.pav-megamenu {
	.navbar {
		margin: 0;
		border: 0;
	}
	.navbar-collapse {
		padding: 0;
	}
	.navbar-nav {
		> li {
			height: 50px;
			&.parent {
				a {
					padding-right: 28px;
				}
			}
			&.aligned-left {
				.dropdown-menu {
					left: -1px;
					right: auto;
				}
				.dropdown-submenu {
					.dropdown-menu {
						left: 100%;
					}
				}
			}
			> a {
				font-weight: 400;
				background-color: transparent !important;
				@include transition(all 200ms ease-out);
				line-height: 22px;
				&:hover, &:focus, &:active {
					color: $white;
					.caret:before {
						color: $white !important;
					}
				}
			}
			&.full-width {
				position: static;
			}
		}
	}
	.widget-heading {
		font-size: $font-size-base + 2;
		margin: 0 0 20px;
	}
	.widget-images {
		margin-bottom: 20px;
		padding-top: 5px;
	}
	.megamenu .cols1 {
		min-width: 200px;
	}
	.megamenu .cols2 {
		min-width: 500px;
	}
	.megamenu .cols3 {
		min-width: 740px;
	}
	.sidebar .product-block {
		padding: 5px 0;
		border-bottom: none;
		.image {
			padding: 0;
		}
	}
}

.ocwall {
	background: transparentize($tc, .15);
	position: absolute;
	width: 100%;
	height: 100%;
	right: -100%;
	top: 0;
	opacity: 0;
	visibility: hidden;
	@include transition(opacity 0.2s ease-in-out);
	.menutoggle {
		position: fixed;
		@include size(50px, 50px);
		background: url('/image/i/closew.png') center center no-repeat;
		background-size: cover;
		top: calc(50% - 25px);
		left: calc(85vw - 25px);
	}
}

.row-offcanvas.active {
	.ocwall {
		opacity: 1;
		visibility: visible;
		z-index: 9999;
	}
}