.sphinxsearch {
	width: 400px;
	right: 0;
	left: auto;
	position: absolute;
	top: 100%;
	margin-top: 1px;
	border: 1px solid #ebebeb;
	z-index: 99999;
	background: $wh;
	border-radius: 0;
	& > a {
		cursor: pointer;
		display: block;
		padding: 5px;
		color: initial;
		line-height: 17px;
		min-height: 85px;
		position: relative;
		&:first-child {
			border-top: none;
		}
		&:last-child {
			border-bottom: none;
		}
		img {
			float: left;
			margin: 0 10px 0 0;
			padding: 5px;
		}
	}
	.categories, .products {
		span {
			display: block;
			padding: 10px 0;
			font-size: 14px;
			text-align: center;
			background: #eee;
		}
	}
	.categories {
		a {
			text-transform: uppercase;
			text-indent: 10px;
		}
	}
}

.product_sp {
	height: 60%;
	color: #666;
	text-align: left;
	font-size: 13px;
	margin-left: 75px;
}

.price_sp {
	width: calc(100% - 89px);
	font-size: 14px;
	font-weight: 600;
	position: absolute;
	bottom: 10px;
	left: 80px;
	.price {
		font-weight: 600;
	}
	.old {
		text-decoration: line-through;
		margin-right: 5px;
		font-size: 13px;
		color: #0264af;
	}
}

.sphinx-viewall {
	font-weight: bold;
	text-align: center;
	padding: 15px 0;
	height: auto;
	&:hover {
		background: $white;
	}
	#view-all {
		padding: 0 25px;
		display: inline-block;
		font-size: 12px;
		height: 36px;
		text-transform: uppercase;
		font-weight: 600;
		background: $theme-color;
		line-height: 36px;
		color: $white;
		&:hover {
			background: $black;
		}
	}
	&-no-res {
		font-weight: bold;
		text-align: center;
		height: 30px;
		color: $black;
		width: 100%;
		line-height: 30px;
	}
}

#input-search + .sphinxsearch {
	left: 15px;
}

@media(max-width:430px) {
	.sphinxsearch {
		width: calc(100vw - 30px);
	}
}