@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat/subset-Montserrat-Bold.eot');
    src: url('/fonts/montserrat/subset-Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/montserrat/subset-Montserrat-Bold.woff') format('woff'),
        url('/fonts/montserrat/subset-Montserrat-Bold.ttf') format('truetype'),
        url('/fonts/montserrat/subset-Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat/subset-Montserrat-SemiBold.eot');
    src: url('/fonts/montserrat/subset-Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/montserrat/subset-Montserrat-SemiBold.woff') format('woff'),
        url('/fonts/montserrat/subset-Montserrat-SemiBold.ttf') format('truetype'),
        url('/fonts/montserrat/subset-Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat/subset-Montserrat-Medium.eot');
    src: url('/fonts/montserrat/subset-Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/montserrat/subset-Montserrat-Medium.woff') format('woff'),
        url('/fonts/montserrat/subset-Montserrat-Medium.ttf') format('truetype'),
        url('/fonts/montserrat/subset-Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat/subset-Montserrat-ExtraBold.eot');
    src: url('/fonts/montserrat/subset-Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/montserrat/subset-Montserrat-ExtraBold.woff') format('woff'),
        url('/fonts/montserrat/subset-Montserrat-ExtraBold.ttf') format('truetype'),
        url('/fonts/montserrat/subset-Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat/subset-Montserrat-Regular.eot');
    src: url('/fonts/montserrat/subset-Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/montserrat/subset-Montserrat-Regular.woff') format('woff'),
        url('/fonts/montserrat/subset-Montserrat-Regular.ttf') format('truetype'),
        url('/fonts/montserrat/subset-Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat/subset-Montserrat-Light.eot');
    src: url('/fonts/montserrat/subset-Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/montserrat/subset-Montserrat-Light.woff') format('woff'),
        url('/fonts/montserrat/subset-Montserrat-Light.ttf') format('truetype'),
        url('/fonts/montserrat/subset-Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
  font-family: 'FontAwesome';
  src: url('/fonts/fontawesome-webfont.eot?v=4.0.3');
  src: url('/fonts/fontawesome-webfont.eot?#iefix&v=4.0.3') format('embedded-opentype'), url('/fonts/fontawesome-webfont.woff?v=4.0.3') format('woff'), url('/fonts/fontawesome-webfont.ttf?v=4.0.3') format('truetype'), url('/fonts/fontawesome-webfont.svg?v=4.0.3#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}