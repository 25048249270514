#cart {
	height: 100%;
	display: inline-block;
	width: auto;
	a {
		display: inline-block;
		position: relative;
		text-decoration: none;
	}
	.icon-cart {
		background: transparent;
		display: block;
		position: relative;
		text-align: center;
	}
	.wrap-cart {
		color: $bk;
		font-size: 13px;
	}
	.cart-inner {
		text-align: right;
	}
}

.product-compare-header {
	display: flex;
	align-items: center;
	margin-left: 15px;
	a {
		position: relative;
	}
	.compare-count {
		position: absolute;
		top: -6px;
		right: -13px;
		font-size: 11.497px;
		font-family: $f;
		font-weight: 700;
		color: #ff335e;
		padding: 5px;
		border-radius: 50%;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 18px;
		height: 18px;
		box-shadow: -3px 0px 9px rgba(23, 16, 18, 0.45);
	}
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

#buy_form_container {
	.cart-item {
		.product-quantity {
			input {
				max-width: 60px;
				background: none;
			}
			button {
				position: relative;
				width: 18px;
				height: 18px;
				padding: 0;
				&:before {
					position: absolute;
					content: '';
					background: #ca274a;
					height: 3px;
				}
				&.sub {
					&:before {
						top: calc(50% - 0px);
						width: 18px;
						left: 8px;
					}
				}
				&.add {
					&:before {
						left: -1px;
						top: calc(50% - 1px);
						width: 19px;
					}
					&:after {
						position: absolute;
						content: '';
						left: calc(50% - 9px);
						background: #ca274a;
						width: 3px;
						height: 19px;
						top: 0;
					}
				}
			}
		}
	}
	.cart-total-items {
		table {
			width: 100%;
		}
		.total-item td:first-child {
			text-align: left;
		}
	}
}