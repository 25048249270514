html {
	overflow-x: hidden;
}

body {
	&[class*="page-"] #content {
		padding: 20px 15px;
		img {
			max-width: 100%;
		}
	}
	&.common-home #content {
		padding: 0;
	}
	&[class*="page-category"] {
		#content {
			padding: 0;
			h1 {
				margin-top: 0;
			}
		}
	}
	&[class*="page-product"] #content {
		overflow: visible;
		padding: 0;
		.owl-carousel .owl-wrapper-outer {
			overflow: visible;
		}
		.owl-carousel {
			margin-bottom: 35px;
		}
	}
}

#content {
	font-family: $f;
	h1 {
		font-size: 16px;
		color: rgb(0, 0, 0);
		text-align: center;
		text-transform: uppercase;
		position: relative;
		padding-top: 28px;
		padding-bottom: 0;
		margin-bottom: 23px;
		&:before {
			content: '';
			position: absolute;
			left: calc(50% - 15px);
			height: 3px;
			width: 30px;
			top: 0;
			background-color: #e6365d;
		}
	}
	p {
		font-size: 13.33px;
	}
}

/*
* HEADER 
*/

.header-v1 {
	height: 100%;
	position: relative;
	border-bottom: 2px solid #c41239;
	&.inner-header {
		background-size: 1920px;
		min-height: 175px;
		.item-one_rc {
			position: absolute;
			top: 29px;
			left: 1556px;
			display: flex;
			flex-direction: column;
			.item-one_rc span.text-hover {
				background: rgba(0, 0, 0, 0.7);
				color: #fff;
				padding: 6px;
				position: absolute;
				max-width: 180px;
				display: block;
				transform: scale(0);
				transition: 0.2s ease-in-out;
				margin-left: 17px;
				width: 180px;
				top: -10px;
				p {
					font-size: 13.333px;
					color: rgb(255, 255, 255);
					line-height: 1.2;
					margin: 0;
					border-bottom: 2px solid #e6365d;
					display: inline-block;
				}
			}
		}
	}
	#pav-mainnav {
		width: 100%;
		.navbar-ex1-collapse {
			& > ul {
				padding: 0 25px;
				justify-content: space-between;
				width: 100%;
				&:before, &:after {
					content: none;
				}
				& > li {
					& > a {
						padding: 10px 15px;
					}
				}
			}
		}
	}
	.button-box {
		display: flex;
		align-items: center;
		margin-left: auto;
		.search {
			display: flex;
			align-items: center;
			height: 100%;
			background-color: rgba(147, 24, 59, 0.54);
			border: 1px solid transparent;
		}
	}
	.business-hours {
		flex-wrap: wrap;
		max-width: 138px;
		& span {
			&:last-child {
				letter-spacing: 0.2px;
			}
		}
	}
	.container-header {
		&.container-header_mobile {
			display: none;
			background: #c41239;
			flex-direction: column;
			padding-top: 22px;
			padding-bottom: 17px;
		}
	}
	.menu {
		&-top {
			.pav-mainnav {
				width: 100%;
				.megamenu {
					width: 100%;
					display: flex;
				}
				.navbar-header {
					width: 100%;
				}
				.collapse .nav {
					height: 40px;
				}
			}
		}
		&-bottom {
			height: 50px;
		}
	}
	.go-to-catalog {

	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

#sidebar-main {
	z-index: 0;
}

#footer {
	ul, ol {
		margin: 0;
		li {
			line-height: 20px;
			a {
				@include transition(all 0.4s ease 0s);
				&:hover {
					color: $white;
				}
			}
		}
	}
}

.footer-center {
	color: $white;
	.main-footer {
		display: flex;
		justify-content: space-between;
	}
}

#powered {
	a {
		font-weight: 500;
		&:hover {
			color: $white;
		}
	}
	.inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.payment {
		img {
			max-height: 61px;
		}
	}
}
.payment2row {
	display: none;
}