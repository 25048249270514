/** May the force of SCSS be with you */
#buy_form_container .buy_products .row {
	position: relative;
}

.logo-store a {
	display: block;
	text-align: center;
}

.logo-store a span {
	display: block;
	position: absolute;
	margin: 0 auto;
	left: 76px;
	right: 0;
	bottom: 7px;
}

/********************************************************************************HEADER**********************************************************************************/

.header-v1 .container-header {
	display: flex;
	width: 100%;
	position: relative;
	justify-content: center;
	max-width: 1120px;
	margin: 0 auto;
}

.header-v1 .container-header .logo {
	background: #ac153a;
	background-size: cover;
	max-width: 200px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.header-v1 .container-header .logo:before {
	content: '';
	position: absolute;
	left: -500px;
	top: 0;
	width: 500px;
	height: 100%;
	background-color: #ac153a;
}

.header-v1 .container-header .logo:after {
	content: '';
	border: 20px solid transparent;
	border-right: 20px solid #ac153a;
	right: 0;
	bottom: -20px;
	width: 17px;
	height: 17px;
	position: absolute;
}

.header-v1 .container-header .logo .logo-store {
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-v1 .container-header .logo .logo-store a {
	text-decoration: none;
}

.header-v1 .container-header .logo .logo-store a .img-responsive {
	max-width: 165px;
	width: 100%;
	height: auto;
}

.header-v1 .container-header .logo .logo-store a span {
	position: relative;
	left: 0;
	color: white;
	font-size: 8pt;
	font-weight: 700;
	text-transform: uppercase;
	padding-top: 15px;
}

.header-v1 .container-header .menu {
	width: 100%;
}

.header-v1 .container-header .menu .menu-top {
	background: #191919;
	display: flex;
	align-items: center;
	height: 40px;
	position: relative;
}

.header-v1 .container-header .menu .menu-top:before {
	position: absolute;
	right: -100%;
	background: #191919;
	height: 100%;
	width: 100%;
	content: '';
}

.header-v1 .container-header .menu .menu-top .pav-mainnav .pav-megamenu .pav-megamenu .navbar .megamenu .navbar-header .collapse .nav {
	padding-left: 10px
}

.header-v1 .container-header .menu .menu-top .pav-mainnav .pav-megamenu .pav-megamenu .navbar .megamenu .navbar-header .collapse .nav .aligned-left {
	border-left: none;
}

.header-v1 .container-header .menu .menu-top .pav-mainnav .pav-megamenu .pav-megamenu .navbar .megamenu .navbar-header .collapse .nav li {
	border: none !important;
}

.header-v1 .container-header .menu .menu-top .pav-mainnav .pav-megamenu .pav-megamenu .navbar .megamenu .navbar-header .collapse .nav li a {
	color: #cccccc;
	font-size: 10pt;
	padding: 10px 32px;
}

.header-v1 .container-header .menu .menu-top .pav-mainnav .pav-megamenu .pav-megamenu .navbar .megamenu .navbar-header .collapse .nav li a:hover {
	color: #c11b40;
}

.header-v1 .container-header .menu .menu-bottom {
	background-color: #c41239;
	position: static;
	display: flex;
	width: 100%;
}

.header-v1 .container-header .menu .menu-bottom:before {
	position: absolute;
	right: -98%;
	background: #c41239;
	height: 50px;
	width: 100%;
	content: '';
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar {
	margin-bottom: 0;
	border: none;
	height: 50px;
	min-height: 50px;
	display: flex;
	align-items: center;
	position: static;
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse {
	height: 100% !important;
	width: 100%;
	padding-left: 0
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav {
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 10px;
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav li {
	display: flex;
	align-items: center;
	height: 100%;
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav li a {
	color: #fff;
	font-size: 12pt;
	text-transform: uppercase;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 10px 32px;
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav li a:hover {
	background: #fff;
	color: #c41239;
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav .dropdown {
	position: static;
	height: 100%;
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav .dropdown:hover > ul {
	display: flex;
	background-image: url('/catalog/view/theme/kitchenrussia/img/19.png');
	background-size: 290px;
	background-repeat: no-repeat;
	background-position: right bottom;
	min-height: 376px;
	min-width: 100%;
	z-index: 200;
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav .dropdown .list-unstyled {
	display: none;
	position: absolute;
	z-index: 500;
	background-color: #fff;
	left: 0;
	top: 90px;
	flex-wrap: wrap;
	padding: 45px;
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav .dropdown .list-unstyled li {
	display: flex;
	flex-wrap: nowrap;
	width: calc(100% / 4 - 40px);
	align-items: center;
	border: 1px solid transparent;
	margin-right: 40px;
	height: 82px;
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav .dropdown .list-unstyled li img {
	max-width: 65px;
	max-height: 80px;
	flex: 1 0 65px;
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav .dropdown .list-unstyled li a {
	font-size: 14.665px;

	color: rgb(25, 25, 25);
	line-height: 1.4;
	text-decoration: none;
	text-transform: none;
	display: flex;
	align-items: center;
	height: 100%;
}

.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav .dropdown .list-unstyled li a:hover {
	background: transparent
}

.header-v1 .container-header .menu .menu-bottom .button-box .product-compare-header {
	display: flex;
	align-items: center;
	margin-left: 15px;
}

.header-v1 .container-header .menu .menu-bottom .button-box .product-compare-header a img:hover {
	filter: brightness(0) invert(0);
}

.header-v1 {
	.container-banner {
		max-width: 1120px;
		margin: 0 auto;
		position: absolute;
		z-index: 50;
		width: 100%;
		left: calc((100% - 1120px) / 2);
		.info-box {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			margin-top: 18px;
			padding-right: 30px;
		}
	}
	.container-shadow {
		position: absolute;
		width: 100%;
		z-index: 50;
		height: 100px;
		box-shadow: inset 0 85px 15px -15px rgba(0, 0, 0, .25);
	}
}

.header-v1 .container-banner .info-box .container-contact {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-bottom: 8px;
}

.header-v1 .container-banner .info-box .container-contact .top-box {
	display: flex;
	align-items: center;
}

.header-v1 .container-banner .info-box .container-contact .top-box .telephone-box {
	position: relative;
	display: flex;
	align-items: center;
}

.header-v1 .container-banner .info-box .container-contact .top-box .telephone-box span {
	width: 30px;
	height: 37px;
	position: relative;
}

.header-v1 .container-banner .info-box .container-contact .top-box .telephone-box span:before {
	position: absolute;
	top: 2px;
	height: 30px;
	width: 1px;
	background: rgba(255, 255, 255, 0.525);
	left: 50%;
	content: '';
}

.header-v1 .container-banner .info-box .container-contact .top-box .telephone-box:before {
	position: absolute;
	content: '';
	left: -36px;
	top: calc(50% - 15px);
	width: 27px;
	height: 28px;
	background: url(/catalog/view/theme/kitchenrussia/img/phone.svg) no-repeat;
	background-size: contain;
}

.header-v1 .container-banner .info-box .container-contact .top-box a, .header-v1 .container-banner .info-box .container-contact .top-box div {
	font-size: 26px;
	color: rgba(255, 255, 255, 0.725);
	line-height: 1.2;
}

.header-v1 .container-banner .info-box .container-contact .top-box a:hover {
	text-decoration: none;
	color: #e03b5c;
}

.header-v1 .container-banner .info-box .container-contact .top-box .request_call {
	font-size: 10pt;
	color: rgb(255, 255, 255);
	text-transform: uppercase;
	background: url('/catalog/view/theme/kitchenrussia/img/5511.png') no-repeat;
	background-size: 100% 100%;
	padding: 7px 13px 15px 13px;
	margin-left: 24px;
}

.header-v1 .container-banner .info-box .container-contact .top-box .request_call:hover {
	color: #c41239;
	cursor: pointer;
}

.header-v1 .container-banner .info-box .container-contact .bottom-box {
	display: flex;
	align-items: center;
	margin-right: 11px;
}

.header-v1 .container-banner .info-box .container-contact .bottom-box span {
	font-size: 9pt;
	font-weight: 500;
	color: #fff;
	line-height: 1.2;
	white-space: nowrap;
}

.header-v1 .container-banner .info-box .container-contact .bottom-box span:first-child {
	margin-right: 8px;
}

.header-v1 .container-banner .info-box .cart-box {
	padding-left: 6px;
}

.header-v1 .container-banner .info-box .cart-box .btn-group .cart-inner a .wrap-cart {
	margin-right: 8px;
}

.header-v1 .container-banner .info-box .cart-box .btn-group .cart-inner a .wrap-cart .icon-cart img {
	width: 55px;
	height: 61px;
}

.header-v1 .container-banner .info-box .cart-box .btn-group .cart-inner a .wrap-cart .icon-cart img:hover {
	filter: brightness(0) invert(1);
}

.header-v1 .container-banner .info-box .cart-box .btn-group .cart-inner a .wrap-cart .cart-total {
	position: absolute !important;
	top: -8px;
	right: 0;
	font-size: 11.497px;
	font-weight: 700;
	color: rgb(255, 255, 255);
	padding: 5px;
	border-radius: 50%;
	background-color: #ff335e;
	display: flex !important;
	align-items: center;
	justify-content: center;
	width: 18px;
	height: 18px;
}

/***************************************************************END HEADER************************************************************/

/***************************************************************SPECIAL-OFFER************************************************************/

.top-bg {
	padding-bottom: 70px;
	position: relative;
	z-index: 2;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products {
	margin-top: 50px;
}

.special-offer.bottom-bg .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products {
	margin-top: 0;
	padding-top: 50px;
	padding-bottom: 70px;
	position: relative;
}

.special-offer.bottom-bg .homebuilder .pt_block {
	background: url(/catalog/view/theme/kitchenrussia/img/liders.png) no-repeat center;
	background-size: cover;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content {
	position: relative;
}

.special-offer.bottom-bg .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content:after {
	position: absolute;
	left: -69px;
	background: url(/catalog/view/theme/kitchenrussia/img/lider-el.png) no-repeat bottom;
	content: '';
	bottom: -71px;
	width: 437px;
	height: 400px;
	background-size: 100% 100%;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .tab-header {
	border: none;
	text-align: center;
	display: flex;
	flex-direction: column;
	text-transform: uppercase;
	font-size: 12pt;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .tab-header span {
	font-size: 12pt;
	font-weight: 700;
	color: rgb(0, 0, 0);
	line-height: 1.2;
	position: relative;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .tab-header span:before {
	width: 30px;
	height: 4px;
	position: absolute;
	left: calc(50% - 15px);
	top: -22px;
	content: '';
	background: #c41239;
}

.special-offer.bottom-bg .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel:before {
	content: '';
	position: absolute;
	right: -53px;
	background: url(/catalog/view/theme/kitchenrussia/img/4411.png) no-repeat;
	top: calc(67% - 70px);
	width: 137px;
	background-size: 100% 100%;
	height: 142px;
	transform: rotate(55deg);
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item:nth-child(even) .item {
	border-left: 3px solid #c41239;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item {
	border: none;
	border-left: 3px solid #4c4c4c;
	-webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	-moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border {
	background: #fff;
	border: none;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block {
	width: 100%;
	border: none;
	margin: 0;
	padding-bottom: 22px;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block:hover {
	border: none;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .block-img .image {
	padding-top: 40px;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .block-img .image .sale {
	padding: 0;
	background: none;
	border: none;
	right: 9px;
	top: 4px;
	width: 50px;
	height: 55px;
	left: auto;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .block-img .image .img {
	display: flex;
	align-content: center;
	justify-content: center;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .block-img .image .img .img-responsive {
	max-width: 190px;
	max-height: 190px;
	margin: 0 auto;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .top {
	padding: 10px 0;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .top div .name a {
	display: block;
	font-size: 10pt;
	color: rgb(0, 0, 0);
	line-height: 1.2;
	text-align: center;
	background-color: #f7f7f7;
	height: 65px;
	padding: 10px 30px;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .top div .name a:hover {
	color: rgb(196, 18, 57);
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .top div .name a:hover {
	text-decoration: none;
}

.product-block .product-meta .top .rating {
	display: flex;
	margin: 15px 0;
	justify-content: center;
	height: 16px;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .top .rating .fa,
.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .top .stars .fa {
	color: $bk;
	font-size: 20px;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .top .product-colors {
	height: 52px;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .top .product-prices {
	padding: 0 30px;
	display: flex;
	justify-content: space-around;
	margin-top: 15px;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .top .product-prices .price-new {
	font-size: 18.667px;
	font-weight: 700;
	color: rgb(196, 18, 57);
	line-height: 1.2;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .top .product-prices .price-old {
	font-size: 18.667px;
	font-weight: 700;
	color: #666666;
	line-height: 1.2;
	text-decoration: line-through;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .bottom {
	display: flex;
	justify-content: center;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .bottom .cart {
	flex: none;
	width: 144px;
	margin-right: 8px;
}

.bottom .cart .btn {
	max-width: 145px;
	border: none;
	background: #e4345b;
	background-size: 100% 100%;
	height: 40px;
	padding: 0 0 0 20px;
	position: relative;
	border-top: 2px solid #be1e43;
	transition: none;
	box-shadow: 0 4px 19px rgba(194, 18, 57, 0.35);
}

.bottom .cart .btn:hover {
	background: #c72d4f;
}

.bottom .cart .btn:hover:after {
	border-left: 10px solid #c72d4f;
	border-top: 6px solid #c72d4f;
}

.bottom .cart .btn:hover:before {
	border-bottom: 38px solid #ab0f32;
}

.bottom .cart .btn:before {
	content: '';
	height: 38px;
	width: 83px;
	border-bottom: 38px solid #c7163d;
	border-left: 30px solid transparent;
	border-right: 0 solid transparent;
	position: absolute;
	right: 0;
	top: 0;
}

.bottom .cart .btn:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -9px;
	border: 8px solid transparent;
	border-left: 10px solid #e5355c;
	border-top: 6px solid #e5355c;
}

.bottom .cart .btn span {
	z-index: 2;
	position: relative;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .bottom .cart .btn:hover span {
	color: #191919;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .bottom .cart .btn span,
.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .bottom .cart .btn:focus span,
.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .bottom .cart .btn:hover span {
	color: #fff;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-item .item .border .product-block .product-meta .bottom .compare .btn {
	background: #f3f3f3;
	border: 1px solid #e2e2e2;
	padding: 9px;
	width: 40px;
	height: 40px;
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-prev {
	position: absolute;
	left: -7px;
	top: calc(50% - 20px);
	background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) no-repeat;
	background-size: cover;
	width: 42px;
	height: 40px;
}

.header-v1.inner-header:before {
	content: '';
	position: absolute;
	right: 0;
	bottom: -62px;
	background: url('/catalog/view/theme/kitchenrussia/img/4411.png') no-repeat;
	width: 110px;
	background-size: 100% 100%;
	height: 123px;
	transform: rotate(60deg);
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-prev:before {
	content: '';
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-next {
	position: absolute;
	right: -7px;
	top: calc(50% - 20px);
	background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) no-repeat;
	background-size: cover;
	width: 42px;
	height: 40px;
	transform: scale(-1, 1)
}

.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-next:before {
	content: '';
}

.choose-color {
	background: url(/catalog/view/theme/kitchenrussia/img/333.jpg) no-repeat;
	padding: 57px 0 65px 0;
	background-size: cover;
	background-position: center;
}

.choose-color .container-info_box {
	max-width: 1145px;
	margin: 0 auto;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.choose-color .choose-color_box {
	display: flex;
	flex-direction: column;
	width: 470px;
	padding: 65px 50px 35px 50px;
	margin: 10px;
	background: url(/catalog/view/theme/kitchenrussia/img/info_bg.png) no-repeat;
	background-size: 100% 100%;
}

.choose-color .choose-color_box h2 {
	font-size: 12pt;
	color: rgb(255, 255, 255);
	line-height: 1.2;
	margin: 0;
	text-transform: uppercase;
	font-weight: 700;
	position: relative;
}

.choose-color .choose-color_box h2:before {
	content: '';
	position: absolute;
	left: 0;
	height: 3px;
	width: 30px;
	top: -16px;
	background-color: #e6365d;
}

.choose-color .choose-color_box p {
	margin-top: 53px;
	font-size: 14.667px;
	color: rgb(255, 255, 255);
	line-height: 1.9;

}

.choose-color .choose-color_box a {
	display: flex;
	margin-top: 45px;
	background: url(/catalog/view/theme/kitchenrussia/img/5511.png) no-repeat;
	width: 200px;
	height: 50px;
	justify-content: center;
	font-size: 10pt;
	font-weight: 700;
	background-size: 100% 100%;
	color: rgb(255, 255, 255);
	text-transform: uppercase;
	padding-top: 9px;
	margin-bottom: 55px;
}

.choose-color .choose-color_box a:hover {
	text-decoration: none;
	color: $bk;
}

/************************************************************************home-about_us*********************************************************************************/
.home-about_us {
	background: url(/catalog/view/theme/kitchenrussia/img/bg_bm.png) no-repeat;
	background-size: 1520px;
	background-position-x: calc(((100vw - 1200px) / 2) - 50px);
}

.home-about_us .home-about_us_container {
	max-width: 1145px;
	margin: 0 auto;
	width: 100%;
	padding: 0 15px;
}

.home-about_us .home-about_us_container h2 {
	font-size: 12pt;
	font-weight: 700;
	color: rgb(0, 0, 0);
	position: relative;
	text-transform: uppercase;
	line-height: 1.7;
}

.home-about_us .home-about_us_container h2:before {
	content: '';
	position: absolute;
	left: calc(50% - 15px);
	height: 3px;
	width: 30px;
	top: -20px;
	background-color: #e6365d;
}

.home-about_us h2 {
	text-align: center;
}

.home-about_us .about_us-content .content {
	display: flex;
	flex-direction: column;
}

.home-about_us .about_us-content .content .labels {
	display: flex;
	max-width: 740px;
	justify-content: space-between;
	width: 100%;
}

.home-about_us .about_us-content .content .labels .labels-item {
	display: flex;
	width: calc(100% / 3 - 15px);
}

.home-about_us .about_us-content .content .labels .labels-item img {
	width: 107px;
	height: 111px;
	display: flex;
	align-items: center;
}

.home-about_us .about_us-content .content .labels .labels-item p {
	font-size: 10pt;
	color: rgb(0, 0, 0);
	line-height: 1.2;
	max-width: 150px;
	padding-top: 12px;
}

.home-about_us .about_us-content .content .labels .labels-item p span {
	color: #c41239;
	font-weight: 700;
}

.home-about_us .about_us-content .content .text-box {
	max-width: 700px;
	margin-top: 20px;
}

.home-about_us .about_us-content .content .text-box p {
	font-size: 10pt;
	color: rgb(0, 0, 0);
	line-height: 1.8;
}

.home-about_us .about_us-content .content .text-box p span {
	font-size: 10pt;
	font-weight: 700;
	color: #c41239;
	line-height: 1.8;
}

/***********************************************************************footer***********************************************************************************/
#footer {
	background: url(/catalog/view/theme/kitchenrussia/img/foter_bg.jpg) no-repeat;
	background-size: 100% 100%;
	padding: 40px 0 20px 0;
	border-top: 3px solid #c41239;
}

#footer div div div {
	width: 100%;
	padding: 0
}

#footer div div div > span {
	margin-top: 15px;
	display: block;
}

#footer div div div .ft-phone a, #footer div div div .ft-phone span {
	font-size: 26px;

	color: rgba(255, 255, 255, 0.525);
	line-height: 1.2;
}

#footer div div div .ft-wt {
	font-size: 9pt;
	color: rgb(178, 178, 178);
	line-height: 1.2;
	text-transform: uppercase;
}

#footer div div div .calc-button {
	font-size: 10pt;
	color: rgb(255, 255, 255);
	text-transform: uppercase;
	background: url(/catalog/view/theme/kitchenrussia/img/5511.png) no-repeat;
	background-size: 100% 100%;
	padding: 7px 13px 15px 13px;
	position: relative;
}

#footer div div div .calc-button:hover {
	color: #c41239;
	cursor: pointer;
}

#footer div div div .calc-button:before {
	position: absolute;
	content: '';
	left: -42px;
	top: calc(50% - 15px);
	width: 30px;
	height: 30px;
	background: url(/catalog/view/theme/kitchenrussia/img/phone.svg) no-repeat;
	background-size: contain;
}

#footer div div a {
	font-size: 10pt;
	color: rgb(229, 229, 229);
	line-height: 1.7;
}

#footer .footer-center {
	background: none;
	padding: 0
}

#footer .footer-center .container .row .inner {
	width: 100%;
}

#footer .footer-center .container .row .inner .row {
	max-width: 870px;
	width: 100%;
	margin-left: auto;
	padding-bottom: 45px;
}

#footer .footer-center .container .row .inner .row .box .box-heading {
	font-size: 12pt;
	color: rgb(230, 54, 93);
	line-height: 1.2;
	text-transform: uppercase;
	margin-bottom: 30px;
}

#footer .footer-center .container .row .inner .row .box .list-unstyled .footer-name {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#footer .footer-center .container .row .inner .row .box .list-unstyled .ft-phone {
	display: flex;
	flex-direction: column;
	padding-bottom: 25px;
	span:first-child {letter-spacing: 0.8px}
}

#footer .footer-center .container .row .inner .row .box .list-unstyled li {
	display: flex;
	padding-bottom: 5px;
}

#footer .footer-center .container .row .inner .row .box .one-box {
	max-width: 230px;
	width: 100%;
}

#footer .footer-center .container .row .inner .row .box .center-box {
	max-width: 230px;
	width: 100%;
}

#footer .footer-center .container .row .inner .row .box .first-box li {
	display: flex;
}

#footer .footer-center .container .row .inner .row .box .first-box li.ft-wt {
	display: flex;
	flex-direction: column;
	span {
		&:last-child {
			letter-spacing: 0.18px;
		}
	}
}

#footer .footer-center .container .row .inner .row .box .first-box .footer-name {
	max-width: 170px;
	width: 100%;
	margin-top: 50px;
}

#footer #powered {
	background: none;
}

#footer #powered .container .inner .payment {
	display: flex;
	align-items: center;
	justify-content: center;
}

/********************************************************************************cart**********************************************************************************/
.main-columns .container-inside .row .col-main .row .col-sm-12 {
	max-width: 1030px;
	margin: 0 auto;
	float: none;
}

#content {
	padding: 0
}

#content h1 {
	font-size: 12pt;
	font-weight: 700;
	color: rgb(0, 0, 0);
	text-align: center;
	text-transform: uppercase;
	position: relative;
	padding-top: 28px;
	padding-bottom: 0;
	margin-bottom: 23px;
}

#content h1::before {
	content: '';
	position: absolute;
	left: calc(50% - 15px);
	height: 3px;
	width: 30px;
	top: 0;
	background-color: #e6365d;
}

#buy_form_container {
	position: relative;
}

#buy_form_container .buy_products .shopping-cart-container {
	display: flex;
	flex-direction: column;
}

#buy_form_container .buy_products .shopping-cart-container .top_text_products {
	padding: 11px 0;
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
	margin-bottom: 13px;
	display: flex;
}

#buy_form_container .buy_products .shopping-cart-container .top_text_products span {
	font-size: 10pt;
	color: rgb(76, 76, 76);
	line-height: 1.2;
	position: relative;
}

#buy_form_container .buy_products .shopping-cart-container .top_text_products span:first-child {
	margin-left: 70px;
	width: 119px;
}

#buy_form_container .buy_products .shopping-cart-container .top_text_products span:nth-child(2) {
	width: 330px;
}

#buy_form_container .buy_products .shopping-cart-container .top_text_products span:nth-child(3) {
	margin-left: 20px;
	width: 125px;
}

#buy_form_container .buy_products .shopping-cart-container .top_text_products span:nth-child(4) {
	margin-left: 35px;
	min-width: 90px;
}

#buy_form_container .buy_products .shopping-cart-container .top_text_products span:nth-child(5) {
	margin-left: 35px;
	min-width: 90px;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item {
	display: flex;
	align-items: center;
	width: 100%;
	border-left: 3px solid #c41239;
	padding: 23px 23px 23px 60px;
	margin-bottom: 20px;
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
	position: relative;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .product-image a img {
	max-width: 115px;
	max-height: 115px;
	width: 100%;
	height: auto;
	border: none;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .product-name {
	display: flex;
	flex-direction: column;
	max-width: 330px;
	margin-left: 20px;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .product-name .text-danger {
	background: #c11b40;
	height: 21px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 114px;
	font-size: 11.596px;
	font-weight: 700;
	color: rgb(255, 255, 255) !important;
	margin-bottom: 12px;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .product-name a {
	font-weight: 700;
	font-size: 12pt;
	color: rgb(0, 0, 0);
	line-height: 1.2;
	text-decoration: none;
	text-transform: uppercase;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .product-quantity {
	margin-left: 20px;
	border: 1px solid #e5e5e5;
	align-items: center;
	display: flex;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .product-quantity .th-title {
	display: none !important;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .product-quantity .input-group {
	display: flex;
	position: relative;
	height: 40px;
	width: 125px;
	max-width: 125px;
	min-width: 125px;
	align-items: center;
	justify-content: space-between;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .product-quantity .input-group .add {
	z-index: 2;
	background: none;
	border: none;

	font-size: 34.472px;
	color: rgb(196, 18, 57);
	height: 18px;
	line-height: 0;
	width: 33px;
	padding-right: 8px;
}

#content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-quantity .input-group .sub {
	z-index: 2;
	background: none;
	border: none;

	font-size: 34.472px;
	color: rgb(196, 18, 57);
	height: 12px;
	line-height: 0;
	margin-top: -4px;
	width: 25px;
}

#content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-quantity .input-group .form-control {
	text-align: center;

	display: flex;
	align-items: center;
	padding: 10px 0;
	font-size: 20px;
	color: rgb(0, 0, 0);
	width: 125px;
	border: none;
}

#content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices {
	display: flex;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices .static-price {
	margin-left: 35px;
	font-size: 18.667px;
	font-weight: 700;
	color: rgb(25, 25, 25);
	min-width: 90px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices .calced-price {
	margin-left: 35px;
	font-size: 18.667px;
	font-weight: 700;
	color: #c5153c;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices .calced-price span {
	white-space: nowrap;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .old {
	font-size: 14px;
	text-decoration: line-through;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .remove-product {
	margin-left: auto;
	cursor: pointer;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .remove-product .cart-remove img {
	width: 16px;
	height: 22px;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .remove-product .cart-remove .tcicon {
	width: 17px;
	height: 22px;
}

#buy_form_container .buy_products .row .col-sm-6 {
	position: absolute;
	right: 15px;
	max-width: calc(42% - 15px);
	height: 165px;
	display: flex;
	bottom: -186px;
	padding-right: 0;
	width: 100%;
}

#buy_form_container .buy_products .row .col-sm-6 .cart-total-items {
	width: 100%;
	border-left: 3px solid #c41239;
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
	padding: 14px 25px;
}

#buy_form_container .buy_products .row .col-sm-6 .cart-total-items > div {
	text-transform: uppercase;
	font-size: 12pt;
}

#buy_form_container .buy_products .row .col-sm-6 .cart-total-items > div > span {
	font-weight: 700;
	margin-right: 5px;
}

#buy_form_container .buy_products .row .col-sm-6 .cart-total-items .total-item {
	font-weight: 700;
	font-size: 18px;
	color: #c41239;
	min-height: 26px;
	align-items: center;
}

#buy_form_container .buy_products .row .col-sm-6 .cart-total-items .total-item strong {
	font-weight: 700;
	font-size: 14px;
	text-align: left;
	color: black;
	text-transform: none;
}

#content .panel-group .form-inline {
	display: flex;
	flex-direction: column;
}

#content .panel-group .form-inline h4 {
	margin-bottom: 0;
	text-transform: uppercase;
}

#content .panel-group .form-inline .form-box-inline {
	display: flex;
	margin-top: 20px;
}

#content .text-center {
	font-size: 18px;
	font-weight: 700;
	color: rgb(0, 0, 0);
	text-transform: uppercase;
	position: relative;
}

.container-inside .bottom-text {
	margin-top: 40px;
	font-weight: 700;
	color: rgb(0, 0, 0);
	text-align: center;
	display: flex;
	justify-content: center;
	font-size: 12pt;
	margin-bottom: 29px;
}

#content .bottom_text-box {
	margin-bottom: 47px;
	position: relative;
}

#content .bottom_text-box:before {
	content: '';
	position: absolute;
	right: -30px;
	background: url(/catalog/view/theme/kitchenrussia/img/pepper.svg) no-repeat;
	bottom: -75px;
	width: 110px;
	height: 130px;
	z-index: -1;
}

#content .row .top_radio-box .top_radio-box_container {
	display: flex;
	justify-content: center;
}

#content .row .top_radio-box .top_radio-box_container .top_radio-box-content {
	display: flex;
}

#content .row .top_radio-box .top_radio-box_container .top_radio-box-content .label-item {
	position: relative;
	margin: 0 36px;
}

#content .row .top_radio-box .top_radio-box_container .top_radio-box-content .label-item:before {
	content: '';
	position: absolute;
	left: -19px;
	width: 14px;
	height: 14px;
	border: 1px solid #dfdfdf;
	top: calc(50% - 7px);
	border-radius: 50%
}

#content .row .top_radio-box .top_radio-box_container .top_radio-box-content .label-item:after {
	content: '';
	position: absolute;
	left: -16px;
	width: 8px;
	height: 8px;
	background: #931a3b;
	top: 7px;
	border-radius: 50%;
	opacity: 0;
}

#content .row .top_radio-box .top_radio-box_container .top_radio-box-content input {
	position: absolute;
	opacity: 0;
}

#content .row .top_radio-box .top_radio-box_container .top_radio-box-content input:checked + label:after {
	opacity: 1;
}

#content .row .top_radio-box .top_radio-box_container .top_radio-box-content .label-item span {
	font-size: 10pt;
	color: rgb(51, 51, 51);
}

#content .row .user-payment-container {
	display: flex;
	justify-content: center;
	margin-top: 22px;
}

#content .row .user-payment-container .user-info {
	max-width: 345px;
	width: 100%;
}

#content .row .user-payment-container .user-info input,
#content .row .user-payment-container .user-info textarea {
	resize: none;
	width: 100%;
	min-height: 40px;
	margin-bottom: 10px;
	padding: 10px 10px 10px 14px;
	font-size: 9pt;
	color: rgb(76, 76, 76);
	line-height: 1.2;
	-webkit-box-shadow: inset 10px 10px 11px -10px rgba(233, 233, 233, 0.68);
	-moz-box-shadow: inset 10px 10px 11px -10px rgba(233, 233, 233, 0.68);
	box-shadow: inset 10px 10px 11px -10px rgba(233, 233, 233, 0.68);
	border: 1px solid #cbcbcb;
}

#content .row .user-payment-container .payment-info .radio_custom:first-child {
	margin-top: 0;
}

#content .row .user-payment-container .payment-info .radio_custom {
	display: flex;
	align-items: center;
	margin: 12px 0
}

#content .row .user-payment-container .payment-info .radio_custom .input-control {
	font-size: 10pt;

	color: rgb(51, 51, 51);
	line-height: 1.2;
	text-transform: none;
}

#content .row .shipping-container div h3 {
	font-size: 18px;
	font-weight: 700;
	color: rgb(0, 0, 0);
	text-align: center;
	margin-top: 30px;
}

#content .row .shipping-container div .radio_custom {
	max-width: 450px;
	margin: 0 auto;
}

#content .row .shipping-container div .radio_custom .input-control {
	text-transform: none;
}

#content .row .shipping-container div .radio_custom .input-control #shipping_methodpickup.pickup {
	font-size: 10pt;
	color: rgb(51, 51, 51);
	line-height: 1.2;
}

#content .row .shipping-container div .tk-comment {
	max-width: 650px;
	margin: 20px auto 0;
	text-align: center;
	line-height: 1.8;
	font-size: 10pt;
	color: rgb(51, 51, 51);
}

#content .row .shipping-container div .tk-comment .tk-link {
	font-weight: 700;
	color: #e3335a;
}

#content .row .agreement-container {
	max-width: 640px;
	width: 100%;
	margin: 50px auto 0;
}

#content .row .agreement-container div .chekaut_button {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 185px;
	height: 40px;
	background: #e3335a;
	margin: 20px auto 0;
}

#content .row .agreement-container div .chekaut_button:before {
	content: '';
	height: 39px;
	width: 109px;
	border-bottom: 39px solid #c11b40;
	border-left: 19px solid transparent;
	border-right: 0 solid transparent;
	position: absolute;
	right: 0;
	top: 0;
	opacity: 0.6;
	z-index: 1;
}

#content .row .agreement-container div .chekaut_button:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -8px;
	border: 8px solid transparent;
	border-left: 10px solid rgb(227, 51, 90);
	border-top: 6px solid rgb(227, 51, 90);
	z-index: -1;
}

#content .row .agreement-container div div:first-child label,
#content .row .agreement-container div div:last-child label {
	display: flex;
	justify-content: center;
	text-align: center
}

#content .row .agreement-container div div label {
	font-size: 10pt;

	color: rgb(51, 51, 51);
	font-weight: 100;
	line-height: 2.5;
	position: relative;
}

#content .row .agreement-container div div label input {
	position: absolute;
	opacity: 0;
}

#content .row .agreement-container div div label span {
	position: absolute;
	left: -25px;
	top: calc(50% - 7px);
	width: 15px;
	height: 15px;
	content: '';
	border: 1px solid #d8d8d8;
}

#content .row .agreement-container div div label span:before {
	content: "\2713";
	color: #c41239;
	font-size: 18px;
	top: -16px;
	left: calc(100% - 12px);
	position: absolute;
	width: 15px;
	height: 15px;
	align-items: center;
	display: none;
	font-weight: 600;
}

#content .row .agreement-container div div label input:checked + span:before {
	display: block;
}

#content .row .agreement-container .last-box_text label {
	text-align: center;
	margin-top: 32px;
	font-size: 10.667px;
	color: rgb(0, 0, 0);
	line-height: 1.2;

}

#content .row .agreement-container div div label a {
	font-weight: 700;
	color: #e3335a;
}

#content .row .agreement-container div #button-order {
	border: none;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	height: 39px;
	width: 184px;
	font-size: 10pt;
	font-weight: 700;
	color: rgb(255, 255, 255);
	background: none;
	margin: 0;
	z-index: 2;
	outline: none;
}

#content .row .agreement-container div #button-order:hover {
	color: $bk;
}

span.product-label.product-label-special {
	display: none;
}

/********************************************************************************bottom_text-box Cart**********************************************************************************/
.bottom_text-box .user-payment-container .payment-info {
	margin-left: 54px;
}

/******************************************************popular goods********************************************************************/
.featured_categories {
	max-width: 1160px;
	width: 100%;
	background: #fff;
	position: relative;
	padding: 5px;
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}

.common-home {
	.featured_categories {
		margin: -135px auto 0;
		z-index: 100;
	}
}

.featured_categories:after {
	content: '';
	position: absolute;
	left: -70px;
	background: url(/catalog/view/theme/kitchenrussia/img/4411.png) no-repeat;
	top: -60px;
	width: 110px;
	background-size: 100% 100%;
	height: 123px;
}

.featured_categories:before {
	content: '';
	position: absolute;
	right: -30px;
	background: url(/catalog/view/theme/kitchenrussia/img/pepper.svg) no-repeat;
	top: -62px;
	width: 120px;
	height: 130px;
	z-index: 1;
}

.featured_categories .featured_categories-container {
	display: flex;
	align-items: center;
	border: 1px solid #e5e5e5;
	padding: 17px 0;
	width: 100%;
	overflow: hidden;
}

.featured_categories .module_header {
	width: 160px;
}

.featured_categories .module_header h4 {
	margin-left: 35px;
	font-size: 12pt;
	font-weight: 700;
	color: rgb(0, 0, 0);
	line-height: 1.2;
	text-align: left;
	position: relative;
}

.featured_categories .module_header h4:before {
	content: '';
	position: absolute;
	left: 0;
	height: 3px;
	width: 30px;
	top: -16px;
	background-color: #e6365d;
}

.featured_categories .featured-categories-carousel {
	width: 100%
}

.featured_categories .featured-categories-carousel .owl-stage-outer {
	overflow: hidden;
	width: calc(100% - 165px);
}

.featured_categories .featured-categories-carousel .owl-stage-outer .owl-stage {
	display: flex;
}

.featured_categories .featured-categories-carousel .owl-item {
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.featured_categories .featured-categories-carousel .owl-item .featured_cat {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.featured_categories .featured-categories-carousel .owl-item .featured_cat .thumb .img-responsive {
	max-width: 130px;
	max-height: 115px;
	width: 100%;
}

.featured_categories .featured-categories-carousel .owl-item .featured_cat .title {
	text-align: center
}

.featured_categories .featured-categories-carousel .owl-item .featured_cat .title span {
	color: $bk;
	line-height: 1.2;
	font-size: 10pt;

}

.featured_categories .featured-categories-carousel .owl-nav .owl-prev {
	position: absolute;
	left: -12px;
	top: calc(50% - 20px);
	background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) no-repeat;
	background-size: cover;
	width: 42px;
	color: transparent;
	height: 40px;
	font-size: 0;
}

.featured_categories .featured-categories-carousel .owl-nav .owl-prev:before,
.featured_categories .featured-categories-carousel .owl-nav .owl-next:before {
	display: none;
}

.featured_categories .featured-categories-carousel .owl-nav .owl-next {
	position: absolute;
	right: -12px;
	top: calc(50% - 20px);
	color: transparent;
	background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) no-repeat;
	background-size: cover;
	width: 42px;
	height: 40px;
	transform: scale(-1, 1);
	font-size: 0;
}

/********************************************************************************product color box***************************************************************************************************/
.product-colors ul {
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 0;
	flex-wrap: wrap;
	min-height: 52px;
}

.product-colors ul li {
	margin: 4px;
	position: relative
}

.product-colors ul li a {
	display: block;
	width: 18px;
	height: 18px;
	position: relative;
}

.product-colors ul li span {
	height: 3px;
	width: 3px;
	border-right: 9px solid transparent;
	border-left: 0 solid transparent;
	position: absolute;
	left: 0;
	bottom: -4px;
}

/****************************************************************************breadcrumbs********************************************************************************************/
.breadcrumbs {
	background: none
}

.breadcrumbs .container .breadcrumb .width span {
	font-size: 10pt;
	line-height: 1.2;
	color: black
}

.breadcrumbs .container .breadcrumb .width a span {
	color: rgb(76, 76, 76);
}

/*************************************************************promokod********************************************************************/
.box_code {
	display: flex;
	align-items: center;
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
	max-width: 575px;
	width: 100%;
	position: relative;
	margin-top: 20px;
	margin-bottom: 55px;
}

.box_code:before {
	content: '';
	position: absolute;
	right: 30px;
	background: url(/catalog/view/theme/kitchenrussia/img/pepper.svg) no-repeat;
	top: -30px;
	width: 80px;
	height: 80px;
	z-index: 1;
	transform: scale(-1, 1);
	background-size: contain;
}

.box_code .code_img {
	border-right: 2px solid #c41239;
	position: relative;
	max-width: 155px;
	width: 100%;
	height: 165px;
	max-height: 165px;
}

.box_code .code_img:before {
	background: #c41239;
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0.5;
}

.box_code .code_img img {
	width: 100%;
	height: 100%;
}

.box_code .panel-group {
	margin-left: 20px;
	margin-top: 0 !important;
}

.box_code .panel-group .form-inline {
	display: flex;
	flex-wrap: wrap;
}

.box_code .panel-group .form-inline h4 {
	font-size: 12pt;
	font-weight: 700;
	color: rgb(0, 0, 0);
	line-height: 1.2;
	margin-bottom: 20px;
	width: 100%;
}

.box_code .panel-group .form-inline .form-group .form-control {
	height: 38px;
	width: 205px;
	border: 1px solid #cccccc;
}

.box_code .panel-group .form-inline .btn {
	margin-left: 10px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 144px;
	font-size: 10pt;
	font-weight: 700;
	text-transform: uppercase;
	height: 39px;
	background: #e3335a;
	z-index: 2;
	border: none;
	color: #fff;
}

.box_code .panel-group .form-inline .btn:hover {
	color: $bk;
}

.box_code .panel-group .form-inline .btn:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -8px;
	border: 8px solid transparent;
	border-left: 10px solid rgb(227, 51, 90);
	border-top: 6px solid rgb(227, 51, 90);
	z-index: -1;
}

.box_code .panel-group .form-inline .btn:before {
	content: '';
	height: 39px;
	width: 80px;
	border-bottom: 39px solid #c11b40;
	border-left: 19px solid transparent;
	border-right: 0 solid transparent;
	position: absolute;
	right: 0;
	top: 0;
	opacity: 0.6;
	z-index: -1;
}

/***************************************************news***************************************************************/
.widget_news {
	position: relative;
}

.news_block {
	padding-top: 80px;
	padding-bottom: 30px;
	background: url(/catalog/view/theme/kitchenrussia/img/466.png) no-repeat bottom center;
	background-size: cover;
}

.widget_news .widget-inner .owl-stage-outer {
	width: 100%;
	overflow: hidden;
}

.widget_news.box-news .widget-inner .owl-stage-outer {
	width: 100%;
	overflow: hidden;
	padding: 20px 0;
}

.widget_news.box-news .widget_news_item .date {
	position: absolute;
	background: rgb(255, 51, 94);
	width: 49px;
	height: 49px;
	top: -7px;
	right: -7px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 9pt;
	line-height: 1;
}

.widget_news.box-news .widget_news_item .date span {
	font-size: 28px;
}

.widget_news.box-news .widget_news_item .date:before {
	content: '';
	position: absolute;
	left: 0;
	bottom: -9px;
	border: 8px solid transparent;
	border-left: 10px solid rgb(255, 51, 94);
	border-top: 6px solid rgb(255, 51, 94);
}

.widget_news .box {
	position: relative;
}

.widget_news .widget-heading {
	display: flex;
	justify-content: space-between;
	width: calc(50% + 107px);
	margin-left: auto;
}

.widget_news .widget-heading h2 {
	font-size: 12pt;
	font-weight: 700;
	line-height: 1.2;
	text-align: center;
	position: relative;
	margin: 0;
	color: rgb(255, 255, 255);
	text-transform: uppercase;
}

.widget_news .widget-heading h2:before {
	width: 30px;
	height: 4px;
	position: absolute;
	left: calc(50% - 15px);
	top: -22px;
	content: '';
	background: #c41239;
}

.widget_news .widget-heading a {
	max-width: 210px;
	border: none;
	background: rgb(255, 51, 94) no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: 100% 100%;
	height: 40px;
	width: 100%;
	padding: 0 0 0 20px;
	position: relative;
	font-size: 13.332px;
	font-weight: 700;
	color: rgb(255, 255, 255);
	text-decoration: none;
	text-transform: uppercase;
}

.widget_news .widget-heading a:hover {
	color: $bk;
}

.widget_news .widget-heading a span {
	z-index: 2;
	position: relative;
}

.widget_news .widget-heading a span:before {
	background-image: url(/catalog/view/theme/kitchenrussia/img/check-list.svg);
	position: absolute;
	left: -34px;
	top: 0;
	width: 27px;
	content: '';
	height: 23px;
	background-size: cover;
}

.widget_news .widget-heading a:before {
	content: '';
	height: 40px;
	width: 120px;
	border-bottom: 40px solid #c11b40;
	border-left: 30px solid transparent;
	border-right: 0 solid transparent;
	position: absolute;
	right: 0;
	top: 0;
	opacity: 0.6;
}

.widget_news .widget-heading a:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -9px;
	border: 8px solid transparent;
	border-left: 10px solid rgb(255, 51, 94);
	border-top: 6px solid rgb(255, 51, 94);
}

.box-recipes {
	padding-bottom: 80px;
	padding-top: 40px;
}

.recipes_block {
	background: url(/catalog/view/theme/kitchenrussia/img/558.jpg) no-repeat top center;
	background-size: cover;
	position: relative;
}

.widget_news.recipes.box-recipes:after {
	height: 12px;
	content: '';
	position: absolute;
	bottom: 0;
	background: $bk;
	opacity: 0.3;
	width: 100%;
	left: 0;
	display: block;
}

.choose-color {
	position: relative;
}

.choose-color:after {
	height: 12px;
	content: '';
	position: absolute;
	bottom: 0;
	background: $bk;
	opacity: 0.3;
	width: 100%;
	left: 0;
	display: block;
}

.widget_news.box-recipes:before {
	content: '';
	position: absolute;
	right: -30px;
	background: url(/catalog/view/theme/kitchenrussia/img/4411.png) no-repeat;
	bottom: -100px;
	width: 165px;
	background-size: 100% 100%;
	height: 183px;
	transform: rotate(75deg);
	z-index: 2;
}

.widget_news.box-recipes .widget-inner {
	width: 100%;
	margin-top: 90px;
}

.widget_news .widget-inner {
	position: relative;
	max-width: 100%;
}

.widget_news .content-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.widget_news.box-news .widget-inner {
	position: relative;
}

.widget_news.box-news .widget-inner:after {
	content: '';
	position: absolute;
	right: 70px;
	background: url(/catalog/view/theme/kitchenrussia/img/4411.png) no-repeat;
	top: -30px;
	width: 100px;
	background-size: 100% 100%;
	height: 113px;
	z-index: -1;
}

.widget_news.box-news .widget-inner:before {
	content: '';
	position: absolute;
	left: -57px;
	background: url(/catalog/view/theme/kitchenrussia/img/pepper.svg) no-repeat;
	top: -40px;
	width: 120px;
	height: 130px;
	z-index: -1;
	transform: scale(-1, 1);
}

.widget_news .widget-inner .owl-stage-outer .owl-stage {
	display: flex;
}

.widget_news .widget-inner .owl-item .widget_news_item {
	display: flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	border-left: 3px solid #c41239;
	background: rgba(0, 0, 0, 0.73);
	width: calc(100% - 27px);
	padding: 10px 10px 15px 10px;
	margin: 0 auto;
	height: 100%;
	position: relative;
}

.widget_news .widget-inner .owl-item .widget_news_item .widget_news_item_img a .img-responsive {
	max-height: 160px;
}

.widget_news .widget-inner .owl-item .widget_news_item .widget_news_item_title {
	display: flex;
	width: 100%;
	padding: 0 20px 46px;
	margin-top: 23px;
	position: relative;
}

.widget_news .widget-inner .owl-item .widget_news_item .widget_news_item_title:before {
	content: '';
	position: absolute;
	left: 20px;
	bottom: 20px;
	height: 1px;
	width: 43px;
	background: #fff;
}

.widget_news .widget-inner .owl-item .widget_news_item .widget_news_item_title a {
	font-size: 10pt;
	color: rgb(255, 255, 255);
	line-height: 1.2;
	text-align: left;
}

.widget_news.box-news .widget-inner .owl-item .widget_news_item .widget_news_item_title {
	padding-bottom: 0;
}

.widget_news.box-news .widget-inner .owl-item .widget_news_item .widget_news_item_title:before {
	display: none;
}

.widget_news.box-news .widget-inner .owl-item .widget_news_item .widget_news_item_title a {
	font-size: 10pt;
	font-weight: 700;
	color: rgb(196, 18, 57);
	line-height: 1.2;
}

.widget_news .widget-inner .owl-item .widget_news_item .news-read-more {
	font-weight: 700;
	color: rgb(255, 255, 255);
	font-size: 10pt;
	text-transform: uppercase;
	display: flex;
	margin-right: auto;
	padding-top: 9px;
	justify-content: center;
	width: 160px;
	text-align: center;
	margin-left: 20px;
	height: 50px;
	background: url(/catalog/view/theme/kitchenrussia/img/5511.png) no-repeat;
	background-size: 100% 100%;
}

.widget_news.box-news h2 {
	color: #333333;
}

.widget_news.box-news .content-container {
	margin-top: 65px;
}

.widget_news.box-news .widget-inner .owl-item .widget_news_item {
	background: #fff
}

.widget_news.box-news .widget-inner .owl-item .widget_news_item .news-read-more {
	color: #333333;
}

.widget_news .widget-inner .owl-item .widget_news_item .widget_news_item_description {
	font-size: 10pt;
	color: #333333;
	line-height: 1.2;
	padding: 5px 20px 20px
}

.widget_news .widget-inner .owl-nav .owl-prev {
	text-indent: -9999px;
	position: absolute;
	left: -7px;
	top: calc(50% - 40px);
	background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) no-repeat;
	background-size: cover;
	width: 42px;
	height: 40px;
}

.widget_news .widget-inner .owl-nav .owl-next {
	text-indent: -9999px;
	position: absolute;
	right: -7px;
	top: calc(50% - 40px);
	background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) no-repeat;
	background-size: cover;
	width: 42px;
	height: 40px;
	transform: scale(-1, 1);
}

#subscribe_form {
	display: flex;
	flex-direction: column;
	background: url(/catalog/view/theme/kitchenrussia/img/478.png) no-repeat;
	padding: 20px 30px;
	position: relative;
	background-size: 100% 100%;
	margin-top: 90px;
	max-width: 265px;
	width: 100%;
	&:before {
		content: '';
		position: absolute;
		width: calc(100% - 20px);
		height: calc(100% - 32px);
		border: 1px solid #ffffff;
		top: 22px;
		left: 10px;
	}
	.form-group {
		display: flex;
		flex-direction: column;
		label {
			font-size: 12pt;
			font-weight: 700;
			color: $wh;
			line-height: 1.2;
			text-transform: uppercase;
			margin-top: 20px;
			position: relative;
			span {
				margin-top: 10px;
				font-weight: 400;
			}
		}
		small {
			margin-top: 5px;
			font-size: 9pt;
			color: $wh;
			line-height: 1.2;
		}
		input {
			width: 206px;
			margin-top: 15px;
			z-index: 3;
		}
	}
	button {
		margin-top: 20px;
		margin-bottom: 89px;
		border: none;
		background: url(/catalog/view/theme/kitchenrussia/img/5511.png) transparent no-repeat center;
		background-size: contain;
		height: 52px;
		display: flex;
		justify-content: center;
		padding-bottom: 12px;
		z-index: 12;
	}
	.subscribe_agree_container {
		color: $wh;
		padding-top: 10px;
		font-size: 10pt;
		line-height: 1.3;
		position: relative;
		z-index: 1;
		a {
			color: $wh;
			text-decoration: underline;
		}
	}
	.subscribe_alert {
		font-weight: 700;
		margin-top: 10px;
		color: $wh;
	}
}

.widget_news .content-container .go-to-catalog {
	display: flex;
	width: 100%;
	justify-content: center;
	margin: 180px auto 0;
	max-width: 212px;
	border: none;
	background: rgb(255, 51, 94) no-repeat;
	background-size: 100% 100%;
	height: 40px;
	position: relative;
	right: calc((-100% / 3) - 10px);
	align-items: center;
	font-size: 10pt;
	font-weight: 700;
	color: rgb(255, 255, 255);
	z-index: 2;
}

.widget_news .content-container .go-to-catalog:hover {
	color: $bk;
}

.widget_news .content-container .go-to-catalog:before {
	content: '';
	height: 40px;
	width: 83px;
	border-bottom: 40px solid #c11b40;
	border-left: 30px solid transparent;
	border-right: 0 solid transparent;
	position: absolute;
	right: 0;
	top: 0;
	z-index: -1;
	opacity: 0.6;
}

.widget_news .content-container .go-to-catalog:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -9px;
	border: 8px solid transparent;
	border-left: 10px solid rgb(255, 51, 94);
	border-top: 6px solid rgb(255, 51, 94);
}

/*====================================================================================================================*/

.contact-blocks .phone div:first-child {letter-spacing: 0.4px}

.custom-catalog .product-block:nth-of-type(3n + 2) {
	border-left: 2px solid #4c4c4c;
}

.custom-catalog .product-block:nth-of-type(3n),
.custom-catalog .product-block:nth-of-type(3n + 1) {
	border-left: 2px solid #c41239;
}

.custom-catalog .product-block .cart .btn,
.product-info .cart-btn .cart .btn-cart,
.custom-right-btn .btn,
.featured .product-block .product-meta .bottom .cart .custom-left-btn,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn {
	max-width: 145px;
	border: none;
	background: rgb(255, 51, 94) no-repeat;
	background-size: 100% 100%;
	height: 40px;
	padding: 0 0 0 20px;
	position: relative;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn {
	padding: 2px 0 0 20px;
	min-width: 100%;
}

.custom-catalog .product-block .cart .btn:before,
.product-info .cart-btn .cart .btn-cart:before,
.custom-right-btn .btn:before,
.featured .product-block .product-meta .bottom .cart .custom-left-btn:before,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn:before,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn:before,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn:before {
	content: '';
	height: 40px;
	width: 83px;
	border-bottom: 40px solid #c11b40;
	border-left: 30px solid transparent;
	border-right: 0 solid transparent;
	position: absolute;
	right: 0;
	top: 0;
	opacity: 0.6;
}

.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn:before {
	top: -2px;
}

.custom-catalog .product-block .cart .btn:after,
.product-info .cart-btn .cart .btn-cart:after,
.custom-right-btn .btn:after,
.featured .product-block .product-meta .bottom .cart .custom-left-btn:after,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn:after,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn:after,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -9px;
	border: 8px solid transparent;
	border-left: 10px solid rgb(255, 51, 94);
	border-top: 6px solid rgb(255, 51, 94);
}

.custom-catalog .product-block .cart .btn span,
.product-info .cart-btn .cart .btn-cart span,
.custom-right-btn .btn span,
.featured .product-block .product-meta .bottom .cart .custom-left-btn span,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn span,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn span,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn span {
	z-index: 2;
	position: relative;
}

.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn span {
	z-index: 2;
	position: relative;
	left: inherit;
	right: inherit;
	width: auto;
	display: inline-block;
}

.custom-right-btn .btn span:before {
	background-image: none;
}

.custom-right-btn .btn span {
	left: -8px;
}

.custom-catalog .product-block .bottom,
.custom-bottom .panel-body .owl-item .product-block .product-meta .bottom,
.product-info .cart-btn,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .bottom,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom,
.custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom,
.product-grid .product-block .bottom, .products-owl-carousel .product-block .bottom {
	margin: 0;
	padding: 0;
	width: 100%;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom {
	-webkit-align-items: flex-start;
	align-items: flex-start;
}

.custom-catalog .product-block .bottom .cart,
.custom-bottom .panel-body .owl-item .product-block .product-meta .bottom .cart,
.product-info .cart-btn .cart,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .bottom .cart,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart,
.custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart,
.product-grid .product-block .cart, .products-owl-carousel .product-block .cart {
	margin: 0 10px 0 0;
	min-width: 145px !important;
	flex: inherit;
}

.product-info .cart-btn .compare .btn img,
.custom-catalog .product-block .bottom .compare .btn img,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .compare .btn img,
.custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .compare .btn img {
	display: block;
}

.product-info .cart-btn .compare .btn,
.custom-catalog .product-block .bottom .compare .btn,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .compare .btn,
.custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .compare .btn,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .compare .btn {
	padding: 9px;
	height: 40px;
	width: 40px;
	background-color: #f3f3f3;
	border-color: #e2e2e2;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .compare .btn img {
	height: auto;
	width: 100%;
	display: block;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .compare {
	margin: 0;
}

.custom-catalog .product-block .block-img .image .sale,
.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .block-img .image .sale {
	padding: 0;
	background: none;
	border: none;
	right: 9px;
	top: 12px;
	width: 40px;
	height: 44px;
	left: auto;
}

.custom-catalog .product-block .block-img .image .sale {
	right: 9px;
	top: 3px;
	width: 50px;
	height: 55px;
}

.custom-catalog .product-block .block-img .image .sale img {
	height: 56px;
	width: 53px;
}

.custom-catalog .product-block .product-meta .top .product-prices,
.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .top .product-prices,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .top .product-prices,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .top .product-prices,
.custom-related-products .desc-pane .products-block .product-col-wrap .top .product-prices,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .top .viewed-box-item_price.price {
	padding: 0 30px;
	display: flex;
	justify-content: space-around;
	margin-top: 15px;
}

.product-block .top .product-prices .price-new,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .top .viewed-box-item_price.price .price,
.price .price-new {
	font-size: 18.667px;
	font-weight: 700;
	color: $tc;
	line-height: 1.2;
}

.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .top .product-prices .price-old,
.custom-catalog .product-block .product-meta .top .product-prices .price-old,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .top .product-prices .price-old,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .top .product-prices .price-old,
.custom-related-products .desc-pane .products-block .product-col-wrap .top .product-prices .price-old {
	font-size: 18.667px;
	font-weight: 700;
	color: #666666;
	line-height: 1.2;
	text-decoration: line-through;
}

.product-block, .viewed-box-popular-wrapper .viewed-box-item {
	border-top: none;
	border-right: none;
	border-bottom: none;
	box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.1);
}

.featured .product-block,
.featured .product-block:hover {
	border-left: 3px solid #4c4c4c;
	border-radius: 0;
}

.featured .product-block .block-img .image {
	margin: 0;
}

.featured .product-block {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

.featured .product-block .image {
	position: relative;
	overflow: hidden;
	padding: 10px 10px 0 10px;
}

.featured .product-block .block-img {
	margin-right: 0;
	width: 100%;
	float: none;
	clear: left;
}

.featured .product-block .product-meta .top div,
.featured .product-block .product-meta .top div .name,
.featured .product-block .product-meta .top div .name a,
.featured .product-block .product-meta,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .top .viewed-box-item_name a {
	width: 100%;
}

.featured .product-block .product-meta {
	overflow: visible;
}

.featured .product-block .product-meta .top {
	margin: 0 -15px;
}

.featured .product-block .product-meta .bottom {
	display: block;
}

.custom-catalog .product-block {
	padding-bottom: 0;
}

.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .top .name,
.custom-catalog .product-block .top .name,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .top .name,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .top .name,
.custom-related-products .desc-pane .products-block .product-col-wrap .top .name,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .top .viewed-box-item_name, .no-scroll .product-meta h3 {
	margin: 10px 0;
	font-size: 14px;
	text-transform: inherit;
	overflow: hidden;
	font-weight: 600;
}

.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .top .name a,
.custom-catalog .product-block .top .name a,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .top .name a,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .top .name a,
.custom-related-products .desc-pane .products-block .product-col-wrap .top .name a,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .top .viewed-box-item_name a,
.featured .product-block .product-meta .top div .name a, .product-grid-nh .name a {
	display: block;
	font-size: 10pt;

	color: rgb(0, 0, 0);
	line-height: 1.2;
	text-align: center;
	background-color: #f7f7f7;
	padding: 10px 30px;
}

.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block,
.custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block {
	margin: 0;
	width: 100%;
}

.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .top div,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .top div,
.custom-related-products .desc-pane .products-block .product-col-wrap .top div,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .top > div {
	margin: 0 -20px;
}

.custom-catalog,
.product-filter,
.product-filter .product-filter__item,
.product-filter .product-filter__item .sort,
.product-filter .filter-grid-btn,
.product-filter .filter-list-btn,
.product-info .product-grid,
.product-grid,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

.product-info .product-grid,
.product-grid,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
}

.product-filter {
	align-items: center;
	padding-left: 15px;
	.sort, .limit {
		display: flex;
		align-items: center;
	}
}

.product-filter .product-filter__item,
.product-filter .product-filter__item .sort {
	-webkit-align-items: center;
	align-items: center;
}

.product-filter .filter-grid-btn {
	height: 40px;
	width: 40px;
	background-color: transparent;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-align-content: center;
	align-content: center;
}

.product-filter .filter-grid-btn.active {
	background-color: #931a3b;
}

.product-filter .filter-grid-btn span {
	margin: 2px;
	height: 10px;
	width: 10px;
	display: inline-block;
	background-color: #931a3b;
}

.product-filter .filter-grid-btn.active span {
	background-color: #fff;
}

.product-filter .filter-list-btn {
	height: 40px;
	width: 40px;
	background-color: transparent;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-align-content: center;
	align-content: center;
}

.product-filter .filter-list-btn span {
	margin: 2px;
	height: 10px;
	width: 10px;
	display: inline-block;
	background-color: #931a3b;
}

.product-filter .filter-list-btn span:nth-of-type(1),
.product-filter .filter-list-btn span:nth-of-type(3) {
	margin: 3px 2px 2px;
}

.product-filter .filter-list-btn span:nth-of-type(2),
.product-filter .filter-list-btn span:nth-of-type(4) {
	margin: 4px 2px 2px 0;
	height: 1px;
	position: relative;
}

.product-filter .filter-list-btn span:nth-of-type(2):after,
.product-filter .filter-list-btn span:nth-of-type(2):before,
.product-filter .filter-list-btn span:nth-of-type(4):after,
.product-filter .filter-list-btn span:nth-of-type(4):before {
	content: '';
	height: 1px;
	width: 100%;
	display: inline-block;
	background-color: #931a3b;
	position: absolute;
	left: 0;
}

.product-filter .filter-list-btn span:nth-of-type(2):after,
.product-filter .filter-list-btn span:nth-of-type(4):after {
	top: 2px;
}

.product-filter .filter-list-btn span:nth-of-type(2):before,
.product-filter .filter-list-btn span:nth-of-type(4):before {
	top: 4px;
}

.product-filter .filter-list-btn.active {
	background-color: #931a3b;
}

.product-filter .filter-list-btn.active span {
	background-color: #fff;
}

.product-filter .filter-list-btn.active span:nth-of-type(2):after,
.product-filter .filter-list-btn.active span:nth-of-type(2):before,
.product-filter .filter-list-btn.active span:nth-of-type(4):after,
.product-filter .filter-list-btn.active span:nth-of-type(4):before {
	background-color: #fff;
}

.product-filter .product-filter__item .sort [for='input-sort'],
.product-filter .product-filter__item .sort .selectric-wrapper .selectric .label,
.product-filter .product-filter__item [for='input-limit'],
.product-filter .product-filter__item .selectric-wrapper .selectric .label {

	font-size: 9pt;
	line-height: 12px;
	text-transform: none;
	color: #4c4c4c;
	text-align: left;
}

.product-filter .product-filter__item .sort .selectric-wrapper .selectric .button:after,
.product-filter .product-filter__item .selectric-wrapper .selectric .button:after {
	border-color: #931a3b;
}

.product-filter .product-filter__item .sort .selectric-wrapper .selectric {
	min-width: 206px;
}

.product-filter .product-filter__item .selectric-wrapper .selectric {
	min-width: 65px;
}

.product-filter .product-filter__item .sort .selectric-wrapper .selectric,
.product-filter .product-filter__item .selectric-wrapper .selectric {
	border: 1px solid #cdcdcd;
	box-shadow: inset 1px 2px 2px #e9e9e9;
}

.product-filter .product-filter__item:last-child .btn-group button:last-child {
	margin: 0;
}

.product-filter .filter-list-btn,
.product-filter .filter-grid-btn {
	height: 50px;
	width: 50px;
}

.product-filter .filter-grid-btn span {
	margin: 2px;
	height: 14px;
	width: 14px;
}

.product-filter .filter-list-btn span {
	margin: 2px;
	height: 15px;
	width: 15px;
}

.product-filter + .product-list .products-block .product-block .product-meta .top {
	margin: 0 0 15px;
}

.product-filter + .product-list .products-block .product-block .product-meta .top div .name a {
	text-align: left;
}

.product-filter + .product-list .products-block .product-block .product-meta .top .rating {
	margin-left: 25px;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
}

.product-filter + .product-list .products-block .product-block .product-meta .top .product-colors ul {
	margin-left: 25px;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
}

.product-filter + .product-list .products-block .product-block .product-meta .top .product-prices {
	margin: 0 0 0 8px;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
}

.product-filter + .product-list .products-block .product-block .product-meta .top .product-prices .price-old,
.product-filter + .product-list .products-block .product-block .product-meta .bottom .cart {
	margin-right: 15px;
}

.product-filter + .product-list .products-block .product-block .product-meta .bottom {
	padding: 0 0 0 24px;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
}

.product-filter + .product-list .products-block .product-block .product-meta .bottom .cart .btn {
	min-width: 170px;
}

.product-filter + .product-list .custom-catalog {
	margin: 0 !important;
}

.product-filter + .product-list {
	padding-top: 5px;
}

.featured .product-block .product-meta .top div:nth-of-type(1) {
	margin: 0 -20px;
	padding: 1px 0 10px;
	background-color: #f7f7f7;
}

.featured .product-block .product-meta .top div:nth-of-type(1) .name, .product-grid-nh .name {
	padding: 0 15px;
	text-align: center;
}

.featured .product-block .product-meta .top div:nth-of-type(1) {
	margin: 0;
	width: 100%;
}

.featured .product-block .product-meta .top .product-prices, .no-scroll .product-meta .product-prices {
	font-size: 18.667px;
	font-weight: 700;
	color: rgb(196, 18, 57);
	line-height: 1.2;
	text-align: center;
}

.featured .product-block .product-meta .top .product-prices {
	margin: 15px 0 15px;
	padding: 0 30px;
	display: flex;
	-webkit-justify-content: space-around;
	justify-content: space-around;
}

.featured .product-block .product-meta .bottom,
.featured .product-block .product-meta, .product-grid-nh .product-block .product-meta .bottom, .product-grid-nh .product-block .product-meta {
	width: 100%;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

.featured .product-block .product-meta .bottom .compare .btn {
	padding: 8px;
	height: 100%;
	background-color: #f3f3f3;
	border-color: #e2e2e2;
}

.featured .product-block .product-meta .bottom .compare .btn img {
	height: auto;
	width: 100%;
	display: block;
}

.featured .product-block .product-meta .bottom {
	width: auto;
}

.featured .product-block .product-meta .bottom .cart {
	padding: 0;
}

.featured .product-block .product-meta .bottom .cart .btn {
	height: 50px;
	width: 137px;
	background: url("/catalog/view/theme/kitchenrussia/img/5511.png") no-repeat center;
	background-size: 100% 100%;
	position: relative;
	border: none;
}

.btn-under-order {
	margin: 0 0 -11px;
	height: 65px;
	width: 137px;
	display: inline-block;
	background: url("/catalog/view/theme/kitchenrussia/img/5511.png") no-repeat center;
	background-size: 100% 80%;
	position: relative;
	border: none;
}

.featured .product-block .product-meta .bottom .cart .btn span,
.btn-under-order span {
	width: 100%;
	font-weight: 700;
	font-size: 9pt;
	line-height: 12px;
	text-transform: uppercase;
	color: #e6365d;
	z-index: 1;
}

.featured .product-block .product-meta,
.featured .product-block .product-meta .bottom {
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-align-content: center;
	align-content: center;
}

.featured .product-block .product-meta .bottom {
	min-width: 182px;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}

.featured .product-block .product-meta .bottom .compare {
	margin: -10px 0 0;
}

.custom-category-sidebar {
	padding: 5px;
	-webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	-moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	background: #fff;
}

.custom-category-sidebar .panel-heading {
	padding: 15px 15px 0;
	border-left: 1px solid #e5e5e5;
	border-top: 1px solid #e5e5e5;
	border-right: 1px solid #e5e5e5;
}

.custom-category-sidebar .panel-heading .panel-title {
	font-weight: 700;
	font-size: 12pt;
	line-height: 16px;
	text-transform: uppercase;
	color: $bk;
}

.custom-category-sidebar .panel-body {
	padding: 15px;
	border-left: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	border-right: 1px solid #e5e5e5;
	border-top: 1px solid #e5e5e5;
}

.custom-category-sidebar .panel-body .accordion .accordion-group,
.custom-category-sidebar .panel-body .accordion .accordion-group:hover {
	padding: 0;
	border-top: none;
	background-color: #fff;
}

.custom-category-sidebar .panel-body .accordion .accordion-group {
	position: relative;
}

.custom-category-sidebar .panel-body .accordion .accordion-group > a,
.custom-category-sidebar .panel-body .accordion .accordion-group:hover > a {
	font-weight: 700;
	font-size: 10pt;
	line-height: 13px;
	text-transform: none;
	color: #444;
}

.custom-category-sidebar .panel-body .accordion .accordion-group a {
	margin: 0 -15px;
	padding: 13px 0 13px 23px;
	height: 36px;
	width: 113%;
	color: #333;
	background-color: #fff;
	display: inline-block;
}

.custom-category-sidebar .panel-body .accordion .accordion-group:hover a,
.custom-category-sidebar .panel-body .accordion .accordion-group a.active {
	margin: 0 -22px;
	padding: 13px 0 13px 30px;
	height: 36px;
	width: 120%;
	color: #fff;
	background-color: #c41239;
	display: inline-block;
	text-decoration: none;
}

.custom-category-sidebar .panel-body .accordion .accordion-group .accordion-heading,
.custom-category-sidebar .panel-body .accordion .accordion-group:hover .accordion-heading {
	float: none;
	clear: left;
	position: absolute;
	top: 7px;
	right: -17px;
	z-index: 2;
}

.custom-category-sidebar .panel-body .accordion .accordion-group .accordion-heading,
.custom-category-sidebar .panel-body .accordion .accordion-group:hover .accordion-heading {
	right: -5px;
}

.custom-category-sidebar .panel-body .accordion .bg {
	padding: 10px;
}

.custom-category-sidebar .panel-body .accordion .accordion-group .accordion-heading .bg .fa-angle-down:before {
	color: #a94863;
}

.custom-category-sidebar .panel-body .accordion .accordion-group:hover .accordion-heading .bg .fa-angle-down:before {
	color: #fff;
}

.custom-category-sidebar .panel-body .accordion .accordion-group .accordion-body li,
.custom-category-sidebar .panel-body .accordion .accordion-group:hover .accordion-body li a {
	background-color: #fff;
	color: #333;
}

.custom-category-sidebar .panel-body .accordion .accordion-group:hover .accordion-body li a,
.custom-category-sidebar .panel-body .accordion .accordion-group:hover .accordion-body li:hover a {
	padding: 1px 0;
}

.custom-category-sidebar .panel-body .accordion .accordion-group .accordion-body li,
.custom-category-sidebar .panel-body .accordion .accordion-group:hover .accordion-body li {
	padding: 0;
}

.custom-category-sidebar .panel-body .accordion .accordion-group .accordion-body li a,
.custom-category-sidebar .panel-body .accordion .accordion-group .accordion-body li a {
	padding: 1px 0;
	height: auto;
	width: auto;
	margin: auto;

	font-size: 9pt;
	line-height: 12px;
	color: #333;
}

#column-left .accordion .accordion-group .accordion-body li a img {
	display: none;
}

.custom-category-sidebar .panel-body .accordion .accordion-group:hover .accordion-body li a,
.custom-category-sidebar .panel-body .accordion .accordion-group:hover .accordion-body li:hover a {
	padding: 1px 0;
	height: auto;
	width: auto;
	margin: auto;

	font-size: 9pt;
	line-height: 12px;
	color: #333;
	transition: all .3s linear;
}

.custom-category-sidebar .panel-body .accordion .accordion-group:hover .accordion-body li:hover a {
	font-weight: 700;
}

.featured .panel-heading .panel-title {
	margin: 0 0 30px;
	padding: 22px 0 0;
	font-weight: 700;
	font-size: 12pt;
	line-height: 16px;
	color: $bk;
	text-transform: uppercase;
	position: relative;
	text-align: center;
}

.featured .panel-heading .panel-title:before {
	content: '';
	height: 3px;
	width: 30px;
	background-color: #ed1c24;
	display: inline-block;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

.product-filter .product-filter__item .sort .selectric-wrapper .selectric-items .selectric-scroll ul li,
.product-filter .product-filter__item .selectric-wrapper .selectric-items .selectric-scroll ul li,
.product-filter .product-filter__item .selectric-wrapper .selectric-items .selectric-scroll ul li.selected.highlighted {

	font-size: 9pt;
	line-height: 12px;
	text-transform: none;
	color: #4c4c4c;
}

.product-filter .product-filter__item .sort .selectric-wrapper .selectric-items .selectric-scroll ul li:hover,
.product-filter .product-filter__item .selectric-wrapper .selectric-items .selectric-scroll ul li:hover,
.product-filter .product-filter__item .selectric-wrapper .selectric-items .selectric-scroll ul li.selected.highlighted {
	background: #c41239;
	color: #fff;
}

.product-filter + * + .paging .pull-left {
	width: 100%;
	float: none !important;
}

.product-filter + * + .paging .pull-left .pagination li {
	margin: 0 8px 8px 0;
	padding: 7px 0;
	height: 30px;
	width: 30px;
	display: inline-block;
	border-radius: 50px;
}

.product-filter + * + .paging .pull-left .pagination li span,
.product-filter + * + .paging .pull-left .pagination li a {
	margin-right: 0;
	display: flex;
	justify-content: center;
	font-size: 10pt;
	line-height: 13px;
	text-decoration: none;
	background-color: transparent;
	cursor: pointer;
}

.product-filter + * + .paging .pull-left .pagination li.active {
	background-color: #931a3b;
}

.product-filter + * + .paging .pull-left .pagination li.active span {
	color: #fff;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
	background-color: #fff;
	z-index: 1;
}

.viewed-box-popular-wrapper .viewed-box-content,
.tab-content .tab-description .related-products-wrapp .custom-related-products {
	width: 100%;
	position: relative;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
	padding: 0 0 30px;
}

.viewed-box-popular-wrapper .viewed-box-content:after {
	position: absolute;
	left: -69px;
	background: url('/catalog/view/theme/kitchenrussia/img/lider-el.png') no-repeat;
	content: '';
	bottom: -6px;
	width: 437px;
	height: 400px;
	background-size: 100% 100%;
	z-index: -4;
}

.viewed-box-popular-wrapper .viewed-box-content:before,
.tab-content .tab-description .related-products-wrapp .custom-related-products:before {
	content: '';
	position: absolute;
	top: 50%;
	right: -60px;
	background: url('/catalog/view/theme/kitchenrussia/img/4411.png') no-repeat;
	bottom: auto;
	width: 137px;
	background-size: 100% 100%;
	height: 142px;
	transform: rotate(55deg);
}

.tab-content .tab-description .related-products-wrapp .custom-related-products:before {
	top: 0;
	transform: inherit;
}

.viewed-box-popular-wrapper .viewed-box-content:before {
	right: inherit;
	top: -10%;
	transform: rotate(45deg);
	left: 7.5%;
}

.custom-bottom {
	height: auto;
	width: 100%;
	display: inline-block;
	background: url('/catalog/view/theme/kitchenrussia/img/liders.png') no-repeat center;
	background-size: cover;
	position: relative;
}

.custom-bottom:before {
	content: '';
	height: 190px;
	width: 173px;
	display: inline-block;
	background: url("/catalog/view/theme/kitchenrussia/img/4411.png") no-repeat center;
	background-size: cover;
	position: absolute;
	left: -55px;
	top: -75px;
}

.custom-accessories {
	position: relative;
	width: 100%;
	background: url('/catalog/view/theme/kitchenrussia/img/liders.png') no-repeat top right;
	background-size: cover;
}

.custom-accessories .container {
	position: relative;
	z-index: 1;
}

.widget-products .tab-header,
.product-info .custom-related-products .desc-header-wrapper .desc-header,
.custom-related-products .desc-header-wrapper .desc-header,
.viewed-box-popular-wrapper .custom-viewed-box-heading,
.tab-content .tab-characteristics .desc-header-wrapper .desc-header,
.custom-accessories-heading .custom-accessories-heading__item {
	padding: 24px 0 0;
	position: relative;
}

.tab-content .tab-characteristics .desc-header-wrapper .desc-header,
.custom-accessories-heading {
	margin: 0 0 30px;
}

.widget-products .tab-header,
.widget-products .tab-header span,
.product-info .custom-related-products .desc-header-wrapper .desc-header,
.product-info .custom-related-products .desc-header-wrapper .desc-header span,
.custom-related-products .desc-header-wrapper .desc-header,
.custom-related-products .desc-header-wrapper .desc-header span,
.viewed-box-popular-wrapper .custom-viewed-box-heading h3,
.viewed-box-popular-wrapper .custom-viewed-box-heading h3 span,
.tab-content .tab-characteristics .desc-header-wrapper .desc-header,
.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title,
.custom-accessories-heading .custom-accessories-heading__item {
	width: 100%;
	display: inline-block;
	text-align: center;
	border: none;
	font-size: 12pt;
	line-height: 24px;
	text-transform: uppercase;
	position: relative;
}

.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title {
	width: auto;
}

.custom-producttabs .tab-header,
.tab-content .tab-characteristics .desc-header-wrapper .desc-header,
.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title {
	position: relative;
}

.product-info .custom-related-products .desc-header-wrapper,
.custom-related-products .desc-header-wrapper,
.viewed-box-popular-wrapper .custom-viewed-box-heading,
.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title {
	margin: 0 0 30px;
}

/* OwlCarousel */
.custom-bottom .owl-carousel .owl-stage .owl-item,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap,
.custom-related-products .desc-pane .products-block .product-col-wrap {
	background-color: #fff;
	border-top: none;
	border-right: none;
	border-bottom: none;
	-webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	-moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
}

.custom-bottom .owl-carousel .owl-stage-outer {
	padding: 15px 0 65px;
}

.custom-bottom .owl-carousel .owl-stage .owl-item:nth-of-type(2n + 1),
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item:nth-of-type(2n + 1),
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap:nth-of-type(2n + 1),
.custom-related-products .desc-pane .products-block .product-col-wrap:nth-of-type(2n + 1),
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item:nth-of-type(2n + 1) {
	border-left: 3px solid #c41239;
}

.custom-bottom .owl-carousel .owl-stage .owl-item:nth-of-type(2n + 2),
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item:nth-of-type(2n + 2),
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap:nth-of-type(2n + 2),
.custom-related-products .desc-pane .products-block .product-col-wrap:nth-of-type(2n + 2),
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item:nth-of-type(2n + 2) {
	border-left: 3px solid #4c4c4c;
}

.product-info .custom-related-products .desc-pane .products-block .product-col-wrap,
.custom-related-products .desc-pane .products-block .product-col-wrap {
	margin: 15px;
	width: 22%;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_image {
	position: relative;
	overflow: hidden;
	padding: 10px 10px 0 10px;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_image a {
	padding: 25px;
	display: inline-block;
	width: 100%;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_name {
	margin: 10px 0;
	padding: 0 20px 10px 20px;
	font-size: 14px;
	text-transform: inherit;
	overflow: hidden;
	font-weight: 600;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_name a {
	//	margin: 0 -20px;
	display: block;
	font-size: 10pt;

	color: rgb(0, 0, 0);
	line-height: 1.2;
	text-align: center;
	background-color: #f7f7f7;
	padding: 10px;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_price {
	padding: 0 15px;
	display: flex;
	justify-content: space-around;
	margin-top: 15px;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_price,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_price .price-old,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_price .price-new {
	font-size: 18.667px;
	font-weight: 700;
	color: rgb(196, 18, 57);
	line-height: 1.2;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_price {
	margin-bottom: 15px;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_price .price-old {
	color: #666666;
	text-decoration: line-through;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_rating.rating,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .button {
	padding-bottom: 15px;
	width: 100%;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_rating.rating {
	margin: 0 0 15px;
	height: 14px;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-align-content: center;
	align-content: center;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_rating.rating .fa-star-half-o:before,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_rating.rating .fa-star-half-full:before,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_rating.rating .fa-star-half-empty:before,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_rating.rating .fa-star-half-o:before,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_rating.rating .fa-star-half-full:before,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_rating.rating .fa-star-half-empty:before,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_rating.rating .fa-star:before,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_rating.rating .fa-star-o:before {
	color: $bk;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_cart.cart {
	margin: 0 10px 0 0;
	min-width: 145px;
	flex: inherit;
}

.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .cart .btn,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_cart.cart .btn,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .bottom .cart .btn,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart .btn,
.custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart .btn,
.custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart .btn:focus,
.custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart .btn:hover {
	max-width: 145px;
	background: rgb(255, 51, 94) no-repeat;
	background-size: 100% 100%;
	height: 40px;
	padding: 0 0 0 20px;
	position: relative;
	border: none !important;
}

.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .cart .btn:before,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_cart.cart .btn:before,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .bottom .cart .btn:before,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart .btn:before,
.custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart .btn:before {
	content: '';
	height: 40px;
	width: 83px;
	border-bottom: 40px solid #c11b40;
	border-left: 30px solid transparent;
	border-right: 0 solid transparent;
	position: absolute;
	right: 0;
	top: 0;
	opacity: 0.6;
}

.custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart .btn:before {
	top: 0 !important;
}

.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .cart .btn:after,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_cart.cart .btn:after,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .bottom .cart .btn:after,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart .btn:after,
.custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart .btn:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -9px;
	border: 8px solid transparent;
	border-left: 10px solid rgb(255, 51, 94);
	border-top: 6px solid rgb(255, 51, 94);
}

.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .cart .btn span,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .viewed-box-item_cart.cart .btn span,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .bottom .cart .btn span,
.product-info .custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart .btn span,
.custom-related-products .desc-pane .products-block .product-col-wrap .product-col .product-block .bottom .cart .btn span {
	z-index: 2;
	position: relative;
	color: #fff;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn span:before {
	top: -2px;
}

.custom-bottom .viewed-box {
	margin-bottom: 30px;
}

.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .button .compare {
	flex: 0 0 36px;
}

.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .compare .btn,
.custom-bottom .owl-carousel .owl-stage .owl-item .viewed-box-item .button .compare .btn,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .bottom .compare .btn,
.custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .bottom .compare .btn {
	padding: 8px;
	background-color: #f3f3f3;
	border-color: #e2e2e2;
}

.custom-subcat-owl .owl-nav .owl-prev,
.custom-bottom .owl-prev,
.product-info .custom-related-products .desc-pane .products-block .owl-nav .owl-prev,
.custom-related-products .desc-pane .products-block .owl-nav .owl-prev {
	top: 50% !important;
	transform: translateY(-50%);
}

.custom-subcat-owl .owl-nav .owl-prev,
.custom-bottom .owl-prev,
.product-info .custom-related-products .desc-pane .products-block .owl-nav .owl-prev,
.custom-related-products .desc-pane .products-block .owl-nav .owl-prev {
	right: auto;
}

.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider {
	position: relative;
}

.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider:before {
	content: '';
	height: 408px;
	width: 483px;
	display: inline-block;
	background: url("/catalog/view/theme/kitchenrussia/img/lider-el.png") center;
	background-size: cover;
	position: absolute;
	bottom: 0;
	left: 9%;
}

.custom-bottom .owl-prev:before,
.custom-bottom .owl-next:before,
.custom-bottom .owl-prev i,
.custom-bottom .owl-next i,
.custom-subcat-owl .owl-nav .owl-prev:before,
.custom-subcat-owl .owl-nav .owl-next:before,
.custom-subcat-owl .owl-nav .owl-prev i,
.custom-subcat-owl .owl-nav .owl-next i,
.product-info .custom-related-products .desc-pane .products-block .owl-nav .owl-prev:before,
.product-info .custom-related-products .desc-pane .products-block .owl-nav .owl-prev i,
.product-info .custom-related-products .desc-pane .products-block .owl-nav .owl-next:before,
.product-info .custom-related-products .desc-pane .products-block .owl-nav .owl-next i,
.custom-related-products .desc-pane .products-block .owl-nav .owl-prev:before,
.custom-related-products .desc-pane .products-block .owl-nav .owl-prev i,
.custom-related-products .desc-pane .products-block .owl-nav .owl-next:before,
.custom-related-products .desc-pane .products-block .owl-nav .owl-next i,
.viewed-box-popular-wrapper .viewed-box-content .owl-nav .owl-prev:before,
.viewed-box-popular-wrapper .viewed-box-content .owl-nav .owl-prev i,
.viewed-box-popular-wrapper .viewed-box-content .owl-nav .owl-next:before,
.viewed-box-popular-wrapper .viewed-box-content .owl-nav .owl-next i,
.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-prev i,
.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-next i,
.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-prev:before,
.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-next:before,
.image-additional .owl-prev i,
.image-additional .owl-next i,
.image-additional .owl-prev:before,
.image-additional .owl-next:before,
.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li .slick-list.draggable .slick-track ul .owl-nav .owl-prev i,
.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li .slick-list.draggable .slick-track ul .owl-nav .owl-next i,
.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li .slick-list.draggable .slick-track ul .owl-nav .owl-prev:before,
.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li .slick-list.draggable .slick-track ul .owl-nav .owl-next:before {
	display: none;
}

.custom-subcat-owl .owl-nav .owl-prev,
.custom-bottom .owl-prev,
.product-info .custom-related-products .desc-pane .products-block .owl-nav .owl-prev,
.custom-related-products .desc-pane .products-block .owl-nav .owl-prev,
.viewed-box-popular-wrapper .viewed-box-content .owl-nav .owl-prev,
.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-prev,
.image-additional .owl-prev,
.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li .slick-list.draggable .slick-track ul .owl-nav .owl-prev {
	position: absolute;
	left: -7px;
	top: calc(25% - 20px);
	background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) no-repeat;
	background-size: cover;
	width: 42px;
	height: 40px;
}

.custom-subcat-owl .owl-nav .owl-next,
.custom-bottom .owl-next,
.product-info .custom-related-products .desc-pane .products-block .owl-nav .owl-next,
.custom-related-products .desc-pane .products-block .owl-nav .owl-next,
.viewed-box-popular-wrapper .viewed-box-content .owl-nav .owl-next,
.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-next,
.image-additional .owl-next,
.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li .slick-list.draggable .slick-track ul .owl-nav .owl-next {
	position: absolute;
	right: -7px;
	top: calc(25% - 20px);
	background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) no-repeat;
	background-size: cover;
	width: 42px;
	height: 40px;
	transform: scale(-1, 1);
}

.image-additional .owl-prev,
.image-additional .owl-next {
	top: 0;
}

.custom-subcat {
	margin: -15px 0 25px;
	padding: 5px;
	position: relative;
	-webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	-moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	.img {
		width: 100px;
	}
}

.custom-subcat .custom-subcat-owl,
.custom-subcat .list-inline.subcat {
	margin: 0 !important;
	border: 1px solid #e5e5e5;
}

.custom-subcat-owl .owl-item .subcat-item a,
.custom-subcat .list-inline.subcat {
	margin: 0;
	padding: 0;
	height: auto;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	text-decoration: none;
	position: relative;
	background-color: #fff;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
}

#content .custom-subcat .list-inline.subcat.text-category-description {
	padding: 20px;
	display: block;
	p {
		font-size: 14px;
	}
}

.custom-subcat .list-inline.subcat .subcat-item {
	margin: 6px;
	width: calc(25% - 12px);
}

.custom-subcat .list-inline.subcat .subcat-item .owl-row-item {
	display: flex;
	justify-content: center;
}

.custom-subcat .list-inline.subcat .subcat-item a {
	padding-top: 15px;
	display: inline-flex;
	justify-content: center;
	position: relative;
	height: 160px;
	width: 100%;
	flex-wrap: wrap;
	&:hover {
		text-decoration: none;
	}
}

.custom-subcat .list-inline.subcat .subcat-item a .image {
	width: 100%;
	text-align: center;
}

.custom-subcat .list-inline.subcat .subcat-item a .image img {
	display: inline-block;
	width: 113px;
	height: 99px;
}

.custom-subcat-owl .owl-item .subcat-item a span,
.custom-subcat .list-inline.subcat .subcat-item a span {
	padding: 15px 0;
	width: 100%;
	display: block;
	background-color: #fff;
	font-size: 10pt;
	line-height: 13px;
	color: $bk;
	text-align: center;
	position: relative;
	left: 0;
	bottom: 0;
	transition: all .3s linear;
}

.custom-subcat .list-inline.subcat .subcat-item:hover a span {
	background-color: #c41239;
	color: #fff;
}

.main-columns .heading,
.h1_container {
	width: 100%;
	text-align: center;
}

.h1_container:before {
	display: none;
}

.custom-related-products .desc-header-wrapper .desc-header,
.viewed-box-popular-wrapper .custom-viewed-box-heading,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-header-wrapper .desc-header {
	position: relative;
}

.viewed-box-popular-wrapper .custom-viewed-box-heading h3 {
	margin: 0;
}

.h1_container h1:before,
.main-columns .heading h1:before,
.custom-producttabs .tab-header:before,
.custom-related-products .desc-header-wrapper .desc-header:before,
.viewed-box-popular-wrapper .custom-viewed-box-heading:before,
.tab-content .tab-characteristics .desc-header-wrapper .desc-header:before,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-header-wrapper .desc-header:before,
.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title:before,
.custom-instructions .desc-header-wrapper .desc-header:before,
.custom-accessories-heading .custom-accessories-heading__item:before {
	margin: 0;
	content: '';
	height: 3px;
	width: 30px;
	background-color: #c41239;
	display: inline-block;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

.custom-accessories-heading .custom-accessories-heading__item span {
	width: 100%;
	display: inline-block;
}

.custom-subcat-owl .owl-item .subcat-item .owl-row-item:hover a span {
	background-color: #c41239;
	color: #fff;
}

.custom-subcat-owl .owl-item .subcat-item a {
	border-color: transparent;
}

.product-grid .product-block,
.product-info .custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block,
.custom-related-products .desc-pane .products-block .block-img,
.custom-related-products .desc-pane .products-block .block-img .image {
	border: none;
}

.h1_container {
	position: relative;
}

.featured .product-block .product-meta .bottom .cart .custom-left-btn {
	height: 40px;
	top: -5px;
}

.featured .product-block .product-meta .bottom .cart .custom-left-btn span {
	color: #fff;
	position: relative;
	z-index: 2;
	top: 0;
	right: 12.5px;
	font-weight: 600;
	font-size: 14px;
	line-height: 14px;
	font-family: Open-Sans, sans-serif;
}

.mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item {
	position: relative;
}

.product-info {
	margin: 0 auto;
	position: relative;
	.in-stock {
		padding: 4px 0;
		width: 104px;
		display: -webkit-inline-flex;
		display: inline-flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		-webkit-align-content: center;
		background-color: #e6365d;
		position: absolute;
		top: 9px;
		right: 23px;
		z-index: 1;
		span {
			width: auto;
			display: inline-block;
			font-size: 11px;
			line-height: 11px;
			color: #fff;
		}
	}
}

.product-info .row .product-info-bg .image-container .custom-image-container {
	background-color: #fff;
	border-left: 2px solid #c41239;
	box-shadow: -5px -5px 20px rgba(0, 0, 0, .1);
}

.product-info .row .product-info-bg .image-container .product-label {
	background-color: inherit;
}

.product-info .row .product-info-bg .image-container .thumbnail {
	margin: 0;
	border: none;
}

.product-info .product-article {
	margin: 0 0 15px;
}

.product-info .product-article,
.product-info .product-article span {

	font-size: 9pt;
	line-height: 12px;
	color: $bk;
}

.product-info .product-article span {
	margin: 0;
}

.product-info .product-article span:first-child {
	margin: 0 8px 0 0;
}

.product-info .product-stars-and-review-inner {
	margin: 0 0 10px;
	padding: 0;
	width: 100%;
	position: relative;
}

.product-info .product-stars-and-review-inner:before {
	content: '';
	height: 1px;
	width: 45px;
	background-color: #b9b9b9;
	display: inline-block;
	position: absolute;
	left: 0;
	bottom: 0;
}

.product-info .product-stars-and-review-inner,
.product-info .product-stars-and-review-inner .product-review,
.product-info .product-description .product-model,
.product-info .product-description .product-manufacturer,
.product-info .product-description .product-guarantee,
.product-info .product-description .product-manufacturer-country,
.product-info .product-description .product-manufacturer-country .product-manufacturer-country-item:last-child,
.product-info #product .custom-product-meta .product-prices ul li .list-prices {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-align-items: flex-start;
	align-items: center;
	-webkit-align-content: center;
}

.product-info .product-description {
	margin: 0;
	padding: 0;
}

.product-info .product-colors,
.product-info .product-colors .product-colors-item ul {
	list-style: none;
}

.product-info .product-stars-and-review-inner .product-stars,
.product-info .product-stars-and-review-inner .product-review,
.product-info .product-description .product-model .product-model-item,
.product-info .product-description .product-manufacturer .product-manufacturer-item,
.product-info .product-description .product-guarantee .product-guarantee-item,
.product-info .product-description .product-manufacturer-country .product-manufacturer-country-item,
.product-info .product-colors .product-colors-item:first-child {
	margin: 0 4px 15px 0;
	width: auto;
	display: inline-flex;
}

.product-info .product-stars-and-review-inner .product-stars,
.product-info .product-colors .product-colors-item:first-child {
	margin: 0 15px 15px 0;
}

.product-info .product-description .product-model .product-model-item:last-child,
.product-info .product-description .product-manufacturer .product-manufacturer-item:last-child,
.product-info .product-description .product-guarantee .product-guarantee-item:last-child,
.product-info .product-description .product-manufacturer-country .product-manufacturer-country-item:last-child,
.product-info .product-colors .product-colors-item:last-child {
	margin: 0 0 15px;
	text-transform: none;
}

.product-info .product-stars-and-review-inner .product-stars span:before {
	color: #ccc;
}

.product-info .product-stars-and-review-inner .product-review .product-review-title {
	margin: 0 15px 0 0;
}

.product-info .product-stars-and-review-inner .product-review .product-review-title,
.product-info .product-stars-and-review-inner .product-review .product-review-btn {
	font-size: 14px;
	line-height: 14px;
	color: #c41239;
}

.product-info .product-description .product-model,
.product-info .product-description .product-manufacturer,
.product-info .product-description .product-guarantee,
.product-info .product-description .product-manufacturer-country,
.product-info #product .custom-product-meta,
.product-info #product .custom-product-meta .product-prices ul li .list-prices,
.product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn {
	width: 100%;
}

.product-info #product .custom-product-meta-item:first-child .quantity {
	-webkit-align-items: center;
	align-items: center;
}

.product-info #product .custom-product-meta-item {
	width: 50%;
	max-width: 170px;
}

/* Manufacturer country flag */
.product-description .product-manufacturer-country .product-manufacturer-country-item:last-child img {
	position: initial !important;
	top: initial !important;
}

.product-info .product-description .product-manufacturer-country .product-manufacturer-country-item:last-child span,
.product-info .product-description .product-manufacturer-country .product-manufacturer-country-item:last-child img {
	margin: 0;
	width: auto;
	display: inline-block;
}

.product-info .product-description .product-manufacturer-country .product-manufacturer-country-item:last-child span {
	margin: 0 15px 0 0;
}

.product-info .title-product {
	margin: 0 0 40px;
	padding: 15px 0 0;

	font-size: 16px !important;
	line-height: 22px;
	color: $bk;
	text-transform: uppercase;
}

.product-info #product .custom-product-meta .product-prices ul li .list-prices .price-old,
.product-info #product .custom-product-meta .product-prices ul li .list-prices .price-new {
	white-space: nowrap;
	font-size: 23px;
	line-height: 23px;
	color: #666666;
}

.product-info #product .custom-product-meta .product-prices ul li .list-prices .price-new {
	color: #c41239;
}

.product-info #product .custom-product-meta .product-prices ul li .list-prices .price-old,
.product-info #product .custom-product-meta .product-prices ul li .list-prices .price-new {
	margin: 0 40px 15px 0;
	width: auto;
	display: inline-block;
}

.product-info #product .custom-product-meta .product-prices ul li .list-prices .price-new {
	margin: 0 0 15px;
}

.product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice,
.product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul {
	margin: 0;
	padding: 0;
	width: 100%;
	list-style-type: none;
}

.product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul li {
	margin: 0 0 8px;
	padding: 0 0 0 10px;
	width: 100%;
	position: relative;
	white-space: nowrap;
}

.product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul li:last-child {
	margin: 0;
}

.product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul li:before {
	content: '';
	height: 4px;
	width: 4px;
	display: inline-block;
	background-color: $bk;
	position: absolute;
	top: 8px;
	left: 0;
}

.product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul li a:hover,
.product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul li .invoice:hover,
.product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul li span:hover,
.product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul li .button-credit:hover,
.product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul li .cheap:hover {
	color: $bk;
	border-bottom: 1px solid $bk;
}

.product-info #product .custom-product-meta,
.product-info #product .custom-product-meta-item:first-child .custom-quantity {
	margin: 0 0 15px;
	padding: 0;
	width: 100%;
}

.product-info #product .custom-product-meta-item:first-child .quantity {
	margin: 0;
	padding: 0;
	height: 40px;
	width: 125px;
}

.product-info #product .quantity .fa,
.product-info #product .quantity .quantity-number {
	font-size: 19px;
	line-height: 40px;
	color: #c41239;
	height: 40px;
}

.product-info #product .quantity .fa,
.product-info #product .quantity .form-control {
	font-weight: 700;
}

.product-info #product .quantity .form-control {
	line-height: 19px;
	color: $bk;
	position: relative;
	font-size: 12pt;
	height: 40px;
}

.product-info .cart-btn,
.product-info .cart-btn .cart #button-cart {
	width: 100%;
}

.product-info .cart-btn {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

.product-info #product .custom-product-meta {
	margin: 0 0 15px;
}

.product-info .cart-btn .compare .btn {
	height: 40px;
}

.page-product.row-offcanvas {
	position: relative;
}

.page-product.row-offcanvas:before {
	content: '';
	height: 900px;
	width: 100%;
	display: inline-block;
	background: url("/catalog/view/theme/kitchenrussia/img/cart-bg.jpg") no-repeat center;
	background-size: cover;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
}

#page {
	position: relative;
	z-index: 1;
}

.page-product #page {
	position: unset;
	z-index: unset;
}

.product-info .product-view {
	padding: 0 0 0 15px;
}

.product-info .row .product-info-bg .image-container .image-additional .imgthumbs {
	margin-bottom: 35px !important;
}

.product-info .cart-btn .cart .btn-cart {
	max-width: 100%;
}

.product-info .cart-btn .cart {
	min-width: 175px !important;
}

.product-info .cart-btn .cart .btn {
	height: 48px !important;
	width: 100% !important;
	max-width: 100% !important;
}

.product-info .cart-btn .compare .btn {
	height: 48px !important;
	width: 48px !important;
	padding: 14px 13px !important;
}

.product-info {
	.product-info-bg {
		.product-zoom {
			height: 33px;
			width: 33px;
			background-color: #f7f7f7 !important;
			border: 1px solid #eee;
			display: inline-block;
			position: absolute;
			top: 13px;
			left: 13px;
			z-index: 1;
			.left, .right {
				display: inline-block;
				position: absolute;
				top: 8px;
				left: 8px;
			}
			i {
				font-family: $fa;
				font-weight: 900;
				font-size: 12pt;
				color: #c82347;
				background-color: #f7f7f7;
				transform: rotate(45deg);
				position: absolute;
				&:after {
					content: '';
					height: 2px;
					width: 8px;
					display: inline-block;
					background-color: #c82347;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			.left i {
				left: -8px;
				top: -8px;
				&:after {
					right: 3px;
				}
			}
			.right i {
				right: -8px;
				bottom: -8px;
				&:after {
					right: 5px;
				}
			}
		}
	}
}

.product-info .image-additional .item .active:after,
.product-info .image-additional .item .active:before {
	display: none;
}

.product-info .image-additional .item .imagezoom:not(.active),
.product-info .image-additional .item {
	display: inline-block;
	border: 1px solid transparent !important;
	transition: all .1s linear;
}

.product-info .image-additional .item .imagezoom.active {
	display: inline-block;
	border: 1px solid #c82347;
}

.product-info .row {
	margin: 0 0 30px;
}

.page-navigation {
	margin: 0;
	width: 100%;
	border-top: 5px solid #c82347;
	box-shadow: 0 1px 10px rgba(0, 0, 0, .1);
	position: relative;
	z-index: 1;
}

.page-navigation__item {
	margin: 0;
	padding: 40px 0;
	width: 100%;
	list-style: none;
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

.page-navigation__item li {
	margin: 0 56px 0 0;
}

.page-navigation__item li:last-child {
	margin: 0 !important;
}

.page-navigation__item li a,
.page-navigation__item li a:focus {

	font-size: 14px;
	line-height: 14px;
	color: #333;
	text-transform: uppercase;
	transition: all .3s linear;
	text-decoration: none;
	border-bottom: 2px solid transparent;
}

.page-navigation__item li a:hover,
.page-navigation__item li a.active {
	color: #c41239;
	border-bottom: 2px solid #c41239;
}

.breadcrumbs .breadcrumb li:first-child [itemprop="name"] {
	font-size: 10pt;
	line-height: 1.2;
	color: black;
}

#module1 .mod-hero-banner .mod-hero-banner__image-wrapper {
	position: relative;
}

#module1 .mod-hero-banner .mod-hero-banner__image-wrapper picture,
#module1 .mod-hero-banner .mod-hero-banner__image-wrapper picture img {
	width: 100%;
}

#module1 .mod-hero-banner .mod-hero-banner__image-wrapper picture img {
	height: auto;
	display: block;
}

.mod-contents.mod-contents--triple .mod-contents__container {
	padding: 0 15px;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

#module4:nth-of-type(1),
#module5:nth-of-type(1) {
	position: relative;
}

.product-info .custom-related-products .desc-pane .products-block .product-label.new,
.product-label.new,
.product-label.new {
	padding: 5px 15px 5px 20px;
	width: 97px;
	border: none;
	color: #fff;
	background: rgb(255, 51, 94) no-repeat;
	background-size: 100% 100%;
}

.product-info .custom-related-products .desc-pane .products-block .product-label.new span,
.product-label.new span,
.product-label.new span {
	font-weight: 700;
	font-size: 9pt;
	position: relative;
	z-index: 1;
}

.product-info .custom-related-products .desc-pane .products-block .product-label.new:before,
.product-label.new:before,
.product-label.new:before {
	content: '';
	height: 27px;
	width: 83px;
	border-bottom: 27px solid #c11b40;
	border-left: 30px solid transparent;
	border-right: 0 solid transparent;
	position: absolute;
	right: 0;
	top: 0;
	opacity: 0.6;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
	padding: 0;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .viewed-box-item_image a img {
	height: auto;
	width: 100%;
	display: block;
}

/* Todo: Product popular */
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .viewed-box-item_image {
	padding: 10px 10px 0;
	position: relative;
	overflow: hidden;
	transition: all 0.4s ease 0s;
	text-align: center;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .viewed-box-item_image a {
	width: 100%;
	display: inline-block;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .top {
	position: relative;
	padding: 0 20px 10px 20px;
}

.viewed-box-popular-wrapper .viewed-box-content {
	margin: 0;
}

.tab-content .tab-description .desc-pane + div > .tab-characteristics > div .desc-pane .table,
.tab-content .tab-description .desc-pane + div > .tab-characteristics > div .desc-pane .table tr td {
	padding: 0;
	border: none;
}

.tab-content .tab-description .desc-pane + div > .tab-characteristics > div .desc-pane .table thead tr td,
.tab-content .tab-description .desc-pane + div > .tab-characteristics > div .desc-pane .table thead tr td strong {
	font-weight: 700;
	font-size: 12pt;
	line-height: 16px;
	color: $bk;
}

.tab-content .tab-description .desc-pane + div > .tab-characteristics > div .desc-pane .table tr td .td-item {
	margin: 0 30px 0 0;
	padding: 10px 15px;
	width: auto;
}

.tab-content .tab-description .desc-pane + div > .tab-characteristics > div .desc-pane .table tbody tr td:last-child .td-item {
	margin: 0;
}

.tab-content .tab-description .desc-pane + div > .tab-characteristics > div .desc-pane .table tbody tr:nth-of-type(even) td .td-item {
	background-color: #f6f6f6;
}

.tab-content .tab-description .desc-pane + div > .tab-characteristics > div .desc-pane .table .td-item {
	line-height: 18px;
	color: $bk;
	font-size: 10pt;
}

.tab-content .tab-description .desc-pane + div .custom-review-status {
	margin: 0 0 120px;
	padding: 45px 0 0;
	width: 100%;
	position: relative;
}

#custom-review-status {
	margin-bottom: 20px;
}

.tab-content .tab-description .desc-pane + div .custom-review-status:before {
	height: 100%;
	width: 100%;
	content: '';
	display: inline-flex;
	background: url('/catalog/view/theme/kitchenrussia/img/liders.png') no-repeat left top;
	background-size: 100% 100%;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons {
	margin: 0 0 -18px;
	width: 100%;
	text-align: center;
}

.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group-show-more {
	margin: 0 0 30px;
}

.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons {
	margin: 0;
	text-align: center;
}

.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn {
	padding: 0 15px;
	width: 200px;
	min-width: 200px;
	display: inline-block;
	float: none;
	clear: left;
	text-align: center;
}

.tab-content .tab-description .related-products-wrapp {
	background-color: #fff;
	position: relative;
}

.mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column {
	width: calc(100% / 3);
	display: -webkit-inline-flex;
	display: inline-flex;
}

.mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item,
.mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__media,
.mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__media .mod-content-item__image-wrapper,
.mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__media .mod-content-item__image-wrapper img {
	width: 100%;
}

.mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__media .mod-content-item__image-wrapper img {
	height: auto;
	display: block;
}

/* Review */
.tab-description #review .table {
	position: relative;
	z-index: 1;
}

.tab-description #review .table,
.tab-description #review .table thead,
.tab-description #review .table tbody,
.tab-description #review .table tbody tr,
.tab-description #review .table tbody tr td {
	border: none;
}

.tab-description #review .table tbody tr td .date-published {
	padding: 8px 9px 6px;
	background-color: #e6365d;

	font-size: 10pt;
	line-height: 13px;
	color: #fff;
	text-align: center;
}

.tab-description #review .table tbody tr td .rating {
	margin: 0;
	display: block;
}

.tab-description #review .table tbody tr td .custom-review-text,
.tab-description #review .table tbody tr td .custom-review-title {
	margin: 0 0 15px;
	line-height: 18px;
	color: #333;
}

.tab-description #review .table tbody tr td .custom-review-text {
	font-size: 10pt;
	line-height: 18px;
}

.tab-description #review .table tbody [itemprop="itemReviewed"] {
	background-color: transparent;
}

.tab-description .desc-pane [id] .mod-hero-banner .mod-hero-banner__body .mod-hero-banner__content {
	position: relative;
}

.tab-description .desc-pane [id] .mod-hero-banner .mod-hero-banner__body .mod-hero-banner__content .mod-hero-banner__content-left {
	height: 100%;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-align-content: center;
	align-content: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.mod-hero-banner__title {

	font-size: 33px;
	line-height: 44px;
	color: #325b6a;
}

.mod-hero-banner__title span:first-child {
	color: #8ca4ac;
}

.mod-hero-banner__description {

	font-size: 12pt;
	line-height: 24px;
	color: #325b6a;
}

.tab-description .desc-pane [id] .mod-hero-banner .mod-hero-banner__body .mod-hero-banner__content {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

.custom-accessories .custom-accessories-inner {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

.tab-description .desc-pane [id] .mod-heading.mod-heading--misty {
	margin: 0 auto;
	width: 1200px;
}

.tab-description .desc-pane [id] .mod-slideshow ul li .mod-slideshow__image-wrapper,
.tab-description .desc-pane [id] .mod-slideshow ul li .mod-slideshow__image-wrapper img {
	width: 100%;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .top > .viewed-box-item_rating.rating {
	height: 22px;
	text-align: center;
}

.custom-instructions {
	padding: 30px 0 60px;
	position: relative;
}

.custom-instructions .desc-header-wrapper {
	margin: 0;
}

.custom-instructions .container {
	position: relative;
	z-index: 1;
}

.custom-instructions:after {
	content: '';
	height: 142px;
	width: 137px;
	background: url('/catalog/view/theme/kitchenrussia/img/4411.png') no-repeat;
	background-size: 100% 100%;
	position: absolute;
	top: 0;
	left: -70px;
}

.custom-instructions:before {
	content: '';
	height: 100%;
	width: 100%;
	display: -webkit-inline-flex;
	display: inline-flex;
	background: url('/catalog/view/theme/kitchenrussia/img/liders.png') no-repeat center;
	background-size: 100% auto;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.custom-accessories {
	padding: 30px 0 0;
}

.custom-accessories .container {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-align-content: center;
	align-content: center;
}

.custom-accessories .custom-accessories-inner {
	width: 86%;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	position: relative;
}

.custom-accessories .custom-accessories-inner .custom-accessories-columns {
	width: 33%;
}

.products-block.col-nopadding.custom-catalog {
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	width: 100%;
}

.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .top .product-colors ul,
.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .top .product-colors ul li {
	width: auto;
	display: inline-block;
}

#tab-description img {
	width: 100%;
}

.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content,
.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content p,
.mod-text-photo__notes,
.mod-text-photo__notes p,
.mod-content__content,
.mod-content__content p {
	font-size: 14px;
	line-height: 18px;
}

.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item .mod-content-item__container {
	padding: 15px;
}

.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
	position: absolute;
	left: -7px;
	top: calc(50% - 20px);
	background: url('/catalog/view/theme/kitchenrussia/img/arrows.svg') no-repeat;
	background-size: cover;
	width: 42px;
	height: 40px;
}

.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
	position: absolute;
	right: -7px;
	top: calc(50% - 20px);
	background: url('/catalog/view/theme/kitchenrussia/img/arrows.svg') no-repeat;
	background-size: cover;
	width: 42px;
	height: 40px;
	transform: scale(-1, 1);
}

.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev:before,
.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next:before,
.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev i,
.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next i {
	display: none;
}

.tab-description .desc-pane [id] .mod-contents .slick-track .owl-stage-outer {
	padding: 15px 0;
}

.product-colors ul,
.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .top .product-colors ul {
	width: 100%;
	list-style: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-align-content: flex-start;
	align-content: flex-start;
	padding: 0;
}

.product-colors ul li {
	margin: 0 4px 8px;
}

.owl-carousel .owl-stage {
	display: inline-flex;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
}

.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .viewed-box-item_name {
	word-wrap: break-word;
}

.product-info .row .product-info-bg .image-container .custom-image-container {
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.product-info .row .product-info-bg .image-container .thumbnail {
	height: 627px;
}

.product-info .row .product-info-bg .image-container .thumbnail a {
	height: 100%;
	width: 100%;
	display: inline-block;
}

.product-info .row .product-info-bg .image-container .thumbnail a img {
	height: 100% !important;
	object-fit: contain !important;
}

.tab-description .desc-pane [id] .mod-slideshow ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.tab-description *:focus {
	outline: none !important;
}

.custom-instructions .container .desc-pane ul li a {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

.custom-instructions .container .desc-pane ul li a {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
}

.tab-description .desc-pane [id] .mod-slideshow ul .mod-slideshow__item.slick-slide {
	padding: 0 15px;
	height: 600px;
	overflow: hidden;
}

.tab-description .desc-pane [id] .mod-slideshow ul li .mod-slideshow__image-wrapper img {
	object-fit: cover;
	position: relative;
	top: -15%;
}

.product-info .row .product-info-bg .image-container .product-label.new {
	padding: 5px 15px 5px 20px;
	border: none;
	color: #fff;
	background: rgb(255, 51, 94) no-repeat;
	background-size: 100% 100%;
	left: inherit !important;
	top: 9px;
}

.img {
	height: auto;
	width: 100%;
	display: block;
}

.custom-accessories .custom-accessories-inner .custom-accessories-columns .custom-accessories__items {
	margin: 0 0 17px;
	position: relative;
}

.custom-accessories .custom-accessories-inner .custom-accessories-columns .title-link {
	padding: 24px 15px;
	width: 100%;
	background: rgba(0, 0, 0, .5);
	text-align: center;
	position: absolute;
	left: 0;
	bottom: 0;
}

.custom-accessories .custom-accessories-inner .custom-accessories-columns .title-link span {
	width: auto;
	display: inline-block;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 12pt;
	line-height: 16px;
	color: #fff;
}

.custom-accessories .custom-accessories-inner .custom-accessories-columns:first-child .custom-accessories__items .images {
	border-left: 2px solid #bc3851;
}

.custom-accessories .custom-accessories-inner .custom-accessories-columns:last-child .custom-accessories__items .images {
	border-right: 2px solid #bc3851;
}

.custom-accessories-center .custom-accessories__center {
	width: 100%;
}

.custom-accessories-center .custom-accessories__center .custom-accessories__items .images {
	height: 100%;
	width: 421px;
	position: relative;
	left: -12.666%;
	top: 0;
	z-index: 2;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2)
}

.custom-accessories-center .btn-group {
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
}

.custom-accessories-center .btn-group .btn,
.custom-accessories-center .btn-group .btn-book-recipes,
.product-info .cart-btn .cart .btn-cart,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn,
.custom-catalog .product-block .cart .btn,
.featured .product-block .product-meta .bottom .cart .custom-left-btn,
.product-meta .bottom .cart .btn,
.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .cart .btn,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn {
	max-width: 235px;
	width: 145px;
	border: none;
	background: #e4345b !important;
	background-size: 100% 100%;
	height: 40px;
	padding: 0 0 0 20px;
	position: relative;
	border-top: 2px solid #be1e43 !important;
	transition: none;
	box-shadow: 0 4px 19px rgba(194, 18, 57, 0.35);
}

.custom-accessories-center .btn-group .btn-book-recipes span {
	z-index: 1;
}

.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn {
	max-width: 200px;
	width: 200px;
}

.product-info .cart-btn .cart .btn-cart,
.product-block .product-meta .bottom .cart .btn,
.featured .product-block .product-meta .bottom .cart .custom-left-btn,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn,
.owl-item .product-block .bottom .cart .btn {
	max-width: 145px;
}

.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn {
	width: 200px;
	max-width: 200px;
}

.product-info .cart-btn .cart,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart,
.btn-book-recipes {
	display: flex;
	justify-content: center;
	align-items: center;
	span {
		color: #fff;
		text-transform: uppercase;
	}
}

.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn span {
	left: -10px;
}

.custom-accessories-center .btn-group .btn-book-recipes span:before,
.product-info .cart-btn .cart .btn-cart span:before {
	background-image: url(/catalog/view/theme/kitchenrussia/img/accessories/book.png);
	position: absolute;
	left: -45px;
	top: -5px;
	width: 30px;
	content: '';
	height: 27px;
	background-size: cover;
}

.featured .product-block .product-meta .bottom .cart .custom-left-btn span:before {
	left: 10px;
	top: -5px;
}

.custom-accessories-center .btn-group .btn-book-recipes:after,
.product-info .cart-btn .cart .btn-cart:after,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn:after,
.custom-catalog .product-block .cart .btn:after,
.featured .product-block .product-meta .bottom .cart .custom-left-btn:after,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn:after,
.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .cart .btn:after,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -9px;
	border: 8px solid transparent;
	border-left: 10px solid #e5355c;
	border-top: 6px solid #e5355c;
}

.custom-accessories-center .btn-group .btn-book-recipes:before,
.product-info .cart-btn .cart .btn-cart:before,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn:before,
.custom-catalog .product-block .cart .btn:before,
.featured .product-block .product-meta .bottom .cart .custom-left-btn:before,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn:before,
.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .cart .btn:before {
	content: '';
	height: 38px;
	width: 50%;
	border-bottom: 38px solid #c7163d;
	border-left: 30px solid transparent;
	border-right: 0 solid transparent;
	position: absolute;
	right: 0;
	top: 0;
}

.product-info .cart-btn .cart .btn:before {
	border-bottom: 45px solid #c7163d;
}

.product-info .cart-btn .cart .btn-cart:before,
.custom-catalog .product-block .cart .btn:before,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn:before,
.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .cart .btn:before {
	width: 83px;
}

.custom-accessories-center .btn-group .btn:hover,
.custom-accessories-center .btn-group .btn-book-recipes:hover,
.product-info .cart-btn .cart .btn-cart:hover,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn:hover,
.custom-catalog .product-block .cart .btn:hover,
.featured .product-block .product-meta .bottom .cart .custom-left-btn:hover,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn:hover,
.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .cart .btn:hover,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn:hover, .success_button:hover {
	background: #c72d4f !important;
}

.custom-accessories-center .btn-group .btn:hover:before,
.custom-accessories-center .btn-group .btn-book-recipes:hover:before,
.product-info .cart-btn .cart .btn-cart:hover:before,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn:hover:before,
.custom-catalog .product-block .cart .btn:hover:before,
.featured .product-block .product-meta .bottom .cart .custom-left-btn:hover:before,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn:hover:before,
.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .cart .btn:hover:before,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn:hover:before {
	border-bottom: 38px solid #ab0f32 !important;
}

.product-info .cart-btn .cart .btn:hover:before {
	border-bottom: 45px solid #ab0f32 !important;
}

.custom-accessories-center .btn-group .btn:hover:after,
.custom-accessories-center .btn-group .btn-book-recipes:hover:after,
.product-info .cart-btn .cart .btn-cart:hover:after,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group .btn:hover:after,
.custom-catalog .product-block .cart .btn:hover:after,
.featured .product-block .product-meta .bottom .cart .custom-left-btn:hover:after,
.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn:hover:after,
.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .cart .btn:hover:after,
.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn:hover:after, .success_button:hover:after {
	border-left: 10px solid #c72d4f !important;
	border-top: 6px solid #c72d4f !important;
}

.custom-related-products .desc-pane .products-block .product-col-wrap {
	width: auto;
}

.custom-accessories-center .custom-accessories__center,
.custom-accessories-center .custom-accessories-heading {
	margin: 0 0 70px;
}

.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .btn-group-show-more .btn {
	max-width: 175px !important;
	min-width: 175px !important;
	width: 175px !important;
}

/* Todo: End last block */
.product-info .row .product-info-bg [class*="col-"]:first-child {
	width: 58.167%;
}

.product-info .row .product-info-bg [class*="col-"]:last-child {
	width: 40%;
}

.breadcrumb > li + li:before {
	content: '\003E';
	padding: 0 5px;
	color: #ce3959;
}

.price .price-old {
	font-weight: 700;
	position: relative;
	text-decoration: line-through;
}

.price .price-old::before {
	position: absolute;
	left: 0;
	right: 0;
}

.tab-description .desc-pane [id] .mod-text-photo .mod-text-photo__column.mod-text-photo__column--media.mod-text-photo__column--triple {
	width: 100%;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-align-content: flex-start;
	align-content: flex-start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
}

.featured .panel-heading .panel-title {
	line-height: 28px;
}

.featured .panel-heading .panel-title span {
	display: block;
}

.custom-related-products .desc-pane .products-block .product-col-wrap, .featured .product-block {
	margin: 0;
}

.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-stage-outer {
	padding: 15px 0;
}

.product-info #product .custom-product-meta-item:last-child .quantity {
	margin: 0;
	padding: 0;
	height: 40px;
	width: 125px;
	border: 1px solid #c9c9c9;
	box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.product-info #product .custom-product-meta-item:last-child .quantity span .fa,
.product-info #product .custom-product-meta-item:last-child .quantity .add-down .fa,
.product-info #product .custom-product-meta-item:last-child .quantity .quantity-number,
.product-info #product .custom-product-meta-item:last-child .quantity .quantity-number .form-control {
	font-size: 19px;
	line-height: 40px;
	color: #c41239;
}

.product-info #product .custom-product-meta-item:last-child .quantity span .fa,
.product-info #product .custom-product-meta-item:last-child .quantity .add-down .fa,
.product-info #product .custom-product-meta-item:last-child .quantity .add-up .fa,
.product-info #product .custom-product-meta-item:last-child .quantity .quantity-number .form-control {
	font-weight: 700;
}

.product-info #product .custom-product-meta-item:last-child .quantity .quantity-number .form-control,
.product-info #product .custom-product-meta-item:last-child .quantity .quantity-number {
	height: auto;
	line-height: 19px;
	color: $bk;
	position: relative;
	bottom: -7px;
}

.product-info #product .custom-product-meta-item:last-child .quantity .quantity-number .form-control {
	bottom: -4px;
}

.product-info #product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice,
.product-info #product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul {
	margin: 0;
	padding: 0;
	width: 100%;
	list-style-type: none;
}

.product-info #product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li {
	margin: 0 0 8px !important;
	padding: 0 0 0 10px;
	width: 100%;
	position: relative;
}

.product-info #product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li:before {
	content: '';
	height: 4px;
	width: 4px;
	display: inline-block;
	background-color: $bk;
	position: absolute;
	top: 8px;
	left: 0;
}

.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-bottom .owl-prev {
	top: 25% !important;
	transform: translateY(-25%) !important;
}

.product-info .title-product {
	margin: 0 0 20px !important;
	padding-top: 0 !important;
	text-transform: uppercase !important;
}

.product-info .title-product,
.breadcrumbs .container .breadcrumb {
	text-align: left !important;
}

.product-info .title-product:before {
	display: none !important;
}

.featured .product-block .product-meta .bottom {
	margin: 0 0 25px;
}

.featured .product-block .product-meta .bottom .compare {
	margin: -10px 0 0 10px;
	height: 40px;
}

.product-info .row {
	position: relative;
}

.product-info .row .product-info-bg .image-container .image-additional .imgthumbs {
	padding: 0 40px !important;
	margin-bottom: 17px !important;
}

body[class*="page-"] #content img {
	max-width: 100% !important;
}

.product-info .image-additional .item .imagezoom:not(.active), .product-info .image-additional .item {
	width: 100% !important;
	border: none !important;
}

.product-info .image-additional .item {
	margin: 0 !important;
}

.featured .product-block .block-img .image {
	width: auto;
	display: inline-block;
}

.featured .product-block .product-meta .top {
	margin: 0;
	width: 100%;
	padding: 0;
	display: inline-block;
}

.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-stage-outer {
	padding: 30px 0;
	width: 100%;
}

.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-prev,
.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-next {
	top: calc(50% - 20px);
}

.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-stage-outer {
	width: 100%;
}

.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-next {
	top: calc(50% - 20px);
	right: -7px;
}

.custom-related-products .desc-pane .products-block .owl-nav .owl-prev,
.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
	top: 50% !important;
	transform: translateY(-50%) !important;
}

.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
	top: 52% !important;
	transform: translateY(-50%) scale(-1, 1) !important;
}

.owl-carousel .owl-stage {
	position: relative;
	left: 2px;
}

.tab-content .tab-description .related-products-wrapp .custom-related-products .desc-pane .products-owl-carousel.widget-products {
	padding: 0;
}

.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-bottom .owl-prev,
.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-bottom .owl-next {
	top: 42.3% !important;
	transform: translateY(-50%) !important;
}

.custom-bottom .owl-next {
	transform: translateY(-50%) scale(-1, 1) !important;
}

.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-prev,
.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.featured .custom-bestsellers-popular-slider .owl-next {
	top: calc(45.4% - 25px);
}

.tab-content .tab-description .related-products-wrapp .custom-related-products .desc-pane .products-owl-carousel.widget-products .owl-nav .owl-prev {
	top: 50% !important;
	transform: translateY(-50%) !important;
}

.product-info .row .product-info-bg .image-container .image-additional .imgthumbs a {
	height: 82px;
	width: 82px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
}

.tab-description #review .table tbody [itemprop="itemReviewed"] [itemprop="author"] {
	font-size: 14pt;
}

.tab-description #review .table tbody tr td .custom-review-title {
	font-size: 12pt;
}

.tab-description #review .table tbody tr td .custom-review-title {
	margin: 0 0 8px;
}

.custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .bottom .compare .btn,
.custom-bottom .custom-producttabs .panel-body .box-products .owl-item .product-block .bottom .compare .btn {
	height: 40px;
}

.featured .product-block {
	padding: 0;
	width: 99%;
}

.product-info .product-stars-and-review-inner .product-stars,
.product-info .product-stars-and-review-inner .product-review {
	margin: 0 15px 20px 0;
}

.product-info .product-stars-and-review-inner {
	margin: 0 0 15px;
}

.product-info .row .product-info-bg .image-container .image-additional .imgthumbs a {
	flex-direction: row;
}

.product-info .image-additional .owl-nav {
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	z-index: 222;
}

#image-additional .owl-prev,
#image-additional .owl-next {
	top: 50%;
	transform: translateY(-50%);
}

#image-additional .owl-next {
	transform: translateY(-50%) scale(-1, 1);
}

.product-info .row .product-info-bg .image-container .image-additional .imgthumbs a {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

/* Todo: slick slide images blender */
.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li .slick-list.draggable .slick-track ul .owl-nav .owl-prev,
.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li .slick-list.draggable .slick-track ul .owl-nav .owl-next {
	top: 50%;
	transform: translateY(-50%);
}

.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li .slick-list.draggable .slick-track ul .owl-nav .owl-prev {
	left: -7px;
}

.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li .slick-list.draggable .slick-track ul .owl-nav .owl-next {
	right: -7px;
	transform: translateY(-50%) scale(-1, 1);
}

.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li .slick-list.draggable .slick-track ul li {
	padding: 0;
	height: auto;
}

.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li {
	color: transparent;
}

.tab-description .desc-pane [id] .mod-slideshow .mod-slideshow__list.slick-initialized.slick-slider li .slick-list.draggable .slick-track ul li .mod-slideshow__image-wrapper .mod-slideshow__image {
	height: auto;
	width: 100%;
	display: block;
}

.product-info .row .product-info-bg [class*="col-"]:last-child .cart.pull-left.cart-btn .cart {
	padding-bottom: 10px;
}

.product-info .row .product-info-bg [class*="col-"]:last-child .cart.pull-left.cart-btn {
	margin: 50px 0 25px;
}

.breadcrumbs {
	padding: 22px 0px 10px 0px;
}

.product-info .product-description .product-model .product-model-item, .product-info .product-description .product-manufacturer .product-manufacturer-item, .product-info .product-description .product-guarantee .product-guarantee-item, .product-info .product-description .product-manufacturer-country .product-manufacturer-country-item, .product-info .product-colors .product-colors-item:first-child {
	margin: 0 4px 10px 0;
}

.product-info {
	margin-bottom: 15px !important;
}

.product-info .product-description .product-manufacturer-country .product-manufacturer-country-item:last-child span {
	margin: 5px 0 0 !important;
}

.product-info .product-description .product-guarantee .product-guarantee-item {
	margin-bottom: 10px !important;
	display: inline-block !important;
}

.product-info .row .product-info-bg [class*="col-"]:last-child .product-colors ul {
	padding: 0;
	height: auto;
	justify-content: flex-start;
}

.product-info .row .product-info-bg [class*="col-"]:last-child .product-colors ul li:not(.product-colors-item) {
	margin: 0 5px 8px;
	position: relative;
}

.custom-catalog .product-block .product-meta .top .product-colors ul li {
	position: relative;
}

.custom-catalog .product-block .product-meta .top .product-colors ul li:before,
.product-info .row .product-info-bg [class*="col-"]:last-child .product-colors ul li:not(.product-colors-item):before {
	content: '';
	height: 38px;
	width: 30px;
	display: inline-block;
	background: url("/catalog/view/theme/kitchenrussia/img/accessories/color-backing-border.png") no-repeat center;
	background-size: 100% 100%;
	position: absolute;
	top: -2px;
	left: -2px;
}

.custom-catalog .product-block .product-meta .top .product-colors ul li:before {
	height: 25px;
	width: 20px;
	top: -1px;
	left: -1px;
}

.custom-catalog .product-block .product-meta .top .product-colors ul li span {
	border-right: 11px solid transparent;
}

.product-info .row .product-info-bg [class*="col-"]:last-child .product-colors ul li:not(.product-colors-item) a {
	height: 27px;
	width: 27px;
	display: inline-block;
}

.product-info .row .product-info-bg [class*="col-"]:last-child .product-colors ul li:not(.product-colors-item) span {
	bottom: 0;
	border-right: 11px solid transparent;
}

.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .product-col-wrap .custom-item .image .product-label.sale {
	background-color: inherit;
	padding: 0;
	left: auto;
	right: 0;
	border: none;
}

.tab-description .desc-pane [id] .mod-video.mod-video--double.mod-video--light .mod-video__content-wrapper .mod-content.mod-video__content .mod-content__title {
	font-weight: 700;
	text-align: center;
}

.owl-carousel .owl-item img {
	width: auto;
	margin-left: auto;
	margin-right: auto;
}

.custom-catalog .product-block .image {
	padding-top: 40px;
}

.custom-catalog .product-block .img-link img {
	max-width: 190px;
	margin: 0 auto;
}

.product-list .product-block {
	width: 100%;
}

#content .product-label img {
	min-height: 56px;
	min-width: 53px !important;
}

.success .close svg,
#reserve_form .close {
	height: 15px !important;
	width: 15px !important;
}

#reserve_form .close {
	color: $bk;
	opacity: 1;
	font-size: 30px;
	line-height: 0;
	position: relative;
	z-index: 1;
}

.success h2,
#reserve_form .modal-title {
	margin: 0 auto 11px;
	padding: 22px 0 0;
	font-size: 12pt;
	line-height: 16px;
	font-weight: 700;
	text-transform: uppercase;
	color: $bk;
	position: relative;
}

.success h2:before,
#reserve_form .modal-title:before {
	margin: 0;
	content: '';
	height: 3px;
	width: 30px;
	background-color: #c41239;
	display: inline-block;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

#reserve_form .control-label {
	font-weight: 700;
	color: #e4345b;
}

#reserve_form .modal-title:before {
	left: 0;
	transform: initial;
}

.success form p {

	color: $bk;
}

.success form p a, .product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul li a,
.product-info #product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul li span {
	margin: 0;
	padding: 0 0 4px;
	width: auto;
	display: inline-block;

	font-weight: 400;
	font-size: 12pt;
	line-height: 16px;
	color: #c41239;
	text-decoration: none;
	cursor: pointer;
	border-bottom: 1px solid transparent;
	transition: all .3s linear;
}

.success form .validationError + div > #send-user-error,
#reserve_form form[name="reserve_form"] .reserve_button.pull-right {
	margin-left: auto;
	display: block;
	margin-top: 10px;
	max-width: 145px;
	border: none;
	background: #e4345b;
	font-weight: 700;
	background-size: 100% 100%;
	height: 40px;
	position: relative;
	border-top: 2px solid #be1e43;
	transition: none;
	color: #fff;
	box-shadow: 0 4px 19px rgba(194, 18, 57, 0.35);
	z-index: 2;
	font-size: 14px;
	line-height: 14px;
}

#reserve_form form[name="reserve_form"] .reserve_button.pull-right {
	max-width: 100%;
}

.success form .validationError + div > #send-user-error:before,
#reserve_form form[name="reserve_form"] .reserve_button.pull-right:before {
	content: '';
	height: 38px;
	width: 83px;
	border-bottom: 38px solid #c7163d;
	border-left: 30px solid transparent;
	border-right: 0 solid transparent;
	position: absolute;
	right: 0;
	z-index: -1;
	top: 0;
}

.success form .validationError + div > #send-user-error:after,
#reserve_form form[name="reserve_form"] .reserve_button.pull-right:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -9px;
	border: 8px solid transparent;
	border-left: 10px solid #e5355c;
	border-top: 6px solid #e5355c;
}

.custom-category-sidebar .panel-body .accordion .accordion-group a.active + .accordion-heading.pull-right .bg .fa {
	color: #fff;
	font-size: 8pt;
}

.custom-category-sidebar .panel-body .accordion .accordion-group .collapse.accordion-body.in li {
	height: auto;
}

.custom-category-sidebar .panel-body .accordion .accordion-group .collapse.accordion-body {
	padding-top: 13px !important;
}

.custom-category-sidebar .panel-body .accordion .accordion-group .collapse.accordion-body.in {
	padding-top: 13px;
	padding-bottom: 0;
}

.custom-category-sidebar .panel-body .accordion .accordion-group .collapse.accordion-body li a {
	padding: 1px 0;
	height: auto;
	width: auto;
	margin: auto;
	font-weight: 400;
}

.custom-category-sidebar .panel-body .accordion .accordion-group .accordion-body li a.active {
	background-color: transparent;
	font-weight: 700;
}

.tree-menu ul ul {
	padding-top: 13px !important;
}

.product-info .row .form-group label.col-sm-3.control-label {
	width: 20%;
}

.product-info .row .form-group .col-sm-9 {
	width: 80% !important;
}

.accordion-heading [data-toggle="collapse"].bg.collapsed .fa-angle-down:before {
	color: #fff;
}

.tab-content .tab-description .desc-pane + div .custom-review-status .desc-pane .accordion-body .buttons .btn {
	padding: 0;
}

/* Todo: Owl FancyBox overlay */
.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
	width: 100% !important;
	max-width: 480px !important;
}

.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-outer {
	overflow: hidden;
}

.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-outer .fancybox-inner {
	height: auto !important;
	width: 100% !important;
}

.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-outer .fancybox-inner .mod-accessories__root .owl-item {
	width: 450px !important;
	min-width: 450px;
}

.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-outer .fancybox-inner .mod-accessories__root .owl-item .mod-accessories__item .mod-accessories__placeholder .mod-accessories__thumbnail {
	height: auto !important;
	width: 100% !important;
	display: block !important;
}

.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-outer .fancybox-inner .mod-accessories__root .owl-item .mod-accessories__item .mod-accessories__placeholder .mod-accessories__expand,
.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-outer .fancybox-inner .mod-accessories__root .owl-nav .owl-prev i,
.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-outer .fancybox-inner .mod-accessories__root .owl-nav .owl-next i,
.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-outer .fancybox-inner .mod-accessories__root .owl-nav .owl-prev:before,
.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-outer .fancybox-inner .mod-accessories__root .owl-nav .owl-next:before {
	display: none;
}

.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-outer .fancybox-inner .mod-accessories__root .owl-nav .owl-prev {
	position: absolute;
	left: 0;
	top: 50%;
	background: url('/catalog/view/theme/kitchenrussia/img/arrows.svg') no-repeat;
	background-size: cover;
	width: 42px;
	height: 40px;
}

.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-outer .fancybox-inner .mod-accessories__root .owl-nav .owl-next {
	position: absolute;
	right: 0;
	top: 50%;
	background: url('/catalog/view/theme/kitchenrussia/img/arrows.svg') no-repeat;
	background-size: cover;
	width: 42px;
	height: 40px;
	transform: scale(-1, 1);
}

.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-outer .fancybox-inner .mod-accessories__root .owl-item .mod-accessories__item .mod-accessories__name {
	padding: 15px 0 0;
	width: 100%;
	display: flex;
	justify-content: center;

	color: $bk;
	font-size: 18px;
	line-height: 18px;
}

.main-columns.custom-bestsellers-popular .page-navigation {
	overflow: hidden;
}

.main-columns.custom-bestsellers-popular .page-navigation:after {
	content: '';
	height: 148px;
	width: 123px;
	display: inline-block;
	background: url('/catalog/view/theme/kitchenrussia/img/412.png') no-repeat;
	background-size: cover;
	position: absolute;
	bottom: -70%;
	right: 30px;
}

#buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices span.mob-el {
	display: none;
}

/* Todo: End Responsive mobile */
/*====================================================================================================================*/