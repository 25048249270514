$bk: #333 !default;
$wh: #fff !default;
$tc: #c41239 !default;
$tc2: #ed1c24 !default;

$lg: #f2f1ef !default;
$mg: #959595 !default;
$dg: #777 !default;
$ddg: #3e3936 !default;
$lb: #5c97bf !default;
$bc: #d7d7d7 !default;
$lbc: #e6e6e6 !default;
$b: #c41239 !default;

$f: 'Montserrat', sans-serif !default;
$hf: 'Montserrat', sans-serif !default;
$fa: "FontAwesome" !default;


@import "fonts";
@import "bootstrap";
@import "vars/opencart";
@import "vars/elements.vars";
@import "mixins/app";

@import "owl/owl.carousel";

@import "app/base";
@import "app/elements";
@import "app/form";
@import "app/layout";
@import "app/block";
@import "app/product";
@import "app/modules";
@import "app/menu";
@import "opencart/opencart-modules";
@import "opencart/opencart-pages";
@import "opencart/offcanvas";
@import "opencart/style";
@import "opencart/theme";

@import "opencart/modules/megamenu.scss";
@import "opencart/modules/sphinx.scss";

@import "app/custom";

@import "../stylesheet/rc-temp.scss";
@import "../stylesheet/custom.scss";
@import "opencart/responsive";
@import "../stylesheet/responsive.scss";
