// banner builder
.banner-wrapper-margin {
	.banner-wrapper {
		margin-bottom: 20px;
	}
}

.image-item-margin {
	.image-item {
		margin-bottom: 20px;
	}
}

.nomargin .box {
	margin-bottom: 0;
}

.featured-category {
	.panel-body {
		overflow: hidden;
		padding: 0;
	}
	ul {
		margin: 0;
		li {
			display: inline-block;
			width: 33%;
			position: relative;
			&:before {
				position: absolute;
				right: 0;
				background: $border-color;
				content: "";
				@include size(1px, 200%);
				top: -20px;
			}
			&:after {
				position: absolute;
				left: 25px;
				background: $border-color;
				content: "";
				@include size(87%, 1px);
				top: 0;
			}
			&:nth-child(1), &:nth-child(2), &:nth-child(3) {
				&:after {
					content: none;
				}
			}
			&:nth-child(3n) {
				&:before {
					content: none;
				}
			}
			.image {
				margin: 5px 0 5px 25px;
			}
			.caption {
				overflow: hidden;
				padding: 30px 20px;
				h6 {
					font-weight: normal;
					font-size: $font-size-base + 2;
					a {
						color: $black;
					}
				}
			}
		}
	}
}