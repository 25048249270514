@media screen and (max-width: 1600px) {
	.home-about_us:after {
		left: -50px;
	}

	.information-information-23, .information-information-25 {
		.main-columns.container {
			width: 100%;
		}
	}
}

@media (max-width: $screen-md-max) {
	.logo-store a span {
		font-size: 13px;
		left: 0;
		bottom: -9px;
	}
}

@media (max-width: 1024px) {
	.product-info {
		#product {
			.custom-product-meta {
				.product-prices {
					ul {
						li {
							.list-prices {
								flex-direction: column;
								align-items: flex-start;

								span {
									&:first-child {
										padding-bottom: 5px;
									}
								}
							}
						}
					}
				}
			}
		}
		.markdown-product {
			top: 0 !important;
		}
	}
	#tab-description {
		section {
			.mod {
				&-contents {
					&--triple {
						&.maxH300-OvfHidden {
							.mod-content-item__media {
								img {
									object-fit: cover;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 992px) {
	#cart {
		margin-left: 15px;
	}
}

@media (max-width: $screen-sm-max) { //991 px
	.widget-images {
		margin-bottom: 30px;
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.panel {
			margin-bottom: 15px;
		}
	}
	#pavo-footer-center {
		.container {
			padding-right: 25px;
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			.product-grid .product-block {
				width: calc(100% / 3 - 13px);
				margin: 5px;
			}
			width: calc(100% / 3 - 13px);
		}
	}
	.header-menu > .container {
		padding-left: 0;
	}
	#powered {
		.payment {
			order: 1;
			text-align: center;
			.payment-img {
				float: none !important;
				max-width: 80%;
				display: inline-block;
			}
		}
	}
	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.product-compare-header {
		width: 60px;
		position: relative;
		flex: 0;
		a {
			color: $bk;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.product-markdown-view {
		#product {
			.custom-product-meta {
				flex-direction: column;
			}
		}
	}
}

@media (max-width: $screen-sm) { // 768px
	.quickview, .zoom {
		display: none !important;
	}

	.information-information-23, .information-information-25 {
		#modular-landing {
			.mod-home-banner__box-wrp,
			.mod-text-photo--text-boxed-on-left,
			.mod-text-photo--text-boxed-on-right,
			.mod-video--half-width {
				flex-direction: column-reverse;
			}
			.mod-home-banner__box-wrp {
				.mod-home-banner__box--image {
					height: auto;
				}
			}
			.mod-video--half-width {
				.mod-video__media-wrapper {
					padding-top: 75%;
					width: 100%;
				}
				.mod-video__content-wrapper {
					width: 100%;
					.mod-video__content {
						.mod-content__title {
							margin: 0 0 10px 0;
							font-size: 2.4375rem;
							line-height: 2.6875rem;
						}
						.mod-content__content {
							p {
								& :last-child {
									margin: 20px 0 0 0 !important;
								}
							}
						}
					}
				}
			}
			.mod-hero-column-banner__cta-wrapper {
				width: 100%;
			}
			.mod-home-banner__wrp {
				.mod-home-banner__image-cnt {
					height: 400px;
				}
			}
			.mod-home-banner--full-width {
				.mod-home-banner__content {
					padding-top: 40px;
					height: auto;
				}
			}
			.mod-heading--light {
				.mod-content.mod-heading__content {
					max-width: 100%;
					width: auto;
				}
			}
			.mod-contents--double {
				.mod-contents__column {
					width: 100%;
					float: none;
				}
			}
			.mod-content__title {
				line-height: 5.5rem;
			}
			#module9 {
				.mod-video__content {
					.mod-content__content {
						margin: 0 !important
					}
				}
			}
			.mod-text-photo--text-boxed-on-left,
			.mod-text-photo--text-boxed-on-right {
				.mod-text-photo__column--media {
					flex: 100%;
					max-height:100%;
				}
				.mod-text-photo__column {
					.mod-text-photo__content {
						position: relative;
						width: 100%;
						top: 0;
						left: 0;
						box-shadow: none;
					}
					.mod-text-photo__media {
						padding-bottom: 0;
						.mod-text-photo__image-wrapper {
							img {
								position: relative;
							}
						}
					}
				}
			}
			.mod-text-photo--text-boxed-on-right {
				margin-bottom: 2rem;
			}
			.mod-hero-column-banner-wrapper {
				& > * {
					flex: 100%;
				}
			}
			#module11 .mod-text-photo--text-boxed-on-left,
			#module15 .mod-hero-column-banner-wrapper,
			#module15 .mod-hero-column-banner,
			#module15 .mod-hero-column-banner__actions {
				flex-direction: column;
			}
			#module15 .mod-hero-column-banner-wrapper,
			#module15 .mod-hero-column-banner {
				max-width: 100%;
				padding: 0;
				margin-bottom: 20px;
			}
			#module1 {
				margin-right: 0;
			}
			#module15 .mod-hero-column-banner__title {
				font-size: 2.375rem;
				line-height: 3.125rem;
				letter-spacing: -0.050em;
				max-width: 100%;
				font-weight: 700;
			}
			#module15 .mod-hero-column-banner__actions {
				height: auto;
			}

		}
	}
	// product page for miksery
	#tab-description {
		section {
			.mod-contents {
				&__sub-title {
					padding: 0 2rem;
				}
				&--triple {
					&.maxH300-OvfHidden {
						margin-top: 0;
					}
				}
			}
			.mod-video {
				&__content-wrapper {
					.mod-video__content {
						padding: 0 2rem;
					}
				}
			}
			.mod-text-photo {
				flex-direction: column;
				margin: 0;
				padding-top: 0;
				&--text-boxed-on-left, &--text-boxed-on-right {
					.mod-text-photo__column {
						&--content {
							position: relative;
							width: 100%;
							left: 0;
							top: 0;
							.mod-text-photo__content {
								padding: 4rem;
							}
							.mod-text-photo-notes-container {
								.mod-text-photo__notes {
									margin: 0;
									text-align: left;
									padding: 2rem 4rem;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: $screen-xs-max + 1) { // 767 + 1 px
	.featured-category ul li {
		width: 100%;
	}
	#powered ul.links {
		margin-bottom: 40px;
	}
	.producttabs {
		margin-top: 15px;
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.tab-v3 {
		.nav-tabs {
			flex-wrap: wrap;
			border: none;
			& > li {
				width: 100%;
				text-align: center;
				border: none;
				&:last-child {
					border: none;
				}
				&.active a {
					border: none;
				}
				& > a {
					border: none;
					padding: 10px 15px;
					font-size: 18px;
				}
			}
		}
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table, .shopping-cart-table {
		background: #fff;
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		&, & tbody, & td, & tr {
			display: block;
			border: 0;
			width: 100%;
		}
		td {
			position: relative;
			padding-left: 43% !important;
			text-align: left !important;
			white-space: normal !important;
			&:first-child {
				border-top: 0;
				padding: 0 !important;
				text-align: center !important;
			}
			div.th-title {
				display: block;
				position: absolute;
				left: 10px;
				width: 38%;
				padding-right: 10px;
				text-align: right;
				top: 50%;
				@include transform(translateY(-50%));
				font-weight: bold;
				text-transform: uppercase;
			}
			&.no-title {
				padding-left: 20px !important;
				text-align: center !important;
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}
	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	#powered .inner {
		flex-wrap: wrap;
		.copyright {
			order: 2;
			margin-bottom: 15px;
		}
		.payment {
			order: 1;
			.payment-img {
				max-width: 100%;
			}
		}
	}
	#input-enquiry {
		height: 100px;
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
			}
		}
	}
	.breadcrumb > li {
		display: inline;
		font-size: 13px;
	}

	.product-info {
		#product {
			.custom-product-meta {
				.product-prices {
					ul {
						li {
							.list-prices {
								span {
									&:first-child {
										padding-bottom: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.page-product {
		#tab-description {
			.faq {
				.mod-accessories {
					&__row {
						flex-wrap: nowrap;
					}
				}
			}
			.mod-text-photo {
				&--text-boxed-on-left, &--text-boxed-on-right {
					margin: 0;
					flex-direction: column-reverse;
					.mod-text-photo__column--content {
						position: relative;
						width: 100%;
					}
				}
			}
		}
	}
}

@media (max-width: $screen-xs-max) {
	.page-home {
		.bannercontainer {
			&.banner-fullscreen {
				.tp-leftarrow {
					left: 10px !important;
				}
				.tp-rightarrow {
					right: 10px !important;
				}
			}
		}
	}
}

@media (max-width: 667px) {
	#tab-description {
		section {
			.mod {
				&-contents {
					&__column {
						.mod-content-item {
							&__container {
								.mod-content__content {
									padding: 0 2rem;
								}
							}
						}
					}
					&--triple {
						.mod-contents__column {
							width: 100%;
							.mod-content-item {
								&__media {
									height: auto;
								}
								&__container {
									.mod-content__content {
										max-width: 100%;
									}
								}
							}
						}
					}
				}
				&-heading {
					&__content {
						h3 {
							padding: 3rem 2rem 0 2rem;
						}
						.mod-content__content {
							p {
								padding: 0 2rem;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 637px) {
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 45px 15px 15px 15px;
		& > div:first-child {
			margin-top: 20px;
		}
		br {
			display: none;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(33.3% - 20px);
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 2 - 7px);
	}
}

@media (max-width: 570px) {
	.fancy_holiday_works {
		width: 280px !important;
		.fancybox-skin {
			padding: 30px 10px !important;
			background-color: #fff;
			.fancybox-inner {
				width: 260px !important;
			}
			.holiday_table_wrap {
				font-size: 14px;
			}
			.holiday_table {
				td {
					font-size: 14px;
				}
			}
			.fancybox-outer {
				.fancybox-inner {
					.holiday_table_wrap {
						.holiday_table {
							.hw_row {
								.hw_col {
									font-size: 14px;
									width: auto;
								}
							}
						}
					}
				}
			}
		}
	}
	.holiday_works {
		margin-top: 0;
	}
	#powered {
		& > .container {
			padding: 0;
		}
		.payment {
			margin-top: 8px;

			img {
				&:first-child {
					display: none;
				}
			}
		}
	}
	#footer {
		#powered {
			.container {
				.inner {
					.payment {
						justify-content: flex-start;
					}
				}
			}
		}
	}
	.payment2row {
		display: block;
	}
	.breadcrumbs {
		padding: 15px;
		.container {
			padding: 0;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	.product-info .tab-content {
		.desc-header-wrapper .desc-header {
			display: block;
		}
		.scroll-button {
			margin-bottom: 15px;
			margin-left: 15px;
		}
	}
	.page-product {
		#tab-description {
			section {
				.mod-contents {
					.mod-content-item {
						&__content {
							padding: 0;
							.mod-content__title {
								margin-right: 0;
								margin-left: 0;
								max-width: 100%;
								text-align: center !important;
							}
							.mod-content__content {
								max-width: 100%;
							}
						}
					}
				}
			}
			.mod-contents__heading {
				padding: 0 2rem;
			}
			.faq {
				.mod-accessories {
					&__row {
						flex-wrap: wrap;
						max-height: unset;
						margin-bottom: 0;
					}
					&__column {
						&--title {}
						&--image {
							display: none;
						}
					}
					&__svg {
						display: none;
					}
				}
			}
		}
	}
}

@media (max-width: $screen-xs) {
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% - 5px);
	}
	.product-compare-header {
		padding-left: 0;
		width: 40px;
		.badge {
			right: 2px;
		}
	}
	#cart {
		.wrap-cart .badge {
			right: 7px;
		}
		.icon-cart {
			padding: 0 10px;
		}
	}
}

@media (max-width: 360px) {
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}

	.information-information-23, .information-information-25 {
		#modular-landing {
			#module1 {
				.mod-heading {
					padding-bottom: 0;
					h1 {
						margin: 0;
						line-height: 3.5rem;
					}
				}
			}
			#module2 {
				.mod-home-banner__content {
					padding-right: 3rem;
					padding-left: 3rem;
				}
			}
			.mod-heading.mod-heading--misty {
				.mod-content__title {
					font-size: 3.125rem;
					line-height: 1.15;
				}
			}
		}
	}
}


/*------------- product page -----------------*/

@media (max-width: 1200px) {
	.mod-selector__thumbnails.hide-for-small-only {
		.mod-selector__thumb {
			width: calc(100% / 3);
		}
	}
	#tab-description {
		.grid {
			.grid-item {
				&.full-width {
					.full-row {
						flex-wrap: wrap;
						img {
							margin-bottom: 15px;
						}
						img, .copy.outer {
							width: 100%;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.advantages_block {
		.advantage_item {
			flex: 0 0 100%;
			&:nth-child(2n) {
				padding-left: 0;
			}
		}
	}
}
@media (max-width: 767px) {
	#product {
		.custom-product-meta-item {
			flex-wrap: wrap;
		}
	}
	.mod-selector__thumbnails.hide-for-small-only {
		.mod-selector__thumb {
			width: calc(100% / 2);
			.mod-selector__thumb-image-cnt {
				img {
					margin-bottom: 0!important;
				}
			}
		}
	}
}
