
.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.custom-layout {
	.cheap_invoice {
		a, span {
			margin: 0;
			padding: 0 0 4px;
			display: inline-block;
			font-family: $f;
			font-weight: 400;
			font-size: 16px;
			line-height: 16px;
			color: $tc;
			text-decoration: none;
			cursor: pointer;
			border-bottom: 1px solid transparent;
			-webkit-transition: all .3s linear;
			-moz-transition: all .3s linear;
			-o-transition: all .3s linear;
			transition: all .3s linear;
		}
	}
}

.product-block {
	position: relative;
	background-color: $white;
	.description {
		display: none;
	}
	.product-meta {
		line-height: 25px;
		padding: 0 0 25px;
	}
	.price {
		text-align: center;
	}
	.name {
		.rating {
			font-size: 14px;
			margin-top: 7px;
			text-align: center;
		}
	}
	.image {
		position: relative;
		overflow: hidden;
		@include transition(all 0.4s ease 0s);
		padding: 40px 10px 10px 10px;
	}
	.top {
		position: relative;
	}
}

.rating {
	svg {
		width: 16px;
		height: 16px;
		&.star {
			&1, .hs1 {
				fill: $tc;
			}
			&0, .hs2 {
				fill: #ccc;
			}
		}
	}
}

.cart {
	position: relative;
	@include transition(all 0.4s ease 0s);
	padding-bottom: 15px;
}

.product-label {
	text-transform: uppercase;
	z-index: 99;
	position: absolute;
	text-align: center;
	line-height: 17px;
	top: 7px;

	&.markdown-product {
		font-size: 11px;
		line-height: 11px;
		background: #e6365d !important;
		color: $wh;
		text-transform: none;
		padding: 4px;
		right: 7px;
	}
}

.cheap_invoice {
	margin-bottom: 15px;
}

.reservation-block {
	clear: both;
	.storage_widget {
		font-size: 9pt;
		display: none;
	}
	.custom-storage-offer {
		font-size: 8pt;
		margin-bottom: 30px;
	}
	.storage_reserve {
		display: flex;
		justify-content: space-between;
		margin: 15px 0;
		align-items: center;
	}
	#storage_address {
		font-weight: 700;
		color: $tc;
		margin-top: -10px;
	}
	.custom-storage-make-reservation {
		margin: 0;
		height: 50px;
		width: 182px;
		display: -webkit-inline-flex;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background: url('/catalog/view/theme/kitchenrussia/img/5511.png') no-repeat top center;
		background-size: contain;
		border: none;
		span {
			font-size: 10.5pt;
			text-transform: uppercase;
			font-weight: 700;
			color: $tc;
			margin-top: -10px;
		}
	}
	.custom-storage-delivery-pickup-item {
		font-size: 11pt;
		padding-left: 50px;
		padding-top: 5px;
		background: no-repeat left center transparent;
		height: 43px;
		&.pickup {
			background-image: url('/catalog/view/theme/kitchenrussia/img/delivery-icon.png');
		}
		&.delivery {
			background-image: url('/catalog/view/theme/kitchenrussia/img/pickup-icon.png');
		}
	}
	#storage_time {
		color: $tc;
		font-weight: 700;
	}
}

.advantages_block {
	display: flex;
	flex-wrap: wrap;
	.advantage_item {
		display: flex;
		align-items: center;
		flex: 0 0 50%;
		margin-bottom: 15px;
		&:nth-child(2n) {
			padding-left: 10px;
		}
	}
	svg {
		margin-right: 10px;
		width: 50px;
		height: 50px;
		.a_red {
			fill: #d22249;
		}
		.a_wh {
			fill: $wh;
		}
		.a_tr {
			fill: none;
		}
	}
}

.product-view {
	.product-description {
		div > span {
			&:first-child {
				font-weight: 700;
			}
		}
	}
}

.product-colors {
	display: flex;
	align-items: flex-start;
	margin-top: 5px;
	padding-top: 7px;
	min-height: 60px;
	.product-colors-item {
		font-weight: 700;
		flex: 0;
	}
}

.desc-header {
	font-weight: 700;
}

.color_products {
	.products-block.col-nopadding.custom-catalog {
		margin-top: -15px;
	}
	.product-filter {
		display: none;
	}
}

.desc-header {
	text-align: center;
	text-transform: uppercase;
	padding-top: 20px;
	margin-bottom: 30px;
	font-size: 12pt;
	display: block;
}

#tab-description {
	margin: 0 auto;
	max-width: $container-lg;
	padding-top: 40px;
	section {
		.mod {
			&-content-item {
				.mod-content-item__media {
					picture {
						img {
							-o-object-fit: cover;
							object-fit: cover;
							font-family: "object-fit: cover";
						}
					}
				}
			}
			&-heading {
				&__content {
					text-align: center;
					h3 {
						padding-top: 30px;
						font-size: 16pt;
					}
				}
			}
			&-contents {
				padding-bottom: 20px;
				&__heading {
					margin-bottom: 50px;
					.mod-contents {
						p {
							margin: 0;
						}
						&__title {
							font-size: 24pt;
							line-height: 1;
							text-align: center;
						}
						&__sub-title {
							font-size: 12pt;
						}
					}
				}
				.mod-content {
					&-item {
						&__media {
							picture {
								text-align: center;
								display: flex;
								background: transparent;
							}
						}
						.mod-content__title, .mod-content__content {
							text-align: left;
							max-width: 400px;
						}
						.mod-content__title {
							font-size: 18px;
							font-weight: 600;
							margin: 30px auto 20px auto;
							padding: 0;
							br {
								display: none;
							}
						}
						.mod-content__content {
							font-weight: 400;
							font-size: 14px;
							margin: 0 auto;
							line-height: 1.6;
						}
						&__content {
							padding: 0 15px;
						}
					}
				}
				&--double {
					.mod-content {
						&s__column {
							flex: 0 0 50%;
						}
					}
				}
				&--triple {
					.mod-contents__container {
						padding: 0;
					}
					&s__column {
						flex: 0 0 calc(100% / 3);
					}
					&.maxH300-OvfHidden {
						margin-top: -70px;
						.mod-content-item__media {
							height: 312px;
							overflow: hidden;
							.mod-content-item__image-wrapper {
								height: 100%;
							}
						}
					}
				}
				&__container {
					display: flex;
					&.ka-theme {
						.slick-list {
							flex: 1 0 100%;
							width: 100%;
						}
						.slick-dots {
							position: absolute;
							display: none;
						}
						.mod-content-item {
							.mod-content__title {
								margin-top: 15px;
							}
						}
					}
				}
			}
			&-text-photo {
				position: relative;
				display: flex;
				margin-bottom: 70px;
				.mod-text-photo {
					&__notes {
						padding: 15px;
						font-size: 9pt;
						font-style: italic;
						color: $dg;
						font-weight: 500;
					}
					&__media {
						flex: 1;
					}
				}
				&__column {
					&--media {
						flex: 0 0 63%;
						overflow: hidden;
						display: flex;
						align-items: center;
						img {
							-o-object-fit: cover;
							object-fit: cover;
							font-family: "object-fit: cover";
						}
					}
					&--content {
						position: absolute;
						width: 42%;
						top: 10%;
						bottom: 10%;
						.mod-content {
							background: $wh;
							box-shadow: 0 3px 20px rgba(0, 0, 0, 0.12);
							padding: 40px;
						}
						&__title {
							margin-top: 0;
							margin-bottom: 30px;
							font-size: 26px;
						}
						p {
							line-height: 1.6;
							margin: 0;
						}
					}
					&--notes {
						display: none;
					}
					&--triple {
						flex: 0 0 100%;
						max-height: unset;
						.mod-content__content {
							margin-bottom: 30px;
						}
					}
				}
				&--text-boxed {
					&-on-left {
						margin: 0 50px 30px;
						justify-content: flex-end;
						.mod-text-photo__column--content {
							left: 5%;
							right: auto;
						}
						.mod-text-photo__column--media {
							img {
								max-height: 50rem;
							}
						}
						.mod-text-photo__notes {
							margin-right: 95px;
							padding-left: 0;
						}
					}
					&-on-right {
						margin: 0 50px 30px;
						justify-content: flex-start;
						.mod-text-photo__column--content {
							left: auto;
							right: 5%;
						}
						.mod-text-photo__column--media {
							img {
								max-height: 60rem;
							}
						}
						.mod-text-photo__notes {
							margin-left: 90px;
							padding-right: 0;
							text-align: right;
						}
					}
				}
				&.mod-text-photo--text-on-left, &.mod-text-photo--text-on-right {
					.mod-text-photo__column {
						flex: 50%;
						top: 0;
						bottom: 0;
						position: relative;
						display: flex;
						align-items: center;
					}
					.mod-text-photo__content {
						box-shadow: none;
					}
					.mod-content__title {
						font-size: 24pt;
						margin-bottom: 30px;
					}
				}
				&.mod-text-photo--text-on-left {
					.mod-text-photo__column--content {
						order: 1;
					}
					.mod-text-photo__column--media {
						order: 2;
					}
				}
				&.mod-text-photo--text-on-right {
					.mod-text-photo__column--content {
						order: 2;
					}
					.mod-text-photo__column--media {
						order: 1;
					}
				}
				&.specific-text {
					flex-direction: column;
					.mod-text-photo__column--content {
						position: relative;
						width: 100%;
						padding-bottom: 10px;
						.mod-text-photo__content {
							box-shadow: none;
							padding: 0;
							text-align: center;
							.mod-content__title {
								padding: 0 2rem;
							}
						}
					}
				}
			}
			&-video {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 50px;
				& > div {
					flex: 0 1 100%;
				}
				h4 {
					font-size: 26px;
				}
				&__media-wrapper {
					max-height: 500px;
					overflow: hidden;
					order: 2;
				}
				&__content-wrapper {
					order: 1;
				}
				.com-modal {
					flex: 0;
				}
			}
		}
	}
	.com-stripe-title__descr {
		display: none;
	}
	.faq {
		.mod-accessories__row {
			margin-bottom: 20px;
			&--header {
				display: flex;
				max-height: 750px;
			}
			&--carousel {
				.mod-accessories {
					&__root {
						display: flex;
					}
					&__item {
						margin-right: 15px;
						flex: 1 1 100%;
						&:last-child {
							margin-right: 0;
						}
					}
					&__name {
						display: inline-block;
						font-size: 10pt;
						margin-top: 15px;
					}
					&__placeholder {
						position: relative;
					}
					&__expand {
						position: absolute;
						display: none;
						justify-content: center;
						align-items: center;
						background: $wh;
						box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
						width: 29px;
						height: 29px;
						bottom: 0;
						&-icon {
							&:before {
								content: '\f067';
								font-family: $fa;
								color: $tc;
							}
						}
					}
				}
			}
		}
		.mod-accessories__column {
			&--title {
				flex: 0 0 calc(100% / 3);
				margin-right: 15px;
				background: $ddg;
				display: flex;
				flex-wrap: wrap;
				padding: 40px;
				h4 {
					color: $wh;
					font-size: 38px;
					margin: 0;
				}
				.mod-accessories__svg {
					display: flex;
					align-self: flex-end;
					&, picture {
						width: 100%;
					}
					.mod-accessories__svg--file {
						max-height: 220px;
					}
				}
			}
			&--image {
				flex: 0 1 calc(200% / 3);
				&.specific-image {
					.mod-accessories__image-wrp {
						height: 100%;
						img {
							height: 100%;
							-o-object-fit: cover;
							object-fit: cover;
							font-family: "object-fit: cover"
						}
					}

				}
			}
		}
	}
}

.mod-modal {
	&__inner-wrap .slick-track {
		width: auto !important;
	}
	&__inner-wrap, &__navigation {
		display: none;
	}
	&__item {
		&.slick-slide {
			width: auto !important;
		}

	}
}

#tab-downloads {
	margin-top: 30px;
	ul {
		margin: 0;
		padding: 0;
		width: 100%;
		list-style: none;
		li {
			width: 100%;
			margin: 0 0 15px 0;
			list-style: none;
			display: inline-flex;
			a {
				text-decoration: none;
			}
		}
	}
	.pdf-download {
		margin: 0 15px 0 0;
		display: inline-block;
	}
	.title {
		display: inline-flex;
		flex-direction: column;
		font-weight: 700;
		font-size: 12pt;
		line-height: 24px;
		color: #e6365d;
		text-transform: uppercase;
		.size {
			text-transform: none;
			color: $bk;
		}
	}
}

#product {
	.custom-product-meta {
		margin: 0 0 15px;
		display: flex;
		justify-content: space-between;
	}
}

.related-products-wrapp .custom-related-products .desc-pane .products-block .product-col-wrap .top > div {
	margin: 0;
}

.no-scroll.product-grid-nh {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	.products-row {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-right: -30px;
	}
	.product-block {
		width: calc(100% / 4 - 33px);
		margin: 30px 30px 0 0;
		.product-meta {
			.top {
				h3.name {
					background-color: #f7f7f7;
				}
			}
			.bottom {
				justify-content: center;
				margin: 10px 0;
			}
		}
		.compare {
			.btn {
				padding: 8px;
				height: 100%;
				background-color: #f3f3f3;
				border-color: #e2e2e2;
				margin: 0 10px;
				img {
					height: auto;
					width: 100%;
					display: block;
				}
			}
		}
	}
}

.viewed-box-content .product-grid .product-block {
	margin: 0;
	width: 99%;
}
.cart-middle-bg {
	width: 100%;
	height: auto;
	img {
		width: 100%;
		height: auto;
	}
}

.page-product {
	.gifts_block {
		display: flex;
		flex-wrap: wrap;
		.prod_gift {
			width: 100%;
			margin-bottom: 20px;
			.prod_gift_link {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				&:hover {
					text-decoration: none;
					.prod_gift_name {
						color: #c41239;
					}
				}
				.prod_gift_img {
					width:40%;
					max-width: 180px;
					height: auto;
					border-left: 2px solid #c41239;
					padding: 10px; 
				}
				.prod_gift_name {
					width: 60%;
					padding: 0 20px;
				}
			}
		}
	}
	#tab-gifts {
		padding-bottom: 15px;
	}
	.custom-accessories-inner {
		.btn-group {
			.btn-book-recipes {
				width: auto;
				padding-right: 20px;
			}
		}
	}
	#tab-related7 {
		.product-block {
			.bottom {
				height: 40px;
				.btn-under-order {
					margin-top: -7px;
					span {
						vertical-align: top;
					}
				}
			}
		}
	}
}

.mod-selector__thumbnails.hide-for-small-only {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0;
	.mod-selector__thumb {
		width: calc(100% / 6);
	}
}