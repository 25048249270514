@media screen and (max-width: 570px) {
	.logo-store a span {
		font-size: 10px;
	}
}

@media screen and (max-width: 1371px) {
	.home-about_us:after {
		left: -50px;
	}

	.header-v1 .container-header .menu .menu-bottom .pav-mainnav {
		width: 100%;
	}

	.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse {
		width: 100%;
	}

	.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav {
		width: 100%;
		justify-content: space-between;
	}

	.header-v1 .container-header .menu .menu-bottom .button-box .product-compare-header {
		margin-right: 15px;
	}
}

@media screen and (max-width: 1051px) {
	.header-v1 .container-header .menu .menu-top .pav-mainnav .pav-megamenu .pav-megamenu .navbar .megamenu .navbar-header .collapse .nav li a {
		padding: 10px 20px;
	}
}

@media screen and (max-width: 1024px) {
	.header-v1.inner-header {
		background-position: -472px 74px;
	}

	#news_list .news_item {
		width: calc(100% / 3 - 10px);
	}

	.form-box-content {
		margin: 50px auto 0;
	}

	.home-about_us .about_us-content {
		flex-direction: column;
	}

	.search_block form.search-form_rc.is-active input {
		width: 300px;
		right: 40px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices .static-price span, .main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices .calced-price span {
		font-size: 20px;
		white-space: nowrap;
	}

	.header-v1 .container-hover-items .item-four_rc .circle_rc {
		margin-top: 65px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .bottom_text-box {
		margin: 0 15px;
	}

	.header-v1 .container-header .menu .menu-bottom .button-box .search {
		width: 64px;
	}

	.widget_news .widget-inner .owl-stage-outer .owl-stage .owl-item .widget_news_item .news-read-more {
		margin-left: 11px;
	}

	.widget_news .widget-inner .owl-stage-outer .owl-stage .owl-item .widget_news_item .news-read-more {
		margin-right: auto;
		margin-left: 12px;
	}

	.special-offer.bottom-bg .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products:before {
		display: none;
	}

	.choose-color {
		padding-bottom: 0;
	}

	.widget_news.box-recipes:before {
		display: none;
	}

	.special-offer.bottom-bg .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products {
		background-size: cover;
	}

	.top-bg {
		background-position: -275px bottom;
		background-size: 1920px;
	}

	.header-v1 .container-banner .info-box {
		padding-right: 25px;
	}

	.featured_categories:before {
		display: none;
	}

	.header-v1 .container-banner .info-box .container-contact .top-box a {
		font-size: 22px;
	}
	body {
		background-color: #fafafa;
	}

	#footer {
		background-size: cover;
		background-position: right;
	}

	.header-v1 .container-header .logo {
		min-width: 230px;
		max-width: 230px;
	}

	.sidebar-offcanvas {
		display: block !important;
	}

	.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav li a {
		padding: 10px 10px;
		font-size: 14px;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body .panel .panel-body .accordion .accordion-group a {
		max-width: 100%;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-footer {
		display: none;
	}

	.header-v1 .container-header .logo:before {
		display: none;
	}

	.container-header .menu .menu-top .pav-mainnav .pav-megamenu .canvas-menu .fa {
		color: white;
	}

	.header-v1 .container-banner .info-box .cart-box {
		margin-left: 20px;
	}

	.widget_news.box-recipes .widget-inner .owl-nav .owl-prev, .widget_news.box-recipes .widget-inner .owl-nav .owl-next {
		top: calc(50% - 20px);
	}

	.widget_news .widget-inner .owl-nav .owl-prev, .widget_news .widget-inner .owl-nav .owl-next {
		top: 100px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-name a {
		font-size: 12px;
	}

	.home-about_us:after {
		display: none;
	}

	.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .border .product-block .product-meta .top .product-prices .price-new {
		font-size: 16px;
	}

	.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products {
		margin-top: 20px;
	}

	.widget_news.box-news {
		background-size: auto;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .bottom_text-box {
		margin-bottom: 40px;
	}

	.col-inner .producttabs:after {
		display: none;
	}

	.producttabs .owl-carousel.product-grid.col-nopadding.tcarousel.owl-loaded.owl-drag:before {
		display: none;
	}

	.home-about_us .about_us-content .content .text-box p span {
		display: block;
		max-width: 290px;
	}

	.header-v1 .container-hover-items .item-two_rc {
		top: -15px;
		left: 760px;
	}

	.home-about_us {
		background: url(/catalog/view/theme/kitchenrussia/img/bg_bm_fon.png) no-repeat;
		position: relative;
		background-size: 100%;
	}

	.home-about_us:before {
		position: absolute;
		content: '';
		right: 0px;
		bottom: -67px;
		width: 350px;
		height: 220px;
		background: url(/catalog/view/theme/kitchenrussia/img/be-el.png) no-repeat;
		background-size: cover;
		z-index: -1;
	}

	.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content:after {
		display: none;
	}

	.widget_news .content-container .subscribe.form-inline {
		padding-bottom: 105px;
	}

	.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-stage-outer .owl-stage .owl-item .item {
		margin: 0 auto;
	}

	.col-lg-12.col-md-4.col-sm-6.col-xs-12 {
		padding: 0;
	}

	.widget_news .widget-heading a {
		margin-top: 25px;
		margin-left: auto;
		margin-right: 15px;
	}

	.special-offer.bottom-bg .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products {
		background-position: -250px;
	}

	.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .border .product-block .block-img .image .img .img-responsive {
		margin: 0 auto;
	}

	.featured_categories:after {
		left: 70px;
	}

	.top-bg {
		padding-top: 0;
	}

	.header-v1 .container-banner .info-box {
		padding-right: 12px;
	}

	.header-v1 .container-header .menu .menu-top .pav-mainnav .pav-megamenu {
		padding: 0;
	}

	.header-v1 .container-header .menu .menu-bottom:before {
		right: -94%;
	}

	.header-v1 .container-banner .info-box .container-contact .bottom-box {
		display: none;
	}

	.header-v1 .container-hover-items .item-four_rc {
		left: 80px;
	}

	.widget_news.box-recipes .widget-inner {
		margin-top: 30px;
	}

	.widget_news .widget-heading {
		width: 100%;
		justify-content: center;
		flex-direction: column;
	}

	.home-about_us .about_us-content .content .labels {
		flex-direction: column;
	}

	.home-about_us .about_us-content .content .labels .labels-item {
		margin: 20px 0;
	}

	.widget_news.box-news .content-container {
		display: flex;
		margin-top: 25px;
	}

	.widget_news .content-container .go-to-catalog {
		margin-top: 60px;
		margin-right: 49px;
	}

	.widget_news .content-container .subscribe.form-inline {
		margin-left: auto;
	}

	.widget_news.box-news .widget-inner {
		width: 100%;
	}

	.sidebar-offcanvas {
		background: #c41239;
		z-index: 999;
	}

	.box-close {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 5px;
		z-index: 999;
		top: 5px;
		background-color: #ffffff;
	}

	.box-close span.close-menu {
		position: absolute;
		top: 0;
		left: 0;
		width: 30px;
		height: 30px;

	}

	.box-close span.close-menu:before, .box-close span:after {
		position: absolute;
		left: 14px;
		content: ' ';
		top: 0;
		height: 28px;
		width: 2px;
		background-color: #c41239;
	}

	.box-close span.close-menu:before {
		transform: rotate(45deg);
	}

	.box-close span.close-menu:after {
		transform: rotate(-45deg);
	}

	.row-offcanvas-left.active {
		left: 100%;
	}

	.row-offcanvas-left .sidebar-offcanvas {
		left: -100%;
		width: 100%;
		top: 0;
		position: absolute;
	}

	.sidebar-offcanvas .ocwall .menutoggle {
		top: 10px;
		width: 29px;
		height: 29px;
		position: absolute;
		left: auto;
	}

	.sidebar-offcanvas .offcanvas-inner {
		height: 100%;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body #offcanvasmenu {
		background: #191919;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body #offcanvasmenu .nav li {
		background: #191919;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body #offcanvasmenu .nav li a {
		text-transform: none;
		font-family: "Montserrat", sans-serif;
		font-size: 16px;
		font-weight: 300;
		box-shadow: none;
		padding-left: 45px;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body #offcanvasmenu .nav li a .menu-title {
		color: #ffffff;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body .panel {
		padding: 0;
		box-shadow: none;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body .panel .panel-heading {
		border: none;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body .panel .panel-body {
		padding: 0;
		border: none;
		background: #c41239;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body .panel .panel-body .accordion .accordion-group {
		background: #c41239;
		padding: 0;
		border: none;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body .panel .panel-body .accordion .accordion-group a {
		color: #ffffff;
		margin: 20px 0;
		background: #c41239;
		text-transform: uppercase;
		font-family: "Montserrat", sans-serif;
		font-size: 16px;
		font-weight: 300;
		padding: 0 0 0 45px;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body .panel .panel-body .accordion .accordion-group .accordion-heading .bg .fa {
		color: #ffffff;
		font-size: 15px;
		padding-right: 10px;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body .panel .panel-body .accordion .accordion-group .accordion-body {
		background: white;
		width: 100%;
		padding: 0;
	}

	.sidebar-offcanvas .accordion .accordion-group .accordion-heading span.bg .fa-angle-down:before {
		color: #fff !important;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body .panel .panel-body .accordion .accordion-group .accordion-body li {
		padding: 20px 0;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body .panel .panel-body .accordion .accordion-group .accordion-body li a {
		color: #191919;
		background: #ffffff;
		text-transform: none;
		width: 100%;
		display: flex;
		margin: 0;
		padding: 0;
		padding-left: 35px;
		align-items: center;
	}

	.sidebar-offcanvas .offcanvas-inner .offcanvas-body .panel .panel-body .accordion .accordion-group .accordion-body li a img {
		max-width: 65px;
		max-height: 65px;
		width: 100%;
		height: auto;
		margin-right: 20px;
	}

	.page-product-special .products-block.col-nopadding.custom-catalog .product-block.item-default.custom-item {
		width: calc(100% / 3 - 30px);
	}
	.custom-bottom:before {
		height: 130px;
		width: 100px;
		left: -35px;
	}
	.home-about_us .home-about_us_container h2 {
		text-align: center;
	}
	.custom-catalog .product-block .top .rating {
		height: 15px;
	}
	.custom-catalog .product-block .product-meta .top .product-prices .price-old {
		font-size: 14px;
	}
	.custom-catalog .product-block:nth-of-type(3n + 2) {
		margin: 15px 0;
	}
	.product-filter + .product-list .custom-catalog .product-block:nth-child(2n),
	.custom-bottom .panel-body {
		position: relative;
		left: 0;
	}
	#tab-description img {
		width: 100%;
	}

	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container .mod-content.mod-content-item__content h3 {
		font-size: 18px !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		margin: 0 0 475px !important;
	}
	.custom-related-products .desc-pane .products-block .product-col-wrap .top .name a {
		padding: 10px 20px;
	}
	.product-grid .product-block .bottom, .products-owl-carousel .product-block .bottom {
		padding: 0 25px !important;
	}
	.product-grid .product-block .cart, .products-owl-carousel .product-block .cart {
		margin-right: 8px !important;
	}
	.custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .bottom .compare .btn {
		margin-top: 0 !important;
		height: 40px;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	.page-navigation__item {
		justify-content: center !important;
	}
	.product-grid .product-block .bottom, .products-owl-carousel .product-block .bottom {
		padding: 0 8px !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container {
		width: 100% !important;
	}
	#product .product-prices ul li .list-prices .price-old {
		margin: 0 10px 15px 0 !important;
	}
	.product-info .in-stock {
		right: 15px !important;
		top: 0 !important;
		z-index: 1 !important;
	}
	.custom-related-products .desc-pane .products-block .product-col-wrap {
		margin: 0 !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		width: auto !important;
		margin: 0 !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		margin: 0 0 75px !important;
	}

	.product-info .product-info-bg [class*="col-"]:last-child {
		padding: 0 15px !important;
		width: 50% !important;
	}
	#product {
		width: 100% !important;
	}
	#product .custom-product-meta-item:first-child .product-prices, #product .custom-product-meta-item:first-child .quantity {
		margin: 0 ;
		width: auto ;
		display: -webkit-inline-flex ;
		display: inline-flex ;
	}
	#product .custom-product-meta-item:first-child .product-prices, #product .product-prices ul li .list-prices .price-old {
		margin: 0 10px 0 0 !important;
	}
	#product .product-prices ul li .list-prices,
	#product .custom-product-meta-item {
		width: 100% !important;
	}

	#product .product-prices ul li .list-prices .price-old {
		margin: 0 10px 0 0 !important;
	}
	#product .product-prices ul li .list-prices .price-new {
		margin: 0 !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child, .product-info .product-info-bg [class*="col-"]:first-child {
		width: 50% !important;
		float: left !important;
	}
	#product {
		width: 100% !important;
		flex-direction: column !important;
		align-items: flex-start !important;
	}
	#product .custom-product-meta-item .custom-product-meta-btn {
		width: auto !important;
		display: inline-flex !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li a, #product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li .button-credit {
		margin: 0 !important;
		padding: 0 0 4px !important;
		width: auto !important;
		display: inline-block !important;
		font-weight: 400 !important;
		font-size: 16px !important;
		line-height: 16px !important;
		color: #c41239 !important;
		text-decoration: none !important;
		cursor: pointer !important;
		border-bottom: 1px solid transparent !important;
		transition: all .3s linear !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item {
		margin: 0 !important;
		padding: 0 !important;
		height: 40px !important;
		width: 125px !important;
		border: 1px solid #c9c9c9 !important;
		box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.1) !important;
		top: -25px !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number .form-control, #product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number {
		height: auto !important;
		line-height: 19px !important;
		color: #000 !important;
		position: relative !important;
		bottom: -2px !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item span .fa, #product .custom-product-meta-item:last-child .quantity .quantity-item .add-down .fa, #product .custom-product-meta-item:last-child .quantity .quantity-item .add-up .fa, #product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number .form-control {
		font-weight: 700 !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item span .fa, #product .custom-product-meta-item:last-child .quantity .quantity-item .add-down .fa, #product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number, #product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number .form-control {
		font-size: 19px !important;
		line-height: 40px !important;
		color: #c41239 !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number .form-control, #product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number {
		height: auto !important;
		line-height: 19px !important;
		color: #000 !important;
		position: relative !important;
		bottom: -4px !important;
	}
	.product-info {
		width: 100% !important;
	}
	#product .custom-product-meta-item .quantity.clearfix.quantity {
		margin: 24px 0 0 !important;
	}
	#product .custom-product-meta-item .custom-product-meta-btn .cheap_invoice ul li {
		margin: 0 0 15px !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		margin: 0 0 15px !important;
	}
	.tab-description .desc-pane [id] .mod-slideshow ul .mod-slideshow__item.slick-slide {
		height: auto !important;
	}
	.custom-accessories-columns.custom-accessories-center .custom-accessories__center .custom-accessories__items .images {
		width: 340px !important;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		right: -7px !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-stage-outer {
		width: 100% !important;
	}
	.owl-carousel .owl-stage {
		left: 0 !important;
	}
	.product-info .product-info-bg .image-container .thumbnail,
	.product-info .product-info-bg .image-container .thumbnail a img {
		height: auto !important;
	}
	.page-navigation__item li {
		margin: 0 30px 0 0;
	}
	.custom-instructions:after {
		content: '';
		height: 122px;
		width: 117px;
		background: url(/catalog/view/theme/kitchenrussia/img/4411.png) no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: -8%;
		left: -2%;
	}

	.custom-instructions:before,
	.tab-content .tab-description .desc-pane + div .custom-review-status:before,
	.tab-content .tab-description .related-products-wrapp .custom-related-products:before {
		display: none;
	}

	.custom-accessories-columns.custom-accessories-center .custom-accessories__center .custom-accessories__items .images {
		height: 100%;
		width: 100% !important;
		position: relative;
		left: initial;
		top: initial;
		z-index: 2;
		box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
		transform: scale(1.2);
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center,
	.custom-accessories-columns.custom-accessories-center .custom-accessories-heading {
		margin: 0 0 90px !important;
	}
	.custom-accessories-columns.custom-accessories-center .btn-group .btn {
		width: 200px !important;
		max-width: 200px !important;
	}
	.custom-accessories-columns.custom-accessories-center .btn-group .btn span:before {
		left: -35px !important;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 22px !important;
		right: 0;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;

	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
	.custom-featured .products-block .product-block .product-meta .bottom {
		min-width: 190px;
	}
	.custom-featured .products-block .product-block .product-meta .bottom .compare {
		margin: -10px 0 0 7px;
	}
}

@media screen and (min-width: 1024px) {
	.widget_news .content-container .go-to-catalog {
		margin-right: 50px;
	}
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1075px) {
	.home-about_us .about_us-content .content .labels .labels-item img {
		width: 90px;
		height: 94px;
		display: flex;
		align-items: center;
	}
}

@media screen and (max-width: 991px) {

	.breadcrumbs .container {
		margin: 0px;
		width: 100%
	}

	.widget_news .content-container, .widget_news .widget-heading {
		max-width: 560px;
		margin: 0 auto;
	}

	.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products:before {
		display: none;
	}

	.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse {
		display: none !important;
	}

	.box-close {
		display: block;
	}

	.bottom_text-box .user-payment-container .payment-info {
		margin-left: 9px;
	}

	.main-columns .mob-el {
		display: block !important;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .top_text_products {
		display: none;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .top_text_products span:first-child {
		margin-left: 58px;
	}

	.header-v1 .container-header .menu .menu-bottom .button-box .search {
		width: auto;
	}

	.working-hours {
		display: flex;
		align-items: center;
	}

	.working-hours span {
		font-size: 12px;
		color: white;
		font-weight: 500;
		margin-right: 15px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .row .col-sm-6 {
		position: relative;
		bottom: auto;
		margin-left: 15px;
		max-width: calc(100% - 15px);
	}

	.header-v1 .container-header .menu .menu-top {
		justify-content: flex-end;
	}

	.header-v1 .container-header .menu .menu-top .pav-mainnav .pav-megamenu {
		display: flex;
		padding-right: 20px;
		justify-content: flex-end;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .remove-product {
		margin-left: auto;
		position: absolute;
		top: 0;
		right: 0;
		background: #e6365d;
		padding: 10px;
		max-height: 42px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .remove-product img {
		filter: brightness(0) invert(1);
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices .static-price {
		margin-left: 15px;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices .calced-price .mob-el,
	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices .static-price .mob-el {
		color: #4c4c4c;
		font-size: 13px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .panel-group .form-inline h4 {
		margin-top: 0;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices .calced-price {
		margin-left: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item {
		padding: 32px 20px 3px 20px;
	}
	.advantages_block {
		flex-direction: column;
		gap: 16px;

		.advantage_item {
			margin: 0;
			flex: unset;
			padding-left: 0 !important;
		}
	}
}

/*mobile*/
@media screen and (min-width: 768px) {
	.checkout-buy > .container {
		width: 768px;
	}
}

@media screen and (min-device-width: 767px) and (max-device-width: 1024px) {
	.page-checkout-buy .main-columns.container {
		max-width: calc(100% - 30px);
		margin: 0 auto;
		width: 100%;
	}
}

@media screen and (max-width: 768px) {

	.page-product-special .products-block.col-nopadding.custom-catalog .product-block.item-default.custom-item {
		width: calc(100% / 2 - 30px);
	}

	.header-v1 {
		border-bottom: none;
	}
}

@media screen and (max-width: 767px) {

	.featured_categories .featured-categories-carousel .owl-stage-outer .owl-stage .owl-item .featured_cat .thumb {
		margin-top: 10px;
	}

	.form-box-content form span {
		text-align: center;
	}

	.page-product-search #sidebar-main #content .row .col-sm-3 {
		width: 100%;
	}

	body.is-active {
		max-height: 100vh;
		overflow: hidden;
	}

	body.is-active .row-offcanvas-left.active {
		max-height: 100vh;
	}

	body.is-active .row-offcanvas-left .sidebar-offcanvas {
		max-height: 100vh;
		overflow-y: auto;
	}

	body.is-active:before {
		content: '';
		position: absolute;
		bottom: 0;
		height: 900px;
		width: 100%;
		background: #c41239;
		left: 0;

	}

	.header-v1.inner-header {
		height: 136px;
	}

	.radio_custom .input-control {
		padding-left: 36px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .shipping-container div .radio_custom .input-control .input-control__indicator:after,
	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .user-payment-container .payment-info .radio_custom .input-control .input-control__indicator:after {
		width: 14px;
		height: 14px;
		top: 4px;
		left: 4px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .user-payment-container {
		flex-direction: column;
		align-items: center;
	}

	.box_code {
		margin-left: 15px;
		margin-right: 15px;
		width: calc(100% - 30px);
	}

	.box_code .code_img {
		max-width: 130px;
		max-height: 130px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .row .col-sm-6 .cart-total-items {
		padding: 20px 11px;

	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices .static-price span,
	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices .calced-price span {
		font-size: 22px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-name .th-title {
		display: none !important;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .row .col-sm-6 {
		max-width: calc(100% - 45px);
		margin-left: 30px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-quantity .input-group .form-control {
		width: 60px !important;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-name {
		order: 1;
		width: 100%;
		margin: 0 auto;
		max-width: 100%;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-quantity {
		order: 2;
		margin: 20px auto 0;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices {
		order: 3;
		width: 100%;
		justify-content: center;
	}

	.widget_news .widget-inner .owl-stage-outer .owl-stage .owl-item .widget_news_item .news-read-more {
		margin: 0 auto;
	}

	.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-nav .owl-next,
	.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-nav .owl-prev {
		top: 140px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item {
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: calc(100% - 20px);
		margin: 0 auto 30px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .top_radio-box .top_radio-box_container .top_radio-box-content .label-item:before {
		width: 24px;
		height: 24px;
		left: -36px;
		top: calc(50% - 12px);
	}

	.bottom_text-box .top_radio-box_el {
		margin: 0 0 10px 0;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .top_radio-box .top_radio-box_container .top_radio-box-content .label-item:after {
		left: -31px;
		width: 14px;
		height: 14px;
		top: 4px;
	}

	.box_code {
		margin-bottom: 90px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-image {
		margin: 20px auto 0;
		width: 50%;
		display: flex;
		order: 2;
		justify-content: center;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .agreement-container .title-text {
		margin-left: -12px;
		margin-bottom: 35px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .agreement-container div label {
		margin-left: 25px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .shipping-container div h3 {
		font-size: 16px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .user-payment-container .payment-info .radio_custom {
		margin-left: 15px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .user-payment-container .user-info input, .main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .user-payment-container .user-info textarea {
		margin-bottom: 20px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .user-payment-container {
		margin-top: 5px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item {
		width: 100%;
	}

	.container-inside .bottom-text {
		margin-top: 25px;
		margin-bottom: 15px;
	}

	.box_code {
		margin-top: 22px;
		margin-bottom: 66px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .panel-group .form-inline {
		margin-top: 8px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .panel-group .form-inline small {
		font-size: 12px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content h1 {
		margin-bottom: 23px;
		padding-bottom: 0;
		padding-top: 24px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-image {
		width: auto;
		margin-left: 5px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-prices {
		justify-content: space-between !important;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-image a img {
		max-width: 77px;
		max-height: 77px;
		width: auto;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .bottom_text-box:before {
		bottom: -44px;
		left: calc(50% - 60px);
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .bottom_text-box {
		padding-bottom: 120px;
		margin-bottom: 0;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .agreement-container div div label span {
		top: 5px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .agreement-container div div label span:before {
		top: -7px;
		left: calc(100% - 14px);
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .agreement-container .last-box_text label {
		margin-top: 13px;
		font-size: 11px;
		line-height: 1.5;
		text-align: center;
		margin-left: 0;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .agreement-container div .chekaut_button {
		margin-top: 0;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .agreement-container div div label {
		line-height: 1.5;
		margin-bottom: 20px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .agreement-container {
		margin-top: 25px;
	}

	.bottom_text-box .user-payment-container .payment-info {
		margin-left: 0;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .shipping-container div .radio_custom .input-control {
		margin-bottom: 15px;
		text-transform: none;
		font-size: 13px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .shipping-container div .radio_custom .input-control .input-control__indicator,
	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .user-payment-container .payment-info .radio_custom .input-control .input-control__indicator {
		width: 24px;
		height: 24px;
		top: 0;
	}

	.header-v1 .container-header.container-header_mobile {
		display: flex;
	}

	.header-v1 .container-header {
		display: none;
	}

	.top-bg {
		background-position: center;
	}

	.widget_news .widget-inner .owl-stage-outer .owl-stage .owl-item .widget_news_item .widget_news_item_title {
		justify-content: center;
	}

	.widget_news .widget-inner .owl-stage-outer .owl-stage .owl-item .widget_news_item .widget_news_item_title:before {
		left: calc(50% - 22px);
	}

	.featured_categories {
		margin-top: 0px;
	}

	.widget_news.box-news .widget-inner:before, .widget_news.box-news .widget-inner:after {
		display: none;
	}

	.header-v1 .container-banner .info-box .container-contact .top-box, .header-v1 .container-banner .info-box .cart-box {
		display: none;
	}

	#footer .footer-center .container .row .inner .row .box .box-heading {
		margin: 20px 0px 10px 0px
	}

	#footer .footer-center .container .row .inner .row .box .one-box {
		max-width: none;
	}

	#footer .footer-center .container .row .inner .row .box .center-box {
		max-width: none;
	}

	#footer .footer-center .container .row .inner .row .box .first-box li.ft-wt {
		font-size: 17px;
	}

	#footer .footer-center .container .row .inner .row .box .first-box .footer-name {
		max-width: 200px;
	}

	#footer .footer-center .container .row .inner .row {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: none;
		padding-bottom: 0;
	}

	#footer {
		background-size: cover;
		background-position: right;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .top_radio-box .top_radio-box_container .top_radio-box-content {
		flex-direction: column;
	}
	#news_list .news_item {
		width: calc(100% / 2 - 10px);
	}
	.product-info #product .custom-product-meta {
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0;
	}
	.product-info .row .product-info-bg [class*="col-"]:last-child .cart.pull-left.cart-btn {
		margin-top: 20px;
	}
	.product-info #product .custom-product-meta .product-prices ul li .list-prices {
		flex-direction: row;
	}
	.product-markdown-view .compare {
		display: none;
	}
	.product-info #product .custom-product-meta-item:first-child .quantity {
		justify-content: flex-start!important;
	}
}

/*медии что установленны владельцем проекта*/
@media screen and (max-width: 637px) {
	.success {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.success a {
		display: contents;
	}

	.success .success_button {
		margin: 10px auto;
	}
}

/*медии что установленны владельцем проекта*/
@media screen and (max-width: 600px) {
	.working-hours {
		display: none;
	}

	.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content:after {
		display: none;
	}
}

@media screen and (max-width: 550px) {
	.box_code .code_img {
		display: none;
	}

	.box_code {
		padding: 20px 20px 38px;
	}

	.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .border .product-block .product-meta .top .product-prices {
		flex-wrap: wrap;
		justify-content: space-around;
	}
}

@media screen and (max-width: 400px) {
	.back-call_rc {
		left: 0;
	}
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.special-offer .widget-products .container {
		width: 100%;
	}

	.widget_news .container {
		width: 100%;
	}
}

@media screen and (max-width: 480px) {
	#news_list .news_item {
		width: calc(100% / 1 - 10px);
	}

	.featured_categories .featured-categories-carousel .owl-stage-outer .owl-stage .owl-item .featured_cat .title {
		margin-top: 16px;
	}

	.header-v1 .container-header.container-header_mobile .bottom-box .burger-box .canvas-menu {
		margin-top: 5px;
	}

	.header-v1 .container-header.container-header_mobile .bottom-box {
		align-items: flex-start;
	}

	.img-responsive {
		width: 100%;
	}

	.page-product-special .products-block.col-nopadding.custom-catalog .product-block.item-default.custom-item {
		width: 100%;
		margin: 0 !important;
	}

	.breadcrumbs .container {
		padding: 0px;
	}
	.form-box-content {
		max-width: 290px;
	}
	.widget_news.box-recipes {
		background-position: -908px;
	}

	.logo-store a span {
		font-size: 8px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .user-payment-container .payment-info .radio_custom .input-control {
		font-size: 16px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .panel-group .form-inline .form-box-inline {
		flex-direction: column;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .panel-group .form-inline .btn,
	.box_code .panel-group {
		margin-left: 0;
	}

	.box_code:before {
		top: auto;
		bottom: -21px;
		right: 22px;
	}

	.main-columns .container-inside .row .col-main .row .col-sm-12 #content .panel-group .form-inline .form-box-inline {
		flex-direction: column;
	}

	.header-v1 .container-header.container-header_mobile .top-box .right-box .telephone-box a {
		margin-left: 10px;
	}

	.widget_news.box-news .widget-inner .owl-stage-outer .owl-stage .owl-item .widget_news_item .widget_news_item_title a {
		text-align: center;
		margin: 0 auto;
	}

	.widget_news.box-recipes .widget-inner {
		max-width: 320px;
		margin: 30px auto 0;

	}

	.widget_news .widget-inner .owl-stage-outer .owl-stage .owl-item .widget_news_item .widget_news_item_description {
		text-align: center;
	}

	.widget_news .widget-heading a {
		margin: 25px auto 0;
	}

	.home-about_us .home-about_us_container h2 {
		margin: 0 auto;
	}

	.featured_categories .featured_categories-container {
		padding: 56px 0 30px;
	}

	.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products, .widget_news.box-news .widget-inner {
		max-width: 320px;
		margin: 0 auto;
	}

	.special-offer.bottom-bg .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products {
		padding-top: 40px;
	}

	.choose-color {
		padding-bottom: 99px;
		padding-top: 20px;
		background-position: -375px;
	}

	.box-recipes {
		padding-bottom: 40px;
	}

	.home-about_us {
		padding-top: 20px;
	}

	.home-about_us .about_us-content .content .text-box {
		margin-top: 0;
	}

	.featured_categories .module_header h4 {
		margin-left: 0;
		text-align: center;
	}

	.widget_news .content-container .subscribe.form-inline .form-group input {
		width: 100%;
	}

	.home-about_us .about_us-content .content .text-box p span {
		max-width: none;
	}

	.featured_categories .featured-categories-carousel .owl-nav .owl-next {
		right: 0;
		top: calc(60% - 20px);
	}

	.featured_categories .featured-categories-carousel .owl-nav .owl-prev {
		left: 0;
		top: calc(60% - 20px);
	}

	.special-offer.bottom-bg .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products:before {
		display: none;
	}

	.home-about_us:before {
		display: none;
	}

	.special-offer .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .border .product-block .product-meta .bottom .cart .btn {
		max-width: 170px;
		width: 100%;
	}

	.featured_categories .module_header {
		margin: 0 auto;
	}

	.featured_categories .module_header h4:before {
		left: calc(50% - 15px);
	}

	.featured_categories:after {
		display: none;
	}

	.featured_categories .featured_categories-container {
		flex-direction: column;
	}

	.featured_categories .featured-categories-carousel .owl-stage-outer {
		width: 100%;
	}

	.header-v1 .container-hover-items .item-four_rc {
		display: none;
	}

	.featured_categories {
		margin-top: 0;
	}

	.choose-color .choose-color_box a {
		margin: 40px auto 0;
	}

	#footer {
		background: url('/catalog/view/theme/kitchenrussia/img/footer-mob.jpg') no-repeat center;
		background-size: cover;
	}

	.widget_news.box-news .widget-inner .owl-stage-outer .owl-stage .owl-item .widget_news_item .widget_news_item_title {
		text-align: center;
	}

	.widget_news .widget-inner .owl-stage-outer .owl-stage .owl-item .widget_news_item .widget_news_item_description {
		text-align: center;
	}

	.widget_news.box-news .widget-inner .owl-stage-outer .owl-stage .owl-item .widget_news_item .news-read-more {
		margin-left: auto;
	}

	.choose-color .choose-color_box {
		background: rgba(179, 50, 81, 0.83);
		position: relative;
		width: 100%;
		padding: 60px 30px;
	}

	.choose-color .choose-color_box:after {
		position: absolute;
		content: '';
		width: calc(100% - 20px);
		height: calc(100% - 20px);
		border: 1px solid #fff;
		top: 10px;
		left: 10px;
		opacity: 0.7;
		z-index: 1;
	}

	.choose-color .choose-color_box:before {
		position: absolute;
		content: '';
		width: 60px;
		height: 70px;
		bottom: -35px;
		left: calc(50% - 30px);
		background: url(/catalog/view/theme/kitchenrussia/img/pepper.svg) no-repeat;
		background-size: cover;
		z-index: 2;
	}

	.choose-color .choose-color_box h2 {
		text-align: center;
	}

	.choose-color .choose-color_box h2:before {
		left: calc(50% - 15px);
	}

	.choose-color .choose-color_box p {
		text-align: center;
		margin-top: 40px;
	}

	.widget_news .widget-heading a {
		margin-top: 20px;
	}

	.widget_news.box-news .widget-inner {
		order: 1;
		width: 100%;
	}

	.widget_news .content-container .go-to-catalog {
		order: 2;
		margin: 0 auto 0;
		max-width: 280px;
		text-transform: uppercase;
		font-size: 17px;
		height: 55px;
	}

	.widget_news .content-container .go-to-catalog:before {
		height: 55px;
		width: 123px;
		border-bottom: 55px solid #c11b40;
	}

	.widget_news .content-container .subscribe.form-inline {
		order: 3;
		padding-bottom: 104px;
		max-width: 290px;
		margin: 20px auto 0;
	}

	.widget_news.box-news {
		background: none;
	}

	.widget_news.box-news .widget-inner:before {
		display: none;
	}

	.widget_news.box-news .widget-inner:after {
		display: none;
	}

	.home-about_us .about_us-content .content .labels {
		flex-direction: column;
		max-width: none;
		align-items: center;
	}

	.home-about_us .about_us-content .content .labels .labels-item p {
		max-width: none;
	}

	.home-about_us .about_us-content .content .labels .labels-item {
		width: 210px;
		margin: 20px 0;
	}

	.home-about_us .about_us-content .content .labels .labels-item p {
		margin-left: 20px;
	}

	.widget_news .widget-inner .owl-nav .owl-prev {
		left: 0;
	}

	.widget_news .widget-inner .owl-nav .owl-next {
		right: 0;
	}

	.widget_news .widget-inner .owl-stage-outer .owl-stage .owl-item .widget_news_item {
		width: 100%;
	}

	.widget_news.box-news .widget_news_item .date {
		right: 0;
		top: 0;
	}

}

@media screen and (max-width: 1280px) {
	.header-v1.inner-header:before {
		display: none;
	}

	.home-about_us .about_us-content .content .labels .labels-item p {
		margin-left: 0;
	}

	.home-about_us:after {
		display: none;
	}

	.widget_news .widget-inner .owl-stage-outer .owl-stage .owl-item .widget_news_item .news-read-more {
		margin: 0 auto;
	}

	.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav li a {
		padding: 10px 20px;
	}
}

/*====================================================================================================================*/
/* Todo: Begin Responsive mobile */
@media screen and (min-width: 1024px) and (max-width: 1199px) {
	.product-info .product-info-bg .image-container .thumbnail {
		height: auto !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul {
		width: 120%;
	}
	.product-info .product-info-bg .image-container .thumbnail {
		height: auto !important;
	}

	.custom-accessories-columns.custom-accessories-center .custom-accessories__center .custom-accessories__items .images {
		height: 100%;
		width: 100% !important;
		position: relative;
		left: initial;
		top: initial;
		z-index: 2;
		box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
		transform: scale(1.2);
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center,
	.custom-accessories-columns.custom-accessories-center .custom-accessories-heading {
		margin: 0 0 90px !important;
	}
	.custom-accessories-columns.custom-accessories-center .btn-group .btn {
		width: 200px !important;
		max-width: 200px !important;
	}
	.custom-accessories-columns.custom-accessories-center .btn-group .btn span:before {
		left: -35px !important;
	}
}

@media screen and (min-width: 961px) and (max-width: 1199px) {
	.product-info .product-info-bg .image-container .thumbnail {
		height: auto !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul {
		width: 120%;
	}
	.product-info .product-info-bg .image-container .thumbnail {
		height: auto !important;
	}

}

@media screen and (min-width: 320px) and (max-width: 1024px) {
	.main-columns.custom-bestsellers-popular .page-navigation:after {
		display: none;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		border-width: 3px;
	}
}

@media screen and (min-width: 768px) and (max-width: 992px) {
	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 50% !important;
		transform: translateY(-50%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 50% !important;
		transform: translateY(-50%) scale(-1, 1) !important;
	}
	.product-info .product-info-bg .image-container .thumbnail {
		height: auto;
	}
	.product-info .product-info-bg .image-container .thumbnail a img {
		height: auto !important;
	}
	.page-navigation__item li {
		margin: 0 30px 0 0;
	}
	.custom-instructions:after {
		content: '';
		height: 122px;
		width: 117px;
		background: url(/catalog/view/theme/kitchenrussia/img/4411.png) no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: -8%;
		left: -2%;
	}

	.custom-instructions:before,
	.tab-content .tab-description .desc-pane + div .custom-review-status:before,
	.tab-content .tab-description .related-products-wrapp .custom-related-products:before {
		display: none;
	}

	.custom-accessories .custom-accessories-inner .custom-accessories-columns .title-link span {
		font-size: 12px !important;
	}
	.custom-accessories-columns.custom-accessories-center .custom-accessories__center .custom-accessories__items .images {
		height: 100%;
		width: 100% !important;
		position: relative;
		left: initial;
		top: initial;
		z-index: 2;
		box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center,
	.custom-accessories-columns.custom-accessories-center .custom-accessories-heading {
		margin: 0 0 50px !important;
	}
	.custom-accessories-columns.custom-accessories-center .btn-group .btn {
		width: 200px !important;
		max-width: 200px !important;
	}
	.custom-accessories-columns.custom-accessories-center .btn-group .btn span:before {
		left: -35px !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs {
		padding: 0 15px !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 66px;
		width: 66px !important;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-stage-outer .owl-stage {
		height: auto;
	}
}

@media screen and (max-width: 960px) {
	.product-filter__item:last-child .btn-group {
		display: initial !important;
	}
	.product-filter__item [for='input-limit'],
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'] {
		margin: initial !important;
	}
	.product-filter__item [for='input-limit'],
	.product-filter__item .sort [for='input-sort'] {
		margin: 0 8px 0 0;
	}
	.product-filter__item,
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'],
	.product-filter__item .sort .selectric-wrapper,
	.product-filter__item:nth-of-type(2),
	.product-filter__item [for='input-limit'],
	.product-filter__item:nth-of-type(2) .selectric-wrapper {
		width: initial;
	}
	.h1_container h1,
	.custom-viewed-box-heading h3 {
		text-align: center;
	}
	.custom-bottom:before {
		height: 90px;
		width: 90px;
		left: -33px;
	}
	.custom-catalog .product-block .top .rating {
		height: 15px;
	}
	.custom-catalog .product-block .top .name {
		margin: 10px 0 4px;
	}
	.custom-catalog .product-block:nth-child(2n) {
		margin: 15px;
	}
	.product-filter + .product-grid .custom-catalog .product-block {
		margin: 15px;
		width: 29.50%;
	}
	.product-filter + .product-list .custom-catalog .product-block:nth-child(2n),
	.product-filter + .product-list .custom-catalog .product-block {
		margin: 15px 0;
		width: 100%;
	}
	#product .product-prices ul li .list-prices .price-new,
	#product .product-prices ul li .list-prices .price-old {
		font-size: 18px;
	}
	#product .custom-product-meta-item:last-child .custom-product-meta-btn,
	.mod-contents.mod-contents--top-spacing.mod-contents--3-images.container.container_v2_slick, .mod-contents.mod-contents--3-images.container.container_v2_slick, .mod-text-photo.mod-text-photo--text-boxed-on-right.container.container_v1 {
		width: 100% !important;
	}
	#product {
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
		-webkit-align-items: flex-start;
		align-items: flex-start;
	}

	.tab-description .desc-pane [id] .mod-video.mod-video--half-width.mod-video--dark.mod-video--half-width---text-on-right.container,
	.tab-description .desc-pane .com-stripe-title__descr.container {
		width: 100% !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container.container_v1 .mod-text-photo__column.mod-text-photo__column--notes {
		padding: 0 !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center,
	.custom-accessories-columns.custom-accessories-center .custom-accessories-heading {
		margin: 0 0 30px !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .title-link span {
		font-size: 12px !important;
	}
	.custom-accessories-columns.custom-accessories-center .btn-group .btn, .custom-accessories-columns.custom-accessories-center .btn-group .btn-book-recipes {
		max-width: 200px !important;
		width: 200px !important;
	}
	.custom-accessories-columns.custom-accessories-center .btn-group .btn-book-recipes span:before, .product-info .cart-btn .cart .btn-cart span:before {
		left: -35px !important;
	}

	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__media-wrapper .mod-video__media .mod-video__image img {
		top: 0 !important;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	.product-info .in-stock {
		top: 0 !important;
		right: 15px !important;
		position: absolute !important;
		z-index: 1 !important;
	}
	#product .product-prices ul li .list-prices .price-old {
		margin: 0 10px 15px 0 !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev,
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 25% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-25%) scale(-1, 1) !important;
	}

	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 50% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-50%) scale(-1, 1) !important;
	}

	.breadcrumb {
		display: flex !important;
		flex-wrap: wrap;
	}

	.product-info .product-info-bg [class*="col-"]:last-child {
		padding: 0 15px !important;
		width: 50% !important;
	}
	#product {
		width: 100% !important;
	}
	#product .custom-product-meta-item:first-child .product-prices, #product .custom-product-meta-item:first-child .quantity {
		margin: 0 !important;
		width: auto !important;
		justify-content: flex-start;
	}
	#product .product-prices ul li .list-prices,
	#product .custom-product-meta-item {
		width: 100% !important;
	}
	#product .product-prices ul li .list-prices .price-old {
		margin: 0 10px 0 0 !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child, .product-info .product-info-bg [class*="col-"]:first-child {
		width: 50% !important;
		float: left !important;
	}
	#product {
		width: 100% !important;
		flex-direction: column !important;
		align-items: flex-start !important;
	}
	#product .custom-product-meta-item .custom-product-meta-btn {
		width: auto !important;
		display: inline-flex !important;
	}
	#product .custom-product-meta-item .quantity.clearfix.quantity {
		height: 0 !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li a, #product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li .button-credit {
		margin: 0 !important;
		padding: 0 0 4px !important;
		width: auto !important;
		display: inline-block !important;
		font-weight: 400 !important;
		font-size: 16px !important;
		line-height: 16px !important;
		color: #c41239 !important;
		text-decoration: none !important;
		cursor: pointer !important;
		border-bottom: 1px solid transparent !important;
		transition: all .3s linear !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item {
		margin: 0 !important;
		padding: 0 !important;
		height: 40px !important;
		width: 125px !important;
		border: 1px solid #c9c9c9 !important;
		box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.1) !important;
		top: -25px !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number .form-control, #product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number {
		height: auto !important;
		line-height: 19px !important;
		color: #000 !important;
		position: relative !important;
		bottom: -2px !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item span .fa, #product .custom-product-meta-item:last-child .quantity .quantity-item .add-down .fa, #product .custom-product-meta-item:last-child .quantity .quantity-item .add-up .fa, #product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number .form-control {
		font-weight: 700 !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item span .fa, #product .custom-product-meta-item:last-child .quantity .quantity-item .add-down .fa, #product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number, #product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number .form-control {
		font-size: 19px !important;
		line-height: 40px !important;
		color: #c41239 !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number .form-control, #product .custom-product-meta-item:last-child .quantity .quantity-item .quantity-number {
		height: auto !important;
		line-height: 19px !important;
		color: #000 !important;
		position: relative !important;
		bottom: -4px !important;
	}
	.product-info {
		width: 100% !important;
	}
	#product .custom-product-meta-item .quantity.clearfix.quantity {
		margin: 20px 0 0 !important;
	}
	#product .custom-product-meta-item .custom-product-meta-btn .cheap_invoice ul li {
		margin: 0 0 15px !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		margin: 0 0 15px !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li a, #product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li .button-credit {
		font-size: 14px !important;
		line-height: 14px !important;
	}
	.custom-accessories-columns.custom-accessories-center .custom-accessories__center .custom-accessories__items .images {
		height: 100%;
		width: 100% !important;
		position: relative;
		left: initial;
		top: initial;
		z-index: 2;
		box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
		transform: scale(1.2);
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center,
	.custom-accessories-columns.custom-accessories-center .custom-accessories-heading {
		margin: 0 0 50px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: 7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -20px !important;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-prev,
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		top: calc(50% - 33px) !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		right: -8px !important;
	}

	.custom-subcat .list-inline.subcat .subcat-item a {
		height: 175px !important;
		position: relative !important;
		overflow: hidden !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item img {
		height: 100px !important;
		max-width: 100% !important;
		width: 100% !important;
		display: block !important;
		object-fit: contain !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item {
		margin: 15px;
		width: 19.333%;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: -7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -7px !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		height: auto;
	}
	.product-info .product-info-bg .image-container .thumbnail a img {
		height: 350px !important;
	}
}

@media screen and (max-width: 823px) {
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		padding: 0 15px !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		margin: 0 0 360px !important;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		margin: 0 0 42px !important;
	}
	.custom-catalog .product-block:nth-of-type(3n + 2) {
		margin: 15px !important;
	}

	.product-info .product-info-bg .image-container .thumbnail {
		height: auto;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 0;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}

	/* Todo: faq fancybox owlcarousel */
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title {
		width: 50%;
	}

}

@media screen and (max-width: 820px) {
	.custom-catalog .product-block:nth-of-type(3n + 2) {
		margin: 15px !important;
	}
}

@media screen and (max-width: 812px) {
	.product-filter__item:last-child .btn-group {
		display: initial;
	}
	.product-filter__item [for='input-limit'],
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'] {
		margin: initial;
	}
	.product-filter__item [for='input-limit'],
	.product-filter__item .sort [for='input-sort'] {
		margin: 0 8px 0 0;
	}
	.product-filter__item,
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'],
	.product-filter__item .sort .selectric-wrapper,
	.product-filter__item:nth-of-type(2),
	.product-filter__item [for='input-limit'],
	.product-filter__item:nth-of-type(2) .selectric-wrapper {
		width: initial;
	}
	.h1_container h1,
	.custom-viewed-box-heading h3 {
		text-align: center;
	}
	.custom-bottom:before {
		height: 90px;
		width: 90px;
		left: -33px;
	}
	.custom-catalog .product-block .top .rating {
		height: 15px;
	}
	.custom-catalog .product-block .top .name {
		margin: 10px 0 4px;
	}
	.custom-catalog .product-block:nth-child(2n) {
		margin: 15px;
	}
	.product-filter + .product-grid .custom-catalog .product-block {
		width: 28.999%;
	}
	.product-filter + .product-list .custom-catalog .product-block:nth-child(2n),
	.product-filter + .product-list .custom-catalog .product-block {
		margin: 15px 0;
		width: 100%;
	}

	/* Todo: Begin cart product */
	.custom-accessories .custom-accessories-inner .custom-accessories__items .title-link span {
		padding: 20px 30px;
		line-height: 24px;
	}

	#product .custom-product-meta-item:first-child .quantity,
	#product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice {
		width: 100%;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
	}
	#product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul {
		width: auto;
		display: inline-block;
	}
	#product .product-prices ul li .list-prices,
	.product-info .cart-btn {
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
	}
	.product-info .product-article {
		text-align: left;
	}
	.product-info .product-stars-and-review-inner .product-stars {
		margin: 0 10px 10px 0px;
	}
	#product,
	.product-info .product-stars-and-review-inner,
	.product-grid,
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		-webkit-flex-direction: column;
		flex-direction: column;
	}
	.page-navigation__item {
		-webkit-justify-content: center;
		justify-content: center;
		gap: 16px;
	}
	.product-info .product-stars-and-review-inner {
		-webkit-align-items: flex-start;
		align-items: flex-start;
		flex-direction: row !important;
	}
	.product-info .product-info-bg [class*="col-"]:first-child {
		width: 40%;
	}
	.product-info .product-info-bg [class*="col-"]:last-child {
		padding: 0 15px;
		width: 50%;
	}
	#tab-description img {
		width: 100%;
		z-index: 2;
	}

	#product .custom-product-meta-item,
	.custom-accessories .custom-accessories-inner .custom-accessories-columns,
	.tab-characteristics > div .desc-pane .table thead tr td .td-item {
		width: 33%;
	}
	.com-stripe-title__descr,
	.tab-characteristics > div .desc-pane .table tbody tr td .td-item,
	.tab-characteristics > div .desc-pane .table thead tr td .td-item {
		text-align: left;
	}
	#product .product-prices ul li .list-prices {
		width: 100%;
	}
	#product .custom-product-meta-item:first-child .product-prices,
	#product .custom-product-meta-item:first-child .quantity {
		margin: 0;
		width: auto;
		display: -webkit-inline-flex;
		display: inline-flex;
		-webkit-justify-content: center;
		justify-content: center;
	}
	#product .custom-product-meta-item:last-child {
		width: 100%;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		-webkit-flex-direction: row;
		flex-direction: row;
		justify-content: center;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		width: 45%;
		float: left;
	}
	#product .custom-product-meta-item:first-child .product-prices,
	#product .product-prices ul li .list-prices .price-old {
		margin: 0 10px 0 0;
	}
	.product-info .product-view {
		padding: 30px 0;
	}
	#product .product-prices ul li .list-prices .price-new {
		margin: 0;
	}
	.breadcrumb {
		display: none;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		margin: 0 0 320px;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__items .title-link span {
		font-size: 14px;
		line-height: 24px;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-center .btn-group {
		margin: 0 0 45px;
	}
	.custom-related-products .desc-pane .products-block .product-col-wrap,
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		width: auto;
	}
	.viewed-box-popular-wrapper .viewed-box-content:before {
		top: -6.5%;
		left: -80px;
		z-index: -1;
	}
	.producttabs .owl-carousel.product-grid.col-nopadding.tcarousel.owl-loaded.owl-drag:before, .viewed-box-popular-wrapper .viewed-box-content:before, .tab-content .tab-description .related-products-wrapp .custom-related-products:before {
		right: -115px;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container {
		padding: 15px;
		height: 100%;
	}

	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container .mod-content.mod-content-item__content h3 {
		font-size: 18px;
	}

	#product {
		width: 50%;
	}
	.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title {
		line-height: 24px;
	}

	.custom-accessories-columns.custom-accessories-center .custom-accessories__center, .custom-accessories-columns.custom-accessories-center .custom-accessories-heading,
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		margin: 0 0 45px !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .title-link {
		padding: 0 !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		width: auto !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		padding: 0 !important;
	}
	.product-info .in-stock {
		top: -20px !important;
		right: 15px !important;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}

	.tab-description .desc-pane [id] .mod-slideshow ul .mod-slideshow__item.slick-slide {
		height: auto !important;
	}
	.product-info .in-stock {
		top: 0 !important;
		right: 15px !important;
		position: absolute !important;
		z-index: 1 !important;
	}
	#product .product-prices ul li .list-prices .price-old {
		margin: 0 10px 0 0 !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev,
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 25% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-25%) scale(-1, 1) !important;
	}

	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 50% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-50%) scale(-1, 1) !important;
	}

	.breadcrumb {
		display: flex !important;
	}
	#product .custom-product-meta-item:first-child {
		align-items: flex-start !important;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: 7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -20px !important;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-prev,
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		top: calc(50% - 33px) !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		right: -8px !important;
	}

	.custom-subcat .list-inline.subcat .subcat-item a {
		height: 175px !important;
		position: relative !important;
		overflow: hidden !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item img {
		height: 100px !important;
		max-width: 100% !important;
		width: 100% !important;
		display: block !important;
		object-fit: contain !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item {
		margin: 15px;
		width: 20.333%;
	}
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item {
		width: 100% !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 60px;
		width: 100%;
	}
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item img {
		height: auto !important;
		width: 100% !important;
		display: block !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}
}

@media screen and (min-width: 768px) and (max-width: 812px) {
	.widget-products .product-grid .product-block {
		margin: 0 0 15px !important;
	}
}

@media screen and (max-width: 768px) {
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: -7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -7px !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		height: auto !important;
	}
	.product-info .product-info-bg .image-container .thumbnail a img {
		height: auto !important;
	}

	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider {
		margin: 0 !important;
	}
	.page-navigation__item li {
		margin: 0;
	}
	.custom-instructions:after {
		content: '';
		height: 122px;
		width: 117px;
		background: url(/catalog/view/theme/kitchenrussia/img/4411.png) no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: -8%;
		left: -2%;
	}

	.custom-instructions:before,
	.tab-content .tab-description .desc-pane + div .custom-review-status:before,
	.tab-content .tab-description .related-products-wrapp .custom-related-products:before {
		display: none;
	}

	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item {
		width: 100% !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 60px;
		width: 100%;
	}
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item img {
		height: auto !important;
		width: 100% !important;
		display: block !important;
	}

}

@media screen and (min-width: 320px) and (max-width: 767px) {

	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.custom-instructions:after {
		content: '';
		height: 122px;
		width: 117px;
		background: url(/catalog/view/theme/kitchenrussia/img/4411.png) no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: -8%;
		left: -2%;
	}

	.custom-instructions:before,
	.tab-content .tab-description .desc-pane + div .custom-review-status:before,
	.tab-content .tab-description .related-products-wrapp .custom-related-products:before {
		display: none;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		margin: 0 0 30px !important;
		height: 329px !important;
	}
}

@media screen and (max-width: 760px) {
	.page-product.row-offcanvas:before {
		display: none;
	}
	.product-info .product-info-bg .image-container .thumbnail,
	.product-info .product-info-bg .image-container .thumbnail a img {
		height: auto !important;
	}
	.custom-instructions:after {
		content: '';
		height: 122px;
		width: 117px;
		background: url(/catalog/view/theme/kitchenrussia/img/4411.png) no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: -8%;
		left: -2%;
	}

	.custom-instructions:before,
	.tab-content .tab-description .desc-pane + div .custom-review-status:before,
	.tab-content .tab-description .related-products-wrapp .custom-related-products:before {
		display: none;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		margin: 0 0 30px !important;
		height: 329px !important;
	}
	.product-info .product-info-bg .image-container .thumbnail {
		height: calc(100% - 75px) !important;
	}
	.product-info .product-info-bg .image-container .thumbnail, .product-info .product-info-bg .image-container .thumbnail a img {
		height: 100% !important;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 0;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (min-width: 668px) and (max-width: 760px) {
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice, #product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul {
		width: 120%;
	}
	#product .custom-product-meta-item:first-child .product-prices, #product .product-prices ul li .list-prices .price-old {
		margin: 0 15px 0 0 !important;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		flex-direction: row;
	}

	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 82px;
		width: 82px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}

	.custom-instructions:after {
		content: '';
		height: 122px;
		width: 117px;
		background: url(/catalog/view/theme/kitchenrussia/img/4411.png) no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: -8%;
		left: -2%;
	}

	.custom-instructions:before,
	.tab-content .tab-description .desc-pane + div .custom-review-status:before,
	.tab-content .tab-description .related-products-wrapp .custom-related-products:before {
		display: none;
	}

	.product-info .product-info-bg .image-container .custom-image-container {
		margin: 0 0 30px !important;
		height: 329px !important;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__title {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		margin: 8px !important;
		height: 100%;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: -7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -7px !important;
	}
	.custom-instructions .container .desc-pane .pdf-download {
		margin: 0 15px 0 0;
		width: auto;
		display: inline-block;
	}
	.custom-instructions .container .desc-pane ul {
		flex-direction: row;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 15px 30px 0;
	}
	.custom-instructions .container .desc-pane ul li:last-child {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 16px;
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider:before {
		display: none !important;
	}
}

@media screen and (max-width: 736px) {
	.product-filter__item:last-child .btn-group {
		display: none !important;
	}
	.product-filter__item [for='input-limit'],
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'] {
		margin: initial;
	}
	.product-filter__item [for='input-limit'],
	.product-filter__item .sort [for='input-sort'] {
		margin: 0 8px 0 0;
	}
	.product-filter__item,
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'],
	.product-filter__item .sort .selectric-wrapper,
	.product-filter__item:nth-of-type(2),
	.product-filter__item [for='input-limit'],
	.product-filter__item:nth-of-type(2) .selectric-wrapper {
		width: initial;
	}
	.h1_container h1,
	.custom-viewed-box-heading h3 {
		text-align: center;
	}
	.custom-bottom:before {
		height: 90px;
		width: 90px;
		left: -33px;
	}
	.custom-catalog .product-block .top .rating {
		height: 15px;
	}
	.custom-catalog .product-block .top .name {
		margin: 10px 0 4px;
	}
	.custom-catalog .product-block:nth-child(2n) {
		margin: 15px 15px 15px 0 !important;
	}
	.product-filter + .product-grid .custom-catalog .product-block {
		margin: 15px 13px !important;
		width: 46%;
	}
	.product-filter + .product-list .custom-catalog .product-block:nth-child(2n),
	.product-filter + .product-list .custom-catalog .product-block {
		margin: 15px;
		width: 100%;
	}

	.refine-search ul li, .refine-search ol li {
		width: calc((100% / 2) - 20px) !important;
	}

	/* Todo: Begin cart product */
	.custom-accessories .custom-accessories-inner .custom-accessories__items .title-link span {
		font-size: 12px;
	}
	/* Todo: End cart product */
	.breadcrumbs .container .breadcrumb .width span {
		font-size: 12px !important;
	}
	.product-info .in-stock {
		right: 55px !important;
		top: 65px !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container.container_v1 .mod-text-photo__column.mod-text-photo__column--notes {
		padding: 8px 0 0 !important;
	}

	.mod-contents.mod-contents--top-spacing.mod-contents--3-images.container.container_v2_slick {
		margin: 0 0 -40px !important;
	}
	.mod-contents.mod-contents--top-spacing.mod-contents--3-images.container.container_v2_slick, .mod-contents.mod-contents--3-images.container.container_v2_slick,
	.tab-content .tab-description .related-products-wrapp .container, .viewed-box.viewed-box-popular-wrapper .container {
		margin-left: auto !important;
		margin-right: auto !important;
		width: 100% !important;
	}
	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider,
	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider .row {
		margin: 0 auto;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		border: none !important;
	}
	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider .row .mod-contents__column {
		width: auto !important;
		padding: 0 !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		width: auto !important;
		margin: 0;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__title {
		margin: 0 0 30px;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-stage-outer {
		left: 0 !important;
	}

	.product-info .product-info-bg .image-container .custom-image-container {
		margin: 0 0 30px !important;
	}

	.product-info .cart-btn .cart {
		min-width: 145px !important;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev,
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 25% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-25%) scale(-1, 1) !important;
	}

	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 50% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-50%) scale(-1, 1) !important;
	}
	.product-grid .product-block .bottom, .products-owl-carousel .product-block .bottom {
		-webkit-align-items: flex-start !important;
		align-items: flex-start !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}
	#product .custom-product-meta-item:first-child {
		align-content: flex-start !important;
		align-items: flex-start !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item {
		top: 0 !important;
	}
	#product .custom-product-meta-item .quantity.clearfix.quantity {
		margin: 0 !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item {
		margin: 0 0 15px !important;
	}
	.product-info .title-product {
		line-height: 28px !important;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}

	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item {
		width: 100% !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 60px;
		width: 100%;
	}
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item img {
		height: auto !important;
		width: 100% !important;
		display: block !important;
	}

	/* Todo: Slick Slidrt Owl */

	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__title {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: auto !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}
	.custom-instructions .container .desc-pane .pdf-download {
		width: 35px;
	}
	.custom-instructions .container .desc-pane ul {
		flex-direction: row;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 15px 30px;
	}
	.custom-instructions .container .desc-pane ul li:last-child {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 0px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (min-width: 667px) and (max-width: 736px) {
	/* Todo: Slick Slidrt Owl */

	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__title {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		margin: 8px !important;
		height: 420px;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: -7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -7px !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs {
		padding: 0 15px !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 66px;
		width: 66px !important;
	}
	.custom-instructions .container .desc-pane .pdf-download {
		width: 35px;
	}
	.custom-instructions .container .desc-pane ul {
		flex-direction: row;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 15px 30px;
	}
	.custom-instructions .container .desc-pane ul li:last-child {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 0px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (max-width: 731px) {
	.custom-bottom .panel-body,
	.custom-subcat {
		position: relative;
		left: 0;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}

	.owl-carousel .owl-stage {
		left: 5px !important;
	}

	/* Todo: Slick slider */
	.tab-description .desc-pane [id] .mod-contents .slick-slider .slick-track .owl-nav .owl-prev, .tab-description .desc-pane [id] .mod-contents .slick-slider .slick-track .owl-nav .owl-next {
		top: calc(41.888% - 20px);
	}
	.tab-description .desc-pane [id] .mod-contents .slick-slider .slick-track .owl-nav .owl-prev {
		left: -7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-slider .slick-track .owl-nav .owl-next {
		right: -7px !important;
	}
	.product-info .product-info-bg .image-container .thumbnail {
		height: auto;
	}
	.product-info .product-stars-and-review-inner .product-stars {
		margin: 0px 10px 10px 0px;
	}
	.custom-featured .products-block .product-block {
		width: 98%;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-items: center;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}

	/* Todo: Slick Slidrt Owl */

	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__title {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		margin: 8px !important;
		height: 100%;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__title {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		margin: 8px !important;
		height: 100%;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}

	.product-info .product-info-bg .image-container .image-additional .imgthumbs {
		padding: 0 15px !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 66px;
		width: 66px !important;
	}
	.custom-instructions .container .desc-pane .pdf-download {
		width: 35px;
	}
	.custom-instructions .container .desc-pane ul {
		flex-direction: row;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 15px 30px;
	}
	.custom-instructions .container .desc-pane ul li:last-child {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}
	.custom-instructions .container .desc-pane .pdf-download {
		width: 35px;
	}
	.custom-instructions .container .desc-pane ul {
		flex-direction: row;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 15px 30px;
	}
	.custom-instructions .container .desc-pane ul li:last-child {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 0 !important;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: capitalize;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}

	/* Todo: faq fancybox owlcarousel */
	.custom-faq .mod-accessories__row.mod-accessories__row--header {
		justify-content: center;
		flex-direction: row;
		width: 100%;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image {
		display: flex;
	}
	.mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image .mod-accessories__image-wrp .mod-accessories__image {
		height: 100%;
		object-fit: cover;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root {
		height: auto;
		width: 100%;
		display: block;
		flex-direction: inherit;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:first-child,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:last-child {
		padding: 0;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item {
		margin: 0 0 15px;
		width: 48%;
		display: inline-block;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:nth-child(even) {
		float: right;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item .mod-accessories__placeholder {
		width: 100%;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image {
		width: 50%;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		max-width: 100% !important;
	}

	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-close {
		top: 0;
		right: 0;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		left: 0 !important;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin {
		padding: 15px !important;
		width: 480px !important;
		left: 50%;
		transform: translateX(-50%);
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title .mod-accessories__title {
		font-size: 20px;
	}
}

@media screen and (min-width: 667px) and (max-width: 736px) {
	.product-info .in-stock {
		position: absolute !important;
		right: 15px !important;
		top: 0 !important;
		z-index: 2 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: -7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -7px !important;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-prev,
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		top: calc(50% - 33px) !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		right: -8px !important;
	}

	.custom-subcat .list-inline.subcat .subcat-item a {
		height: 175px !important;
		position: relative !important;
		overflow: hidden !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item img {
		height: 100px !important;
		max-width: 100% !important;
		width: 100% !important;
		display: block !important;
		object-fit: contain !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item {
		margin: 15px;
		width: 20.333%;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}
	.custom-featured .products-block .product-block .product-meta .bottom .cart .custom-left-btn span:before {
		left: 10px !important;
		top: -5px !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}
	.custom-instructions .container .desc-pane .pdf-download {
		width: 35px;
	}
	.custom-instructions .container .desc-pane ul {
		flex-direction: row;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 15px 30px;
	}
	.custom-instructions .container .desc-pane ul li:last-child {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 0px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (max-width: 667px) {
	.product-filter__item:last-child .btn-group {
		display: initial;
	}
	.product-filter__item [for='input-limit'],
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'] {
		margin: initial;
	}
	.product-filter__item [for='input-limit'],
	.product-filter__item .sort [for='input-sort'] {
		margin: 0 8px 0 0;
	}
	.product-filter__item,
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'],
	.product-filter__item .sort .selectric-wrapper,
	.product-filter__item:nth-of-type(2),
	.product-filter__item [for='input-limit'],
	.product-filter__item:nth-of-type(2) .selectric-wrapper {
		width: initial;
	}
	.h1_container h1,
	.custom-viewed-box-heading h3 {
		text-align: center;
	}
	.custom-bottom:before {
		height: 90px;
		width: 90px;
		left: -33px;
	}
	.custom-catalog .product-block .top .rating {
		height: 15px;
	}
	.custom-catalog .product-block .top .name {
		margin: 10px 0 4px;
	}
	.custom-catalog .product-block:nth-child(2n) {
		margin: 15px 15px 15px 0;
	}
	.product-filter + .product-grid .custom-catalog .product-block {
		width: 45%;
	}
	.product-filter + .product-grid .custom-catalog .product-block:nth-child(2), .product-filter + .product-grid .custom-catalog .product-block:nth-child(3n + 2) {
		margin: 15px !important;
	}
	.product-filter + .product-list .custom-catalog .product-block:nth-child(2n),
	.product-filter + .product-list .custom-catalog .product-block {
		margin: 15px !important;
		width: 100%;
	}

	.refine-search ul li, .refine-search ol li {
		width: calc((100% / 2) - 20px) !important;
	}
	.custom-bottom .panel-body {
		position: relative;
		left: 0;
	}
	/* Todo: Begin cart product */
	.product-info .product-description {
		width: 100% !important;
	}
	.product-info .product-info-bg .image-container {
		margin: 0 0 30px;
	}
	#product .custom-product-meta-item:first-child .quantity,
	#product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice {
		width: 100%;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
	}
	#product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul {
		width: auto;
		display: inline-block;
	}
	.product-info .cart-btn {
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
	}
	.product-info .product-article {
		text-align: left;
	}
	.product-info .product-stars-and-review-inner .product-stars {
		margin: 0px 10px 10px 0px;
	}
	.product-info .product-stars-and-review-inner,
	.page-navigation__item,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container,
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		-webkit-flex-direction: column;
		flex-direction: column;
	}

	.product-grid {
		-webkit-justify-content: center;
		justify-content: center;
	}

	.page-navigation__item,
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		-webkit-flex-direction: row;
		flex-direction: row;
		-webkit-justify-content: center;
		justify-content: center;
	}
	.page-navigation__item li {
		margin: 0 30px 0 0;
	}
	.page-navigation__item li a {
		font-size: 12px;
		line-height: 12px;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column,
	#tab-description img,
	.custom-related-products .desc-pane .products-block .product-col-wrap {
		margin: 0 0 15px;
	}
	#product .custom-product-meta-item,
	.custom-accessories .custom-accessories-inner .custom-accessories-columns,
	.tab-characteristics > div .desc-pane .table thead tr td .td-item,
	.custom-related-products .desc-pane .products-block .product-col-wrap {
		width: 100%;
	}
	.custom-related-products .desc-pane .products-block .product-col-wrap,
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		margin: 15px;
		width: 45%;
	}
	#product .custom-product-meta-item:first-child,
	.com-stripe-title__descr,
	.tab-characteristics > div .desc-pane .table tbody tr td .td-item,
	.tab-characteristics > div .desc-pane .table thead tr td .td-item {
		text-align: left;
	}
	.product-info .product-view {
		padding: 30px 0;
	}
	.breadcrumb {
		display: none;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		margin: 0 0 690px;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center .custom-accessories__items {
		transform: scale(1);
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__items .title-link span {
		line-height: 24px;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-center .btn-group {
		margin: 0 0 45px;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		padding: 0 15px;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		margin: 0 0 30px;
		width: 100%;
	}
	.viewed-box-popular-wrapper .viewed-box-content:before {
		top: -6.5%;
		left: -80px;
		z-index: -1;
	}
	.producttabs .owl-carousel.product-grid.col-nopadding.tcarousel.owl-loaded.owl-drag:before, .viewed-box-popular-wrapper .viewed-box-content:before, .tab-content .tab-description .related-products-wrapp .custom-related-products:before {
		right: -115px;
	}
	.products-block.row.products-row.product-grid {
		padding: 0 15px;
	}

	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container {
		height: auto !important;
	}
	/* Todo: End cart product */

	/* Todo: Begin last work */
	.tab-content .tab-description .related-products-wrapp .container, .viewed-box.viewed-box-popular-wrapper .container {
		width: 90% !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status:before {
		display: none !important;
	}
	#product .custom-product-meta-item:last-child {
		margin: 45px 0 0 !important;
	}
	.product-markdown-view #product .custom-product-meta-item:last-child {
		margin: 0 !important;
	}
	#product .custom-product-meta-item:first-child {
		align-items: flex-start !important;
	}
	#product .custom-product-meta-item:first-child .product-prices {
		margin: 0 0 10px !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column {
		margin: 0 0 17px !important;
	}
	.product-info .in-stock {
		right: 15px !important;
		top: 65px !important;
	}
	.tab-description .desc-pane [id] .mod-slideshow ul .mod-slideshow__item.slick-slide {
		height: auto !important;
	}
	.breadcrumb > li,
	.breadcrumbs .container .breadcrumb .width span,
	.breadcrumbs .breadcrumb li:first-child [itemprop="name"] {
		font-size: 11px;
	}

	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__media-wrapper .mod-video__media {
		height: 350px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		margin: 0 0 30px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content,
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content p,
	.mod-content__content, .mod-content__content p {
		margin: 0 0 30px !important;
	}
	#product .custom-product-meta-item:first-child {
		-webkit-flex-direction: column !important;
		flex-direction: column !important;
	}

	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container, .mod-text-photo.mod-text-photo--text-boxed-on-right.container.container_v1 {
		padding: 0 15px !important;
	}

	.custom-accessories .custom-accessories-inner .custom-accessories-columns .title-link {
		padding: 0 !important;
	}

	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container.container_v1 .mod-text-photo__column.mod-text-photo__column--notes {
		padding: 8px 0 0 !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		margin: 0 0 30px !important;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}

	#product .custom-product-meta-item:first-child {
		margin: 0 0 15px !important;
	}
	.page-navigation__item {
		padding: 30px 0 !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content {
		padding: 0 !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content:before {
		display: none !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content .mod-content__title {
		text-transform: none !important;
	}

	.mod-content__title, .mod-content__content, .mod-content__content p,
	.mod-text-photo__notes, .tab-description .desc-pane .com-stripe-title__descr.container {
		text-align: left !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: 115px !important;
		text-align: center !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .custom-accessories__items {
		margin: 0 !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:first-child .custom-accessories__items .images {
		border-left: none !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:last-child .custom-accessories__items .images {
		border-right: none !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		display: inline-flex !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center .custom-accessories__items {
		margin: 0 !important;
		padding: 0 15px !important;
	}
	.custom-accessories-columns.custom-accessories-center .custom-accessories__center .custom-accessories__items .images {
		width: 100% !important;
		position: initial !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products:before,
	.viewed-box-popular-wrapper .viewed-box-content:before {
		display: none !important;
	}
	.custom-accessories-columns.custom-accessories-center .btn-group .btn-book-recipes {
		max-width: 234px !important;
		width: 234px !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .btn-group .btn-book-recipes span {
		font-size: 14px !important;
	}
	.breadcrumbs .breadcrumb {
		padding: 0 !important;
		display: inline-block !important;
	}
	.product-info .in-stock {
		position: absolute !important;
		left: initial !important;
		right: 15px !important;
		top: 0 !important;
		z-index: 2 !important;
	}
	.product-info .title-product {
		margin: 0 0 30px !important;
		padding: 0 !important;
	}
	.page-navigation__item li {
		margin: 0 15px 0 0 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status {
		margin: 0 0 50px !important;
	}
	#product .custom-product-meta-item:first-child .product-prices,
	#product .custom-product-meta-item:first-child .custom-product-meta-btn {
		width: auto !important;
	}
	#product .custom-product-meta-item:last-child {
		text-align: left !important;
	}
	.product-info .product-stars-and-review-inner:before {
		left: 5% !important;
	}
	.custom-accessories .container {
		padding: 0 !important;
	}
	.custom-accessories .custom-accessories-inner {
		width: 100% !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		justify-content: center !important;
	}

	.product-grid .product-block .bottom, .products-owl-carousel .product-block .bottom {
		justify-content: center !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev {
		top: 25% !important;
		left: 10px !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 22% !important;
		right: 10px !important;
	}
	.tab-content .tab-description .related-products-wrapp .container {
		padding: 0 !important;
	}
	.custom-related-products .desc-header-wrapper {
		margin: 0 0 -30px !important
	}
	.product-info .product-info-bg [class*="col-"] {
		width: 50% !important;
	}
	#product .product-prices ul li .list-prices .price-old {
		margin: 0 10px 0 0 !important;
	}
	.product-info .title-product {
		margin: 0 0 15px !important;
		line-height: 28px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}

	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content {
		padding: 0 !important;
		margin: -45px 0 0 !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper.container {
		width: 100% !important;
	}
	.product-info .title-product, .breadcrumbs .container .breadcrumb {
		padding-left: 15px !important;
		text-align: left !important;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: 7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -20px !important;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-prev,
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		top: calc(50% - 33px) !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		right: -8px !important;
	}

	.custom-subcat .list-inline.subcat .subcat-item a {
		height: 175px !important;
		position: relative !important;
		overflow: hidden !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item img {
		height: 100px !important;
		max-width: 100% !important;
		width: 100% !important;
		display: block !important;
		object-fit: contain !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item {
		margin: 15px;
		width: 19.333%;
	}

	.products-block.col-nopadding.custom-catalog {
		margin: -15px !important;
		position: relative !important;
		left: 5px !important;
	}

	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev,
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 47% !important;
		transform: translateY(-47%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-47%) scale(-1, 1) !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-stage-outer .product-col {
		width: 100% !important;
		margin: 0 !important;
		display: inline-block !important;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}
	.custom-featured .products-block .product-block .product-meta .bottom .cart .custom-left-btn span:before {
		left: 10px !important;
		top: -5px !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}
	.custom-instructions .container .desc-pane .pdf-download {
		width: 35px;
	}
	.custom-instructions .container .desc-pane ul {
		flex-direction: row;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 15px 30px;
	}
	.custom-instructions .container .desc-pane ul li:last-child {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (min-width: 641px) and (max-width: 667px) {
	.product-info .product-stars-and-review-inner:before {
		left: 0 !important;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}
	.custom-featured .products-block .product-block .product-meta .bottom .cart .custom-left-btn span:before {
		left: 10px !important;
		top: -5px !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}
	.custom-instructions .container .desc-pane .pdf-download {
		width: 35px;
	}
	.custom-instructions .container .desc-pane ul {
		flex-direction: row;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 15px 30px;
	}
	.custom-instructions .container .desc-pane ul li:last-child {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (max-width: 640px) {
	.custom-subcat {
		left: 0;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item {
		width: 100% !important;
	}
	#product .custom-product-meta-item:first-child {
		margin: 0 0 15px !important;
	}
	.page-navigation__item {
		padding: 30px 0 !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content {
		padding: 0 !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content:before {
		display: none !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content .mod-content__title {
		text-transform: none !important;
	}

	.mod-content__title, .mod-content__content, .mod-content__content p,
	.mod-text-photo__notes, .tab-description .desc-pane .com-stripe-title__descr.container {
		text-align: left !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: 115px !important;
		text-align: center !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .custom-accessories__items {
		margin: 0 !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:first-child .custom-accessories__items .images {
		border-left: none !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:last-child .custom-accessories__items .images {
		border-right: none !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		display: inline-flex !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center .custom-accessories__items {
		margin: 0 !important;
		padding: 0 15px !important;
	}
	.custom-accessories-columns.custom-accessories-center .custom-accessories__center .custom-accessories__items .images {
		width: 100% !important;
		position: initial !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products:before,
	.viewed-box-popular-wrapper .viewed-box-content:before {
		display: none !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .btn-group .btn-book-recipes span {
		font-size: 14px !important;
	}
	.breadcrumbs .breadcrumb {
		padding: 0 !important;
		display: inline-block !important;
	}
	.product-info .in-stock {
		position: absolute !important;
		left: initial !important;
		right: 15px !important;
		top: 0 !important;
		z-index: 2 !important;
	}
	.product-info .title-product {
		margin: 0 0 30px !important;
		padding: 0 !important;
	}
	.page-navigation__item li {
		margin: 0 15px 0 0 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status {
		margin: 0 0 50px !important;
	}
	#product .custom-product-meta-item:first-child .product-prices,
	#product .custom-product-meta-item:first-child .custom-product-meta-btn {
		width: auto !important;
	}
	#product .custom-product-meta-item:last-child {
		text-align: left !important;
	}
	.product-info .product-stars-and-review-inner .product-stars {
		margin: 0 0 10px;
	}
	.product-info .product-stars-and-review-inner:before {
		left: 0 !important;
	}
	.custom-accessories .container {
		padding: 0 !important;
	}
	.custom-accessories .custom-accessories-inner {
		width: 100% !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		justify-content: center !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .product-col-wrap {
		width: auto !important;
	}
	.product-grid .product-block .bottom, .products-owl-carousel .product-block .bottom {
		justify-content: center !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev {
		top: 25% !important;
		left: 10px !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 22% !important;
		right: 10px !important;
	}
	.tab-content .tab-description .related-products-wrapp .container {
		padding: 0 !important;
	}

	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 50% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-50%) scale(-1, 1) !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		display: none !important;
	}
	.custom-related-products .desc-header-wrapper {
		margin: 0 0 -30px !important
	}
	.product-info .product-info-bg [class*="col-"] {
		width: 50% !important;
	}
	#product .product-prices ul li .list-prices .price-old {
		margin: 0 10px 0 0 !important;
	}
	.product-info .title-product {
		margin: 0 0 15px !important;
		line-height: 28px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}

	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item img {
		height: auto !important;
		width: 100% !important;
		display: block !important;
	}

	.product-info .product-info-bg .image-container .thumbnail {
		height: auto;
	}

	/* Todo: slick slider */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"], .tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__title {
		height: 50px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-slider .slick-track .owl-nav .owl-prev, .tab-description .desc-pane [id] .mod-contents .slick-slider .slick-track .owl-nav .owl-next {
		top: calc(39% - 20px);
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: -10px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -8px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: auto !important;
		text-align: center !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item {
		margin: 0 7px 30px !important;
		height: 400px;
	}

	.owl-carousel .owl-stage {
		left: 5px !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"],
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] {
		width: 98% !important;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.page-product.row-offcanvas:before {
		display: none;
	}

	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}
	.custom-instructions .container .desc-pane .pdf-download {
		width: 35px;
	}
	.custom-instructions .container .desc-pane ul {
		flex-direction: row;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 15px 30px;
	}
	.custom-instructions .container .desc-pane ul li:last-child {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}

	/* Todo: faq fancybox owlcarousel */
	.custom-faq .mod-accessories__row.mod-accessories__row--header {
		justify-content: center;
		flex-direction: row;
		width: 100%;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image {
		display: flex;
	}
	.mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image .mod-accessories__image-wrp .mod-accessories__image {
		height: 100%;
		object-fit: cover;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root {
		height: auto;
		width: 100%;
		display: block;
		flex-direction: inherit;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:first-child,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:last-child {
		padding: 0;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item {
		margin: 0 0 15px;
		width: 48%;
		display: inline-block;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:nth-child(even) {
		float: right;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item .mod-accessories__placeholder {
		width: 100%;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image {
		width: 50%;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		max-width: 100% !important;
	}

	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-close {
		top: 0;
		right: 0;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		left: 0 !important;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin {
		padding: 15px !important;
		width: 480px !important;
		left: 50%;
		transform: translateX(-50%);
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title .mod-accessories__title {
		font-size: 20px;
	}
}

@media screen and (min-width: 616px) and (max-width: 640px) {
	.custom-featured .products-block .product-block .product-meta .bottom .cart .custom-left-btn span:before {
		left: 10px !important;
		top: -5px !important;
	}
	.custom-instructions .container .desc-pane .pdf-download {
		width: 35px;
	}
	.custom-instructions .container .desc-pane ul {
		flex-direction: row;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 15px 30px;
	}
	.custom-instructions .container .desc-pane ul li:last-child {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}

	/* Todo: faq fancybox owlcarousel */
	.custom-faq .mod-accessories__row.mod-accessories__row--header {
		justify-content: center;
		flex-direction: row;
		width: 100%;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image {
		display: flex;
	}
	.mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image .mod-accessories__image-wrp .mod-accessories__image {
		height: 100%;
		object-fit: cover;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root {
		height: auto;
		width: 100%;
		display: block;
		flex-direction: inherit;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:first-child,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:last-child {
		padding: 0;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item {
		margin: 0 0 15px;
		width: 48%;
		display: inline-block;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:nth-child(even) {
		float: right;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item .mod-accessories__placeholder {
		width: 100%;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image {
		width: 50%;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		max-width: 100% !important;
	}

	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-close {
		top: 0;
		right: 0;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		left: 0 !important;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin {
		padding: 15px !important;
		width: 480px !important;
		left: 50%;
		transform: translateX(-50%);
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title .mod-accessories__title {
		font-size: 20px;
	}
}

@media screen and (min-width: 601px) and (max-width: 615px) {
	.product-filter__item:last-child .btn-group {
		display: none;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.page-product.row-offcanvas:before {
		display: none;
	}
	.custom-featured .products-block .product-block .product-meta .bottom .cart .custom-left-btn span:before {
		left: 10px !important;
		top: -5px !important;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 0;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}

	/* Todo: faq fancybox owlcarousel */
	.custom-faq .mod-accessories__row.mod-accessories__row--header {
		justify-content: center;
		flex-direction: row;
		width: 100%;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image {
		display: flex;
	}
	.mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image .mod-accessories__image-wrp .mod-accessories__image {
		height: 100%;
		object-fit: cover;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root {
		height: auto;
		width: 100%;
		display: block;
		flex-direction: inherit;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:first-child,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:last-child {
		padding: 0;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item {
		margin: 0 0 15px;
		width: 48%;
		display: inline-block;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:nth-child(even) {
		float: right;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item .mod-accessories__placeholder {
		width: 100%;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image {
		width: 50%;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		max-width: 100% !important;
	}

	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-close {
		top: 0;
		right: 0;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		left: 0 !important;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin {
		padding: 15px !important;
		width: 480px !important;
		left: 50%;
		transform: translateX(-50%);
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title .mod-accessories__title {
		font-size: 20px;
	}
}

@media screen and (min-width: 375px) and (max-width: 607px) {
	.product-info .product-stars-and-review-inner {
		padding: 0 0 10px;
		flex-direction: row;
	}
	.product-info .product-stars-and-review-inner .product-stars,
	.product-info .product-stars-and-review-inner .product-review {
		margin: 0 15px 10px 0;
	}
	.product-info .product-stars-and-review-inner:before {
		left: 0 !important;
		top: 40px;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.page-product.row-offcanvas:before {
		display: none;
	}
	.custom-featured .products-block .product-block .product-meta .bottom .cart .custom-left-btn span:before {
		left: 10px !important;
		top: -5px !important;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
		a .title {
			font-size: 14px;
		}
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
		span {
			font-size: 11px;
			line-height: 11px;
			text-transform: uppercase;
		}
		&:before {
			height: 19px;
			border-bottom: 19px solid #c11b40;
		}
	}
}

@media screen and (min-width: 569px) and (max-width: 606px) {
	.product-filter + .product-grid .custom-catalog .product-block {
		width: 44%;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}

	.product-info .in-stock {
		top: 0 !important;
	}
	.product-info .product-stars-and-review-inner:before {
		left: 0 !important;
		top: 30px;
	}
	.product-info .product-info-bg .image-container .thumbnail .product-zoom {
		top: 5px !important;
		left: 5px !important;
	}
	#product .custom-product-meta-item .quantity.clearfix.quantity {
		margin: 24px 0 0 !important;
	}
	.product-info .product-info-bg [class*="col-"]:first-child table {
		display: none !important;
	}
	.product-info .title-product,
	.breadcrumbs .container .breadcrumb {
		padding-left: 15px !important;
		text-align: left !important;
	}

	.custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(1), .custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(3) {
		width: 50% !important;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: 7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -20px !important;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-prev,
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		top: calc(50% - 33px) !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		right: -8px !important;
	}

	.custom-subcat .list-inline.subcat .subcat-item a {
		height: 175px !important;
		position: relative !important;
		overflow: hidden !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item img {
		height: 100px !important;
		max-width: 100% !important;
		width: 100% !important;
		display: block !important;
		object-fit: contain !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item {
		margin: 15px;
		width: 19.333%;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		height: auto !important;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: -7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -7px !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		height: auto;
	}
	.product-info .product-stars-and-review-inner:before {
		left: 0 !important;
		top: 30px;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.page-product.row-offcanvas:before {
		display: none;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 0;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (max-width: 600px) {
	.product-filter__item:last-child .btn-group {
		display: none;
	}
	.product-filter + .product-grid .custom-catalog .product-block {
		width: 45%;
	}
	.custom-subcat {
		left: 0;
	}
	#product {
		width: 100% !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .product-col-wrap,
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		width: 44% !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns {
		width: 33% !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__items .title-link span {
		padding: 15px !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		margin: 0 0 220px;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .btn-group .btn-book-recipes span:before {
		left: 15px;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container {
		-webkit-flex-direction: row;
		flex-direction: row;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column,
	#product .custom-product-meta-item {
		width: 100% !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .btn-group .btn-book-recipes span {
		font-size: 12px;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-text, .tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		margin: 0 0 10px;
		font-size: 16px;
		line-height: 16px;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-text {
		font-size: 12px;
	}
	#product .product-prices ul li .list-prices .price-new,
	#product .product-prices ul li .list-prices .price-old {
		font-size: 18px;
	}
	#product .custom-product-meta-item:first-child .quantity .quantity-item {
		width: 100px;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .product-col-wrap {
		width: auto !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .product-col-wrap {
		width: auto !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid {
		position: relative !important;
		left: -2px !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		width: auto !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		padding: 0 !important;
		position: relative !important;
		left: -2px;
	}

	/* Todo: Begin: Принадлежности и насадки */
	.custom-accessories .container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-content: center;
	}
	.custom-accessories .custom-accessories-inner {
		width: 90.221%;
		padding-bottom: 120px;
		display: flex;
	}
	.custom-accessories .custom-accessories-inner:after {
		display: none;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(1) {
		order: 1;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(2) {
		width: 100%;
		order: 0;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		display: none;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(3) {
		order: 2;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(1),
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(3) {
		width: 50%;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-center .btn-group {
		position: absolute;
		bottom: 0;
		z-index: 1;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(2) {
		width: 100% !important;
		order: 0 !important;
	}
	/* Todo: End: Принадлежности и насадки */
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(1) {
		order: 0 !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(2) {
		order: -1 !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(3) {
		order: 2 !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(4) {
		order: 3 !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(5) {
		order: 6 !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(6) {
		order: 5 !important;
	}
	.custom-instructions:after {
		transform: scale(0.7) !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"], .tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"], .mod-text-photo.mod-text-photo--text-boxed-on-right.container.container_v1 .row [class*="col-"], .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] {
		width: auto !important;
		float: none !important;
		clear: left !important;
	}
	.mod-contents.mod-contents--top-spacing.mod-contents--3-images.container.container_v2_slick {
		margin: 0 !important;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}

	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}

	.product-info .in-stock {
		top: 30px !important;
	}
	.product-info .product-stars-and-review-inner:before {
		left: 0 !important;
		top: 30px;
	}
	.product-info .product-info-bg .image-container .thumbnail .product-zoom {
		top: 5px !important;
		left: 5px !important;
	}
	#product .custom-product-meta-item .quantity.clearfix.quantity {
		margin: 24px 0 0 !important;
	}

	.product-info .product-info-bg [class*="col-"]:first-child table {
		display: block !important;
	}
	.product-info .title-product,
	.breadcrumbs .container .breadcrumb {
		padding-left: 15px !important;
		text-align: left !important;
	}

	.custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(1), .custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(3) {
		width: 50% !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: -7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -7px !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		height: auto;
	}
	.product-info .product-info-bg .image-container .thumbnail a img {
		height: auto !important;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.product-info .product-info-bg .image-container .thumbnail {
		height: auto;
	}

	/* Todo: cart top */
	.product-info .product-info-bg [class*="col-"]:last-child, .product-info .product-info-bg [class*="col-"]:first-child {
		width: 100% !important;
		float: left !important;
	}
	#product .custom-product-meta-item:first-child {
		-webkit-flex-direction: row !important;
		flex-direction: row !important;
	}
	#product .custom-product-meta-item:first-child .product-prices {
		margin: 0 60px 10px 0 !important;
	}
	.product-info .product-info-bg [class*="col-"]:first-child table {
		display: none !important;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}

	.product-info .cart-btn .cart {
		min-width: 225px !important;
	}
	.product-info .cart-btn .cart .btn-cart {
		height: 60px !important;
		max-width: 100% !important;
	}
	.product-info .cart-btn .cart .btn-cart:before {
		content: '';
		height: 60px;
		width: 50%;
		border-bottom: 58px solid #c7163d;
		border-left: 30px solid transparent;
		border-right: 0 solid transparent;
		position: absolute;
		right: 0;
		top: -2px;
	}
	.product-info .cart-btn .compare .btn {
		padding: 16px !important;
		height: 60px !important;
		width: 55px !important;
		flex-direction: column !important;
		display: inline-flex !important;
		align-items: center !important;
		align-content: center !important;
		justify-content: center !important;
	}

	.product-info .in-stock {
		top: 0 !important;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (min-width: 568px) and (max-width: 600px) {
	/* Todo: Slick Slidrt Owl */

	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item .mod-content-item__media, .tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item .mod-content-item__media {
		height: 200px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__title {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		margin: 8px !important;
		height: 100%;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}

	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 0;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}

	/* Todo: faq fancybox owlcarousel */
	.custom-faq .mod-accessories__row.mod-accessories__row--header {
		justify-content: center;
		flex-direction: row;
		width: 100%;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image {
		display: flex;
	}
	.mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image .mod-accessories__image-wrp .mod-accessories__image {
		height: 100%;
		object-fit: cover;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root {
		height: auto;
		width: 100%;
		display: block;
		flex-direction: inherit;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:first-child,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:last-child {
		padding: 0;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item {
		margin: 0 0 15px;
		width: 48%;
		display: inline-block;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:nth-child(even) {
		float: right;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item .mod-accessories__placeholder {
		width: 100%;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image {
		width: 50%;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		max-width: 100% !important;
	}

	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-close {
		top: 0;
		right: 0;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		left: 0 !important;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin {
		padding: 15px !important;
		width: 480px !important;
		left: 50%;
		transform: translateX(-50%);
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title .mod-accessories__title {
		font-size: 20px;
	}
}

@media screen and (max-width: 568px) {
	.product-filter__item:last-child .btn-group {
		display: none;
	}
	.product-filter__item [for='input-limit'],
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'] {
		margin: 0 0 4px;
	}
	.product-filter__item,
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'],
	.product-filter__item .sort .selectric-wrapper,
	.product-filter__item:nth-of-type(2),
	.product-filter__item [for='input-limit'],
	.product-filter__item:nth-of-type(2) .selectric-wrapper {
		width: 100%;
	}
	.h1_container h1,
	.custom-viewed-box-heading h3 {
		text-align: center;
	}
	.custom-bottom:before {
		height: 90px;
		width: 90px;
		left: -33px;
	}
	.custom-catalog .product-block .top .rating {
		height: 15px;
	}
	.custom-catalog .product-block .top .name {
		margin: 10px 0 4px;
	}
	.custom-catalog .product-block:nth-child(2n) {
		margin: 15px 15px 15px 0 !important;
	}

	.refine-search ul li {
		width: calc((100% / 4) - 20px) !important;
	}
	.product-filter + .product-grid .custom-catalog .product-block:nth-child(odd) {
		margin: 15px 15px 15px 0 !important;
	}
	.product-filter + .product-grid .custom-catalog .product-block:nth-child(even) {
		margin: 15px 0 15px 15px !important;
	}
	.custom-bottom .panel-body {
		position: relative;
		left: 0;
	}
	/* Todo: Begin cart product */
	.product-info .product-info-bg .image-container {
		margin: 0 0 30px;
	}
	#product .custom-product-meta-item:first-child .quantity,
	#product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice {
		width: 100%;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
	}
	#product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul {
		width: auto;
		display: inline-block;
	}
	.product-info .cart-btn {
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
	}
	.product-info .product-article {
		text-align: center;
	}
	.product-info .product-stars-and-review-inner .product-stars {
		margin: 0 0 30px;
	}
	.product-info .product-stars-and-review-inner,
	.page-navigation__item,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container,
		/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row,
	.product-grid,
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		-webkit-flex-direction: column;
		flex-direction: column;
	}

	.page-navigation__item {
		-webkit-flex-direction: row;
		flex-direction: row;
	}
	.page-navigation__item li {
		margin: 0 15px 0 0;
	}
	.page-navigation__item li a {
		font-size: 12px;
		line-height: 12px;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column,
	#tab-description img,
		/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"]:last-child .mod-content-item__container .mod-content.mod-content-item__content,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row [class*="col-"]:last-child .mod-content-item__container,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:first-child .mod-text-photo__column.mod-text-photo__column--content,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-left.mod-text-photo--half-height .row [class*="col-"]:first-child .mod-text-photo__column.mod-text-photo__column--content {
		width: 100%;
		z-index: 2;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:first-child {
		padding: 0 15px;
	}
	/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"]:last-child .mod-content-item__container .mod-content.mod-content-item__content {
		padding: 30px 0;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:first-child .mod-text-photo__column.mod-text-photo__column--content:after, .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-left.mod-text-photo--half-height .row [class*="col-"]:first-child .mod-text-photo__column.mod-text-photo__column--content:after, .tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(1) .mod-content-item.row:after, .tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row:before {
		height: 100px !important;
		width: 25% !important;
	}
	.custom-related-products .desc-pane .products-block .product-col-wrap {
		margin: 0 0 15px;
	}
	#product .custom-product-meta-item,
	.custom-accessories .custom-accessories-inner .custom-accessories-columns,
	.tab-characteristics > div .desc-pane .table thead tr td .td-item,
	.custom-related-products .desc-pane .products-block .product-col-wrap {
		width: 100% !important;
	}
	#product .custom-product-meta-item:first-child,
	.com-stripe-title__descr,
	.tab-characteristics > div .desc-pane .table tbody tr td .td-item,
	.tab-characteristics > div .desc-pane .table thead tr td .td-item {
		text-align: left;
	}
	.product-info .product-view {
		padding: 30px 0;
	}
	.breadcrumb {
		display: none;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		margin: 0 0 590px;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center .custom-accessories__items {
		transform: scale(1);
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__items .title-link span {
		line-height: 24px;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-center .btn-group {
		margin: 0 0 45px;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		padding: 0 15px;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		margin: 0 0 30px;
		width: 100%;
	}
	.viewed-box-popular-wrapper .viewed-box-content:before {
		top: -6.5%;
		left: -80px;
		z-index: -1;
	}
	.producttabs .owl-carousel.product-grid.col-nopadding.tcarousel.owl-loaded.owl-drag:before, .viewed-box-popular-wrapper .viewed-box-content:before, .tab-content .tab-description .related-products-wrapp .custom-related-products:before {
		right: -115px;
	}
	.products-block.row.products-row.product-grid {
		padding: 0 15px;
	}
	.product-grid .product-block {
		min-width: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container {
		height: auto !important;
	}
	/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"],
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row [class*="col-"],
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*='col-'],
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-left.mod-text-photo--half-height .row [class*="col-"],
	.product-info .product-info-bg [class*="col-"]:first-child,
	.product-info .product-info-bg [class*="col-"]:last-child {
		width: 100% !important;
		float: none !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .product-col-wrap {
		margin: 13px !important;
		width: auto !important;
	}
	.product-info .product-article {
		text-align: left;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		padding: 0;
	}
	.custom-accessories-heading .custom-accessories-heading__item,
	.custom-accessories-heading .custom-accessories-heading__item span {
		font-size: 14px;
	}
	/* Todo: End cart product */

	/* Todo: Begin second last work */
	.page-navigation__item {
		align-items: center;
		flex-direction: column;
	}
	.page-navigation__item li {
		margin: 0 0 15px !important;
	}
	.tab-content {
		padding: 0;
	}
	.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title {
		margin: 0;
		line-height: 24px;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container .mod-content.mod-content-item__content h3,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container .mod-content.mod-content-item__content {
		text-align: center;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"]:first-child {
		padding: 0 15px !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:last-child .mod-content-item.row,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.container .row,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-left.mod-text-photo--half-height .row {
		-webkit-flex-direction: column !important;
		flex-direction: column !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.container.container_v3_ch .row [class*="col-"]:last-child,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(2) .mod-content-item.row [class*="col-"]:first-child,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:last-child {
		padding: 0 15px !important;
	}
	.custom-instructions:after {
		transform: scale(0.5) !important;
	}
	.tab-characteristics > div .desc-pane .container {
		overflow: auto !important;
	}
	.tab-characteristics > div .desc-pane .table {
		min-width: 100% !important;
	}
	.tab-characteristics > div .desc-pane .table thead tr td .td-item,
	.tab-characteristics > div .desc-pane .table tbody tr td .td-item {
		text-align: left !important;
	}
	.mod-content__title,
	.mod-content__content, .mod-content__content p {
		text-align: center !important;
	}
	.tab-description .desc-pane [id] .mod-slideshow ul .mod-slideshow__item.slick-slide {
		height: auto !important;
	}
	.custom-accessories-columns.custom-accessories-left .custom-accessories__items:last-child,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column {
		margin-bottom: 17px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status:before {
		display: none !important;
	}
	.tab-content .tab-description .related-products-wrapp .container,
	.viewed-box.viewed-box-popular-wrapper .container {
		width: 100% !important;
	}
	.breadcrumbs .container .breadcrumb .width,
	.breadcrumbs .container .breadcrumb .width a {
		width: auto !important;
		display: inline-block !important;
	}
	.breadcrumb > li,
	.breadcrumbs .container .breadcrumb .width span,
	.breadcrumbs .breadcrumb li:first-child [itemprop="name"] {
		font-size: 11px !important;
	}
	.product-info .in-stock {
		right: 15px !important;
		top: 65px !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__media-wrapper .mod-video__media {
		height: 300px !important;
	}
	.mod-text-photo.mod-text-photo--text-boxed-on-right.container.container_v1,
	.tab-description .desc-pane [id] .mod-video.mod-video--half-width.mod-video--dark.mod-video--half-width---text-on-right.container,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(2),
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(4),
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(6),
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(2),
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(5) {
		padding: 0 15px !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(4) {
		order: inherit !important;
	}
	/* Todo: End second last work */

	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item .mod-content-item__media {
		height: 250px !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .btn-group .btn-book-recipes span:before {
		left: -45px !important;
	}

	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider,
	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider .row,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"],
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"],
	.mod-text-photo.mod-text-photo--text-boxed-on-right.container.container_v1 .row [class*="col-"],
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item {
		margin: 0 !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"],
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] {
		height: auto !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		margin: 0 0 30px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: 115px !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"] {
		width: 100% !important;
	}
	.mod-text-photo.mod-text-photo--text-boxed-on-right.container.container_v1, .tab-description .desc-pane [id] .mod-video.mod-video--half-width.mod-video--dark.mod-video--half-width---text-on-right.container, .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(2), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(4), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(6), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(2), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(5),
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .title-link {
		padding: 0 !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container, .mod-text-photo.mod-text-photo--text-boxed-on-right.container.container_v1 {
		padding: 0 15px !important;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}

	#product .product-prices ul li .list-prices .price-old {
		margin: 0 15px 0 0 !important;
	}
	#product .custom-product-meta-item:first-child {
		-webkit-flex-direction: row !important;
		flex-direction: row !important;
		-webkit-align-items: flex-start !important;
		align-items: flex-start !important;
		-webkit-align-content: flex-start !important;
		align-content: flex-start !important;
		-webkit-justify-content: flex-start !important;
		justify-content: flex-start !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn {
		margin: 0 0 0 8% !important;
		display: inline-flex !important;
		width: auto !important;
		justify-content: flex-end !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice {
		width: auto !important;
		display: inline-flex !important;
	}
	.product-info .quantity {
		display: flex !important;
		justify-content: flex-start !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item {
		display: inline-flex !important;
		position: relative !important;
		top: -85px !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li {
		margin: 0 0 8px !important;
		text-align: left !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li a,
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li .button-credit {
		text-align: left !important;
	}
	.product-info .cart-btn .cart {
		min-width: 225px !important;
	}
	.product-info .cart-btn .cart .btn-cart {
		height: 60px !important;
		max-width: 100% !important;
	}
	.product-info .cart-btn .cart .btn-cart:before {
		content: '';
		height: 60px;
		width: 50%;
		border-bottom: 58px solid #c7163d;
		border-left: 30px solid transparent;
		border-right: 0 solid transparent;
		position: absolute;
		right: 0;
		top: -2px;
	}
	.product-info .cart-btn .compare .btn {
		padding: 16px !important;
		height: 60px !important;
		width: 55px !important;
		flex-direction: column !important;
		display: inline-flex !important;
		align-items: center !important;
		align-content: center !important;
		justify-content: center !important;
	}
	#product .custom-product-meta-item:first-child .product-prices {
		margin: 0 0 10px !important;
		width: auto !important;
	}
	.product-markdown-view #product .custom-product-meta-item:first-child .product-prices {
		margin: 0 !important;
	}
	#product .product-prices ul li .list-prices .price-new, #product .product-prices ul li .list-prices .price-old {
		font-size: 22px !important;
		line-height: 22px !important;
	}
	.product-info .product-info-bg [class*="col-"]:first-child {
		padding: 0 !important;
	}
	.product-info .product-article {
		text-align: left !important;
	}
	.product-info .product-stars-and-review-inner {
		align-items: center !important;
		align-content: flex-start !important;
		justify-content: flex-start !important;
		-webkit-flex-direction: row !important;
		flex-direction: row !important;
	}
	.product-info .product-stars-and-review-inner .product-review,
	.product-info .product-stars-and-review-inner .product-stars {
		margin: 0 !important;
		width: auto !important;
		display: inline-block !important;
	}
	.product-info .product-stars-and-review-inner .product-stars,
	.product-info .product-stars-and-review-inner .product-review .product-review-title,
	.product-info .product-description {
		margin: 0 !important;
	}
	.product-info .product-stars-and-review-inner .product-stars {
		margin: 0 8px 0 0 !important;
	}
	.product-info .product-stars-and-review-inner .product-review {
		width: 70% !important;
		display: inline-flex !important;
		justify-content: flex-start !important;
		flex-direction: row !important;
	}
	.product-info .product-stars-and-review-inner .product-review .product-review-title,
	.product-info .product-stars-and-review-inner .product-review .product-review-btn {
		width: auto !important;
	}
	.product-info .product-stars-and-review-inner .product-review .product-review-title {
		margin: 0 8px 0 0 !important;
	}
	.product-info .product-description .product-model,
	.product-info .product-description .product-manufacture,
	.product-info .product-description .product-manufacturer,
	.product-info .product-description .product-guarantee,
	.product-info .product-description .product-manufacturer-country {
		-webkit-justify-content: flex-start !important;
		justify-content: flex-start !important;
	}
	.product-info .product-colors {
		display: flex !important;
		justify-content: flex-start !important;
	}
	.product-info .product-stars-and-review-inner:before {
		bottom: -7px !important;
	}
	#product .custom-product-meta-item:first-child .product-prices {
		justify-content: flex-start !important;
	}
	#product .product-prices ul li .list-prices .price-old {
		margin: 0 40px 0 0 !important;
	}
	#product .custom-product-meta-item:first-child {
		margin: 0 0 15px !important;
	}
	.product-markdown-view #product .custom-product-meta-item:first-child {
		margin: 0 !important;
	}
	.page-navigation__item {
		padding: 30px 0 !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content {
		padding: 0 !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content:before {
		display: none !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content .mod-content__title {
		text-transform: none !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(2), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(4), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(6), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(2), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(5) {
		padding: 0 15px !important;
	}
	.mod-content__title, .mod-content__content, .mod-content__content p,
	.mod-text-photo__notes, .tab-description .desc-pane .com-stripe-title__descr.container {
		text-align: left !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: 115px !important;
		text-align: center !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .custom-accessories__items {
		margin: 0 !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:first-child .custom-accessories__items .images {
		border-left: none !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:last-child .custom-accessories__items .images {
		border-right: none !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		display: inline-flex !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center .custom-accessories__items {
		margin: 0 !important;
		padding: 0 15px !important;
	}
	.custom-accessories-columns.custom-accessories-center .custom-accessories__center .custom-accessories__items .images {
		width: 100% !important;
		position: initial !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products:before,
	.viewed-box-popular-wrapper .viewed-box-content:before {
		display: none !important;
	}
	.breadcrumbs .breadcrumb {
		padding: 0 !important;
		display: inline-block !important;
	}
	.product-info .in-stock {
		position: relative !important;
		right: -55% !important;
		left: initial !important;
		top: 90px !important;
		z-index: 2 !important;
	}
	.product-info .title-product {
		margin: 0 0 30px !important;
		padding: 0 !important;
	}
	.page-navigation__item li {
		margin: 0 15px 0 0 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status {
		margin: 0 0 50px !important;
	}
	#product .custom-product-meta-item:first-child .product-prices,
	#product .custom-product-meta-item:first-child .custom-product-meta-btn {
		width: auto !important;
	}
	#product .custom-product-meta-item:last-child {
		text-align: left !important;
	}
	.product-info .product-stars-and-review-inner:before {
		left: 5% !important;
	}
	.custom-accessories .container {
		padding: 0 !important;
	}
	.custom-accessories .custom-accessories-inner {
		width: 100% !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		justify-content: center !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .product-col-wrap {
		width: auto !important;
	}
	.product-grid .product-block .bottom, .products-owl-carousel .product-block .bottom {
		justify-content: center !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev {
		top: 25% !important;
		left: 10px !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 22% !important;
		right: 10px !important;
	}
	.tab-content .tab-description .related-products-wrapp .container {
		padding: 0 !important;
	}
	.custom-related-products .desc-header-wrapper {
		margin: 0 0 -30px !important
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item {
		top: 0 !important;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: 7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -20px !important;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-prev,
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		top: calc(50% - 33px) !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		right: -8px !important;
	}

	.custom-subcat .list-inline.subcat .subcat-item a {
		height: 175px !important;
		position: relative !important;
		overflow: hidden !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item img {
		height: 100px !important;
		max-width: 100% !important;
		width: 100% !important;
		display: block !important;
		object-fit: contain !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item {
		margin: 15px;
		width: 18.333%;
	}
	.products-block.col-nopadding.custom-catalog {
		margin: 0 !important;
	}
	.product-filter__item:last-child .btn-group {
		display: none !important;
	}

	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev,
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 47% !important;
		transform: translateY(-47%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-47%) scale(-1, 1) !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		height: auto !important;
	}

	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 50% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-50%) scale(-1, 1) !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid {
		position: relative !important;
		left: 0 !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .owl-stage {
		left: 0 !important;
	}

	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item {
		margin: 0 0 30px !important;
		height: 450px;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"], .tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] {
		width: 98%;
	}
	.product-info .product-stars-and-review-inner:before {
		left: 0 !important;
		top: 30px;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}

	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item {
		width: 100% !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 100px;
		width: 100%;
	}
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item img {
		height: auto !important;
		width: 100% !important;
		display: block !important;
	}
	.page-navigation__item {
		align-items: center;
		flex-direction: column;
	}
	.page-navigation__item li {
		margin: 0 0 15px !important;
	}

	/* Todo: Slick Slidrt Owl */

	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item .mod-content-item__media, .tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item .mod-content-item__media {
		height: 200px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__title {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		margin: 8px !important;
		height: 100%;
	}

	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}

	.product-info .product-info-bg .image-container .image-additional .imgthumbs {
		padding: 0 15px !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 78px;
		width: 78px !important;
	}

	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (min-width: 502px) and (max-width: 546px) {
	.product-filter + .product-grid .custom-catalog .product-block {
		width: 46%;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		height: auto !important;
	}
	.product-info .product-stars-and-review-inner:before {
		left: 0 !important;
		top: 30px;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}

	.page-navigation__item {
		align-items: center;
		flex-direction: column;
	}
	.page-navigation__item li {
		margin: 0 0 15px !important;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (min-width: 500px) and (max-width: 501px) {
	.custom-catalog .product-block, .product-filter + .product-grid .custom-catalog .product-block {
		width: 46%;
	}
	.custom-subcat .list-inline.subcat .subcat-item {
		margin: 15px;
		width: 39%;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		height: auto !important;
	}
	.product-info .product-stars-and-review-inner:before {
		left: 0 !important;
		top: 30px;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}

	.page-navigation__item {
		align-items: center;
		flex-direction: column;
	}
	.page-navigation__item li {
		margin: 0 0 15px !important;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (min-width: 481px) and (max-width: 568px) {
	.product-info .in-stock {
		position: absolute !important;
		right: 0 !important;
		top: 0 !important;
		z-index: 2 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		height: auto !important;
	}
	.product-info .product-stars-and-review-inner:before {
		left: 0 !important;
		top: 30px;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}

	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item {
		width: 100% !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 60px;
		width: 100%;
	}
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item img {
		height: auto !important;
		width: 100% !important;
		display: block !important;
	}

	.page-navigation__item {
		align-items: center;
		flex-direction: column;
	}
	.page-navigation__item li {
		margin: 0 0 15px !important;
	}

	/* Todo: Slick Slidrt Owl */

	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item .mod-content-item__media, .tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item .mod-content-item__media {
		height: 200px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__title {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		margin: 8px !important;
		height: 100%;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (max-width: 450px) {
	#product {
		.custom-reservation-block {
			.advantages_block {
				display: none;
			}
		}
	}
	.search_block form.search-form_rc.is-active input {
		right: -80px;
		top: 40px;
	}
}

@media screen and (min-width: 320px) and (max-width: 479px) {
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row:after, .tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row:before {
		height: auto !important;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: 7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -20px !important;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-prev,
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		top: calc(50% - 33px) !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		right: -8px !important;
	}

	.custom-subcat .list-inline.subcat .subcat-item a {
		height: 175px !important;
		position: relative !important;
		overflow: hidden !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item img {
		height: 100px !important;
		max-width: 100% !important;
		width: 100% !important;
		display: block !important;
		object-fit: contain !important;
	}
	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider {
		height: 450px !important;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (min-width: 415px) and (max-width: 479px) {
	.custom-subcat .list-inline.subcat .subcat-item {
		margin: 15px;
		width: 40%;
	}
	.custom-catalog .product-block {
		width: 100% !important;
	}
	.product-filter + .product-grid .custom-catalog .product-block:nth-child(odd) {
		margin: 15px !important;
	}
	.product-filter + .product-grid .custom-catalog .product-block:nth-child(even) {
		margin: 15px !important;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	.product-info .in-stock {
		position: absolute !important;
		right: 0 !important;
		top: 0 !important;
		z-index: 2 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row:after, .tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row:before {
		height: auto !important;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: 7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -20px !important;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-prev,
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		top: calc(50% - 33px) !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		right: -8px !important;
	}

	.custom-subcat .list-inline.subcat .subcat-item a {
		height: 175px !important;
		position: relative !important;
		overflow: hidden !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item img {
		height: 100px !important;
		max-width: 100% !important;
		width: 100% !important;
		display: block !important;
		object-fit: contain !important;
	}
	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider {
		height: 450px !important;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.custom-bottom .custom-producttabs .panel-body .box-products .owl-stage-outer .owl-stage .owl-item .product-block .bottom .cart .btn {
		max-width: 235px !important;
	}
	.custom-bottom .custom-producttabs .panel-body .box-products .owl-stage-outer .owl-stage .owl-item .product-block .bottom .cart .btn:hover:before,
	.custom-catalog .product-block .cart .btn:hover:before {
		border-bottom: 44px solid #ab0f32 !important;
	}

	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 50% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-50%) scale(-1, 1) !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid {
		position: relative !important;
		left: 0 !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .owl-stage {
		left: 0 !important;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		margin: 8px !important;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (max-width: 415px) {

	.product-filter__item:last-child .btn-group {
		display: none !important;
	}
	.product-filter__item [for='input-limit'],
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'] {
		margin: 0 0 4px;
	}
	.product-filter__item,
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'],
	.product-filter__item .sort .selectric-wrapper,
	.product-filter__item:nth-of-type(2),
	.product-filter__item [for='input-limit'],
	.product-filter__item:nth-of-type(2) .selectric-wrapper {
		width: 100%;
	}
	.h1_container h1,
	.custom-viewed-box-heading h3 {
		text-align: center;
	}
	.custom-bottom:before {
		height: 90px;
		width: 90px;
		left: -33px;
	}
	.custom-catalog .product-block .top .rating {
		height: 15px;
	}
	.custom-catalog .product-block .top .name {
		margin: 10px 0 4px;
	}
	.custom-catalog .product-block,
	.custom-catalog .product-block:nth-child(2n),
	.product-filter + .product-grid .custom-catalog .product-block,
	.product-filter + .product-grid .custom-catalog .product-block:nth-child(2n),
	.product-filter + .product-grid .custom-catalog .product-block:nth-child(odd) {
		margin: 15px !important;
		width: 100%;
	}

	.refine-search ul li {
		width: calc((100% / 2) - 20px) !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item {
		margin: 15px;
		width: 40%;
	}

	/* Todo: Begin cart product */
	#product .custom-product-meta-item:first-child .quantity,
	#product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice {
		width: 100%;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
	}
	#product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul {
		width: auto;
		display: inline-block;
	}
	#product .product-prices ul li .list-prices,
	.product-info .cart-btn {
		-webkit-justify-content: center;
		justify-content: center;
	}
	.product-info .product-article {
		text-align: center;
	}
	.product-info .product-stars-and-review-inner .product-stars {
		margin: 0 0 30px;
	}
	#product,
	.product-info .product-stars-and-review-inner,
	.page-navigation__item,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container,

		/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row,
	.product-grid,
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		-webkit-flex-direction: column;
		flex-direction: column;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column,
	#tab-description img,

		/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"]:last-child .mod-content-item__container .mod-content.mod-content-item__content,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row [class*="col-"]:last-child .mod-content-item__container,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:first-child .mod-text-photo__column.mod-text-photo__column--content,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-left.mod-text-photo--half-height .row [class*="col-"]:first-child .mod-text-photo__column.mod-text-photo__column--content {
		width: 100%;
		z-index: 2;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:first-child {
		padding: 0 15px;
	}
	/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"]:last-child .mod-content-item__container .mod-content.mod-content-item__content {
		padding: 30px 0;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:first-child .mod-text-photo__column.mod-text-photo__column--content:after, .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-left.mod-text-photo--half-height .row [class*="col-"]:first-child .mod-text-photo__column.mod-text-photo__column--content:after, .tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(1) .mod-content-item.row:after, .tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row:before {
		height: 125px !important;
		width: 50% !important;
	}
	#product .custom-product-meta-item,
	.custom-accessories .custom-accessories-inner .custom-accessories-columns,
	.tab-characteristics > div .desc-pane .table thead tr td .td-item {
		width: 100%;
	}
	#product .custom-product-meta-item:first-child,
	.com-stripe-title__descr,
	.tab-characteristics > div .desc-pane .table tbody tr td .td-item,
	.tab-characteristics > div .desc-pane .table thead tr td .td-item {
		text-align: center;
	}
	.product-markdown-view {
		.cheaper-item, .cheaper-products {
			&__reason {
				text-align: left;
			}
		}
	}
	.product-info .product-view {
		padding: 30px 0;
	}
	.breadcrumb {
		display: none;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		margin: 0 0 400px;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center .custom-accessories__items {
		transform: scale(1);
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__items .title-link span {
		line-height: 24px;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-center .btn-group {
		margin: 0 0 45px;
	}
	.custom-related-products .desc-pane .products-block .product-col-wrap,
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		margin: 0 auto !important;
		width: 100% !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content:before {
		top: -6.5%;
		left: -80px;
		z-index: -1;
	}
	.producttabs .owl-carousel.product-grid.col-nopadding.tcarousel.owl-loaded.owl-drag:before, .viewed-box-popular-wrapper .viewed-box-content:before, .tab-content .tab-description .related-products-wrapp .custom-related-products:before {
		right: -115px;
	}
	.product-info .product-info-bg [class*="col-"]:first-child,
	.product-info .product-info-bg [class*="col-"]:last-child,

		/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"],
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row [class*="col-"],
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:first-child,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*='col-'],
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-left.mod-text-photo--half-height .row [class*="col-"],
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .product-col-wrap,
	.product-info .product-description {
		width: 100% !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"],
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row [class*="col-"] {
		padding: 0 15px;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .product-col-wrap {
		margin: 15px !important;
	}
	.product-info .product-stars-and-review-inner {
		-webkit-justify-content: center !important;
		justify-content: center !important;
		-webkit-align-items: center !important;
		align-items: center !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container {
		height: auto !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		padding: 0 15px !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .product-col-wrap, .viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		width: 100% !important;
	}
	/* Todo: End cart product */

	/* Todo: Begin second last work */
	.page-navigation__item {
		align-items: center;
	}
	.page-navigation__item li {
		margin: 0;
	}
	.tab-content {
		padding: 0;
	}
	.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title {
		margin: 0;
		line-height: 24px;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container .mod-content.mod-content-item__content h3,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container .mod-content.mod-content-item__content {
		text-align: center;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"]:first-child {
		padding: 0 15px !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:last-child .mod-content-item.row,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.container .row,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-left.mod-text-photo--half-height .row {
		-webkit-flex-direction: column !important;
		flex-direction: column !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.container.container_v3_ch .row [class*="col-"]:last-child,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(2) .mod-content-item.row [class*="col-"]:first-child,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:last-child {
		padding: 0 15px !important;
	}
	.custom-instructions:after {
		transform: scale(0.5) !important;
	}
	.tab-characteristics > div .desc-pane .container {
		overflow: auto !important;
	}
	.tab-characteristics > div .desc-pane .table {
		min-width: 560px !important;
	}
	.tab-characteristics > div .desc-pane .table thead tr td .td-item,
	.tab-characteristics > div .desc-pane .table tbody tr td .td-item {
		text-align: left !important;
	}
	.mod-content__title,
	.mod-content__content, .mod-content__content p {
		text-align: center !important;
	}
	.tab-description .desc-pane [id] .mod-slideshow ul .mod-slideshow__item.slick-slide {
		height: auto !important;
	}
	.custom-accessories-columns.custom-accessories-left .custom-accessories__items:last-child,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column {
		margin-bottom: 17px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status:before,
	.breadcrumb {
		display: none !important;
	}
	.product-info .in-stock {
		right: 15px !important;
		top: 80px !important;
	}
	.product-info .quantity {
		display: flex !important;
		justify-content: center !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item {
		display: inline-flex !important;
	}

	.product-info .product-description .product-model,
	.product-info .product-description .product-manufacturer,
	.product-info .product-description .product-guarantee,
	.product-info .product-description .product-manufacturer-country,
	#product,
	#product .product-prices ul li .list-prices,
	#product .custom-product-meta-item:last-child .custom-product-meta-btn {
		-webkit-justify-content: center !important;
		justify-content: center !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__media-wrapper .mod-video__media {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__media-wrapper .mod-video__media img {
		top: 0 !important;
	}

	#product .custom-product-meta-item:first-child {
		align-items: center !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn {
		display: inline-flex !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(1), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(2), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(4), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(5),
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"] {
		width: 100% !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item .mod-content-item__media, .tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item .mod-content-item__media {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		margin: 0 !important;
	}
	/* Todo: End second last work */

	.custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(1), .custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(3) {
		width: 100% !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(2),
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(4),
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(6),
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(2),
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(5) {
		padding: 0 !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container, .mod-text-photo.mod-text-photo--text-boxed-on-right.container.container_v1 {
		padding: 0 15px !important;
	}
	.mod-contents.mod-contents--3-images.container.container_v2_slick {
		padding-top: 0 !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		padding-bottom: 0 !important;
		margin-bottom: 0 !important;
	}

	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider,
	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider .row,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"],
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"],
	.mod-text-photo.mod-text-photo--text-boxed-on-right.container.container_v1 .row [class*="col-"],
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item {
		margin: 0 !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"],
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] {
		height: 400px !important;
	}
	.product-info .product-info-bg .image-container .custom-image-container {
		margin: 0 0 30px !important;
	}

	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	#product .product-prices ul li .list-prices .price-old {
		margin: 0 15px 0 0 !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child {
		padding: 0 !important;
	}
	#product .custom-product-meta-item:first-child {
		-webkit-flex-direction: column !important;
		flex-direction: column !important;
		-webkit-align-items: flex-start !important;
		align-items: flex-start !important;
		-webkit-align-content: flex-start !important;
		align-content: flex-start !important;
		-webkit-justify-content: flex-start !important;
		justify-content: flex-start !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn {
		padding: 0 30px 0 0 !important;
		display: inline-flex !important;
		width: 100% !important;
		justify-content: flex-end !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice {
		width: auto !important;
		display: inline-flex !important;
	}
	.product-info .quantity {
		display: flex !important;
		justify-content: flex-start !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item {
		display: inline-flex !important;
		position: relative !important;
		top: -120px !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li {
		margin: 0 0 8px !important;
		text-align: left !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li a,
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice ul li .button-credit {
		text-align: left !important;
	}
	.product-info .cart-btn .cart {
		min-width: 225px !important;
	}
	.product-info .cart-btn .cart .btn-cart {
		height: 60px !important;
		max-width: 100% !important;
	}
	.product-info .cart-btn .cart .btn-cart:before {
		content: '';
		height: 60px;
		width: 50%;
		border-bottom: 58px solid #c7163d;
		border-left: 30px solid transparent;
		border-right: 0 solid transparent;
		position: absolute;
		right: 0;
		top: -2px;
	}
	.product-info .cart-btn .compare .btn {
		padding: 16px !important;
		height: 60px !important;
		width: 55px !important;
		flex-direction: column !important;
		display: inline-flex !important;
		align-items: center !important;
		align-content: center !important;
		justify-content: center !important;
	}
	#product .product-prices ul li .list-prices .price-new, #product .product-prices ul li .list-prices .price-old {
		font-size: 22px !important;
		line-height: 22px !important;
	}
	.product-info .product-info-bg [class*="col-"]:first-child {
		padding: 0 !important;
	}
	.product-info .product-article {
		text-align: left !important;
	}
	.product-info .product-stars-and-review-inner {
		align-items: center !important;
		align-content: flex-start !important;
		justify-content: flex-start !important;
		-webkit-flex-direction: row !important;
		flex-direction: row !important;
	}
	.product-info .product-stars-and-review-inner .product-review,
	.product-info .product-stars-and-review-inner .product-stars {
		margin: 0 !important;
		width: auto !important;
		display: inline-block !important;
	}
	.product-info .product-stars-and-review-inner .product-stars,
	.product-info .product-stars-and-review-inner .product-review .product-review-title,
	.product-info .product-description {
		margin: 0 !important;
	}
	.product-info .product-stars-and-review-inner .product-stars {
		margin: 0 8px 0 0 !important;
		width: 35% !important;
	}
	.product-info .product-stars-and-review-inner .product-review {
		width: 75% !important;
		display: inline-flex !important;
		justify-content: flex-start !important;
		flex-direction: row !important;
	}
	.product-info .product-stars-and-review-inner .product-review .product-review-title,
	.product-info .product-stars-and-review-inner .product-review .product-review-btn {
		width: 100% !important;
	}
	.product-info .product-stars-and-review-inner .product-review .product-review-title, .product-info .product-stars-and-review-inner .product-review .product-review-btn {
		margin: 0 !important;
		width: auto !important;
	}
	.product-info .product-stars-and-review-inner .product-review .product-review-title {
		margin: 0 8px 0 0 !important;
	}
	.product-info .product-description .product-model,
	.product-info .product-description .product-manufacture,
	.product-info .product-description .product-manufacturer,
	.product-info .product-description .product-guarantee,
	.product-info .product-description .product-manufacturer-country {
		-webkit-justify-content: flex-start !important;
		justify-content: flex-start !important;
	}
	.product-info .product-colors {
		display: flex !important;
		justify-content: flex-start !important;
	}
	.product-info .product-stars-and-review-inner:before {
		bottom: -7px !important;
	}
	#product .product-prices ul li .list-prices .price-old {
		margin: 0 40px 0 0 !important;
	}
	#product .custom-product-meta-item:first-child {
		margin: 0 0 15px !important;
	}
	.page-navigation__item {
		padding: 30px 0 !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content {
		padding: 0 !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content:before {
		display: none !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content .mod-content__title {
		text-transform: none !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(2), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(4), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(6), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(2), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(5) {
		padding: 0 15px !important;
		height: auto;
	}
	.mod-content__title, .mod-content__content, .mod-content__content p,
	.mod-text-photo__notes, .tab-description .desc-pane .com-stripe-title__descr.container {
		text-align: left !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: 115px !important;
		text-align: center !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .custom-accessories__items {
		margin: 0 !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:first-child .custom-accessories__items .images {
		border-left: none !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:last-child .custom-accessories__items .images {
		border-right: none !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		display: inline-flex !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center .custom-accessories__items {
		margin: 0 !important;
		padding: 0 15px !important;
	}
	.custom-accessories-columns.custom-accessories-center .custom-accessories__center .custom-accessories__items .images {
		width: 100% !important;
		position: initial !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products:before,
	.viewed-box-popular-wrapper .viewed-box-content:before {
		display: none !important;
	}
	.custom-accessories-columns.custom-accessories-center .btn-group .btn-book-recipes {
		max-width: 234px !important;
		width: 234px !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .btn-group .btn-book-recipes span {
		font-size: 14px !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn {
		height: 48px !important;
		max-width: 179px !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn:before {
		height: 45px;
		border-bottom: 45px solid #c7163d;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn span {
		font-size: 16px !important;
		top: 5px !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn span:before {
		left: -50px !important;
		top: -5px !important;
		width: 22px !important;
		height: 28px !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .compare .btn {
		padding: 8px !important;
		height: 48px !important;
		width: 44px !important;
	}
	.breadcrumbs .breadcrumb {
		padding: 0 !important;
		display: inline-block !important;
	}
	.product-info .in-stock {
		position: absolute !important;
		right: 0 !important;
		top: 0 !important;
		z-index: 2 !important;
	}
	.product-info .title-product {
		margin: 0 0 30px !important;
		padding: 0 !important;
	}
	.page-navigation__item li {
		margin: 0 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status {
		margin: 0 0 50px !important;
	}
	.custom-accessories .container {
		padding: 0 !important;
	}
	.custom-accessories .custom-accessories-inner {
		width: 100% !important;
	}
	.page-navigation__item li {
		margin: 0 0 15px !important;
	}
	.page-navigation__item li a {
		font-size: 14px !important;
		line-height: 14px !important;
	}
	.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title {
		position: inherit !important;
		text-transform: none !important;
		font-weight: 700 !important;
		font-size: 18px !important;
		text-align: center !important;
	}
	.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title:after {
		display: none !important;
	}
	.mod-content__title {
		text-align: center !important;
	}
	.tab-characteristics > div .desc-pane .table {
		min-width: auto !important;
	}
	.product-info .title-product {
		font-size: 16px !important;
		line-height: 24px !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs {
		margin-bottom: 10px !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev,
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 25% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-25%) scale(-1, 1) !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item {
		margin: 0 15px !important;
		width: 36% !important;
	}
	.breadcrumbs {
		margin: 0 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}
	#product .custom-product-meta-item:first-child .custom-product-meta-btn .cheap_invoice {
		position: relative !important;
		top: -35px !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item {
		top: -100px !important;
	}
	.custom-featured .products-block .product-block {
		margin: 0 !important;
		width: 98%;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row:after, .tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row:before {
		height: auto !important;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: -7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -7px !important;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-prev,
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		top: calc(50% - 33px) !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		right: -8px !important;
	}

	.custom-subcat .list-inline.subcat .subcat-item a {
		height: 175px !important;
		position: relative !important;
		overflow: hidden !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item img {
		height: 100px !important;
		max-width: 100% !important;
		width: 100% !important;
		display: block !important;
		object-fit: contain !important;
	}

	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider {
		height: 550px !important;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.custom-bottom .custom-producttabs .panel-body .box-products .owl-stage-outer .owl-stage .owl-item .product-block .bottom .cart .btn {
		max-width: 235px !important;
	}
	.custom-bottom .custom-producttabs .panel-body .box-products .owl-stage-outer .owl-stage .owl-item .product-block .bottom .cart .btn:hover:before,
	.custom-catalog .product-block .cart .btn:hover:before {
		border-bottom: 44px solid #ab0f32 !important;
	}

	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 50% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-50%) scale(-1, 1) !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid {
		position: relative !important;
		left: 0 !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .owl-stage {
		left: -15px !important;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content) .mod-text-photo__media, .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content) .mod-text-photo__media .mod-text-photo__image-wrapper {
		height: 250px !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}

	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		height: 100%;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}

	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		margin: 8px !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
}

@media screen and (min-width: 320px) and (max-width: 415px) {
	.product-info .in-stock {
		position: absolute !important;
		right: 0 !important;
		top: 0 !important;
		z-index: 2 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row:after, .tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row:before {
		height: auto !important;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: 7px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -20px !important;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-prev,
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		top: calc(50% - 33px) !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		right: -8px !important;
	}

	.custom-subcat .list-inline.subcat .subcat-item a {
		height: 175px !important;
		position: relative !important;
		overflow: hidden !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item img {
		height: 100px !important;
		max-width: 100% !important;
		width: 100% !important;
		display: block !important;
		object-fit: contain !important;
	}
	.product-info .product-info-bg .image-container .thumbnail {
		height: auto;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.custom-bottom .custom-producttabs .panel-body .box-products .owl-stage-outer .owl-stage .owl-item .product-block .bottom .cart .btn {
		max-width: 235px !important;
	}
	.custom-bottom .custom-producttabs .panel-body .box-products .owl-stage-outer .owl-stage .owl-item .product-block .bottom .cart .btn:hover:before,
	.custom-catalog .product-block .cart .btn:hover:before {
		border-bottom: 44px solid #ab0f32 !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 50% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-50%) scale(-1, 1) !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid {
		position: relative !important;
		left: 0 !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .owl-stage {
		left: -15px !important;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}
	.slick-track {
		.mod-content-item {
			picture {
				img {
					max-height: 250px;
				}
			}
		}
	}
}

@media screen and (min-width: 375px) and (max-width: 414px) {
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		margin: 8px !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content) .mod-text-photo__media, .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content) .mod-text-photo__media .mod-text-photo__image-wrapper {
		height: 250px !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}

	.product-info .product-info-bg .image-container .image-additional .imgthumbs {
		padding: 0 15px !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 66px;
		width: 66px !important;
	}
	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		height: 450px;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}

	.product-info .cart-btn .cart .btn-cart span {
		font-size: 21px;
	}
	.product-info .cart-btn .cart .btn-cart span:before {
		height: 36px;
		width: 41px;
		top: -4px;
		left: -54px !important;
	}

	/* Todo: faq fancybox owlcarousel */
	.custom-faq .mod-accessories__row.mod-accessories__row--header {
		justify-content: center;
		flex-direction: column;
		width: 100%;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root {
		height: auto;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:first-child,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:last-child {
		padding: 0;
		width: 100%;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item {
		margin: 0 0 15px;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item .mod-accessories__placeholder {
		width: 100%;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		max-width: 100% !important;
	}

	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-close {
		top: 0;
		right: 0;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		left: 0 !important;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin {
		padding: 0 !important;
	}
}

@media screen and (max-width: 411px) {
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item {
		width: 100% !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 60px;
		width: 100%;
	}
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item img {
		height: auto !important;
		width: 100% !important;
		display: block !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content) .mod-text-photo__media, .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content) .mod-text-photo__media .mod-text-photo__image-wrapper {
		height: 250px !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}

	.product-info .product-info-bg .image-container .image-additional .imgthumbs {
		padding: 0 15px !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 66px;
		width: 66px !important;
	}
	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		height: 100%;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}

	.product-info .cart-btn .cart .btn-cart span {
		font-size: 21px;
	}
	.product-info .cart-btn .cart .btn-cart span:before {
		height: 36px;
		width: 41px;
		top: -4px;
		left: -54px !important;
	}

	/* Todo: faq fancybox owlcarousel */
	.custom-faq .mod-accessories__row.mod-accessories__row--header {
		justify-content: center;
		flex-direction: column;
		width: 100%;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root {
		height: auto;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:first-child,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:last-child {
		padding: 0;
		width: 100%;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item {
		margin: 0 0 15px;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item .mod-accessories__placeholder {
		width: 100%;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		max-width: 100% !important;
	}

	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-close {
		top: 0;
		right: 0;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		left: 0 !important;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin {
		padding: 0 !important;
	}
}

@media screen and (max-width: 375px) {
	.product-filter__item:last-child .btn-group {
		display: none;
	}
	.product-filter__item [for='input-limit'],
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'] {
		margin: 0 0 4px;
	}
	.product-filter__item,
	.product-filter__item .sort,
	.product-filter__item .sort [for='input-sort'],
	.product-filter__item .sort .selectric-wrapper,
	.product-filter__item:nth-of-type(2),
	.product-filter__item [for='input-limit'],
	.product-filter__item:nth-of-type(2) .selectric-wrapper {
		width: 100%;
	}
	.h1_container h1,
	.custom-viewed-box-heading h3 {
		text-align: center;
	}
	.custom-bottom:before {
		height: 90px;
		width: 90px;
		left: -33px;
	}
	.custom-catalog .product-block .top .rating {
		height: 15px;
	}
	.custom-catalog .product-block .top .name {
		margin: 10px 0 4px;
	}
	.custom-catalog .product-block,
	.custom-catalog .product-block:nth-child(2n),
	.product-filter + .product-grid .custom-catalog .product-block,
	.product-filter + .product-grid .custom-catalog .product-block:nth-child(2n),
	.product-filter + .product-grid .custom-catalog .product-block:nth-child(odd) {
		margin: 15px !important;
		width: 100%;
	}

	.custom-subcat .list-inline.subcat .subcat-item {
		margin: 15px;
		width: 40%;
	}
	/* Todo: Begin cart product */
	#product .custom-product-meta-item:first-child .quantity,
	#product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice {
		width: 100%;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
	}
	#product .custom-product-meta-item:last-child .custom-product-meta-btn .cheap_invoice ul {
		width: auto;
		display: inline-block;
	}
	#product .product-prices ul li .list-prices,
	.product-info .cart-btn {
		-webkit-justify-content: center;
		justify-content: center;
	}
	.product-info .product-article {
		text-align: center;
	}
	.product-info .product-stars-and-review-inner .product-stars {
		margin: 0 0 30px;
	}
	#product,
	.product-info .product-stars-and-review-inner,
	.page-navigation__item,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container,

		/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row,
	.product-grid,
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		-webkit-flex-direction: column;
		flex-direction: column;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column,
	#tab-description img,

		/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"]:last-child .mod-content-item__container .mod-content.mod-content-item__content,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row [class*="col-"]:last-child .mod-content-item__container,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:first-child .mod-text-photo__column.mod-text-photo__column--content,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-left.mod-text-photo--half-height .row [class*="col-"]:first-child .mod-text-photo__column.mod-text-photo__column--content {
		width: 100%;
		z-index: 2;
	}

	/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"],
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row [class*="col-"] {
		width: 100%;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:first-child {
		padding: 0 15px;
	}

	/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"]:last-child .mod-content-item__container .mod-content.mod-content-item__content {
		padding: 30px 0;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:first-child .mod-text-photo__column.mod-text-photo__column--content:after, .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-left.mod-text-photo--half-height .row [class*="col-"]:first-child .mod-text-photo__column.mod-text-photo__column--content:after, .tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(1) .mod-content-item.row:after, .tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row:before {
		height: 125px !important;
		width: 50% !important;
	}
	#product .custom-product-meta-item,
	.custom-accessories .custom-accessories-inner .custom-accessories-columns,
	.tab-characteristics > div .desc-pane .table thead tr td .td-item,
	.product-info .product-info-bg [class*="col-"]:first-child,
	.product-info .product-info-bg [class*="col-"]:last-child,
	.product-info .product-description,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*='col-'],
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-left.mod-text-photo--half-height .row [class*="col-"],
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		width: 100% !important;
	}
	#product .custom-product-meta-item:first-child,
	.com-stripe-title__descr,
	.tab-characteristics > div .desc-pane .table tbody tr td .td-item,
	.tab-characteristics > div .desc-pane .table thead tr td .td-item {
		text-align: center;
	}
	.product-info .product-view {
		padding: 30px 0;
	}
	.breadcrumb {
		display: none;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		margin: 0 0 400px;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center .custom-accessories__items {
		transform: scale(1);
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__items .title-link span {
		line-height: 24px;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-center .btn-group {
		margin: 0 0 45px;
	}
	.custom-related-products .desc-pane .products-block .product-col-wrap,
	.product-info .product-description,
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		margin: 0 0 30px;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container {
		padding: 15px;
		height: auto;
	}
	.custom-related-products .desc-pane .products-block .product-col-wrap,
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item {
		width: auto !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content:before {
		top: -6.5%;
		left: -80px;
		z-index: -1;
	}
	.producttabs .owl-carousel.product-grid.col-nopadding.tcarousel.owl-loaded.owl-drag:before, .viewed-box-popular-wrapper .viewed-box-content:before, .tab-content .tab-description .related-products-wrapp .custom-related-products:before {
		right: -115px;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product {
		padding: 0;
	}
	.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title {
		line-height: 28px;
	}

	/* Todo: Other cheas grid img&text */
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"]:last-child .mod-content-item__container .mod-content.mod-content-item__content,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row [class*="col-"]:last-child .mod-content-item__container {
		padding: 30px;
		text-align: center;
	}

	.product-info .product-stars-and-review-inner {
		-webkit-align-items: center;
		align-items: center;
	}
	#product .custom-product-meta-item:first-child .quantity {
		margin: 0 0 30px;
	}
	#product {
		width: 100%;
	}
	/* Todo: End cart product */

	/* Todo: Begin second last work */
	.page-navigation__item {
		align-items: center;
	}
	.page-navigation__item li {
		margin: 0;
	}
	.tab-content {
		padding: 0;
	}
	.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title {
		margin: 0;
		line-height: 24px;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container .mod-content.mod-content-item__content h3,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column .mod-content-item .mod-content-item__container .mod-content.mod-content-item__content {
		text-align: center;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row [class*="col-"]:first-child {
		padding: 0 15px !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:last-child .mod-content-item.row,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.container .row,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-left.mod-text-photo--half-height .row {
		-webkit-flex-direction: column !important;
		flex-direction: column !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.container.container_v3_ch .row [class*="col-"]:last-child,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(2) .mod-content-item.row [class*="col-"]:first-child,
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-on-right.mod-text-photo--half-height .row [class*="col-"]:last-child {
		padding: 0 15px !important;
	}
	.custom-instructions:after {
		transform: scale(0.5) !important;
	}
	.tab-characteristics > div .desc-pane .container {
		overflow: auto !important;
	}
	.tab-characteristics > div .desc-pane .table {
		min-width: 560px !important;
	}
	.tab-characteristics > div .desc-pane .table thead tr td .td-item,
	.tab-characteristics > div .desc-pane .table tbody tr td .td-item {
		text-align: left !important;
	}
	.mod-content__title,
	.mod-content__content, .mod-content__content p {
		text-align: center !important;
	}
	.tab-description .desc-pane [id] .mod-slideshow ul .mod-slideshow__item.slick-slide {
		height: auto !important;
	}
	.custom-accessories-columns.custom-accessories-left .custom-accessories__items:last-child,
	.tab-description .desc-pane [id] .mod-contents.mod-contents--triple .mod-contents__container .mod-contents__column {
		margin-bottom: 17px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status:before,
	.breadcrumb {
		display: none !important;
	}
	.product-info .in-stock {
		right: 15px !important;
		top: 80px !important;
	}
	.product-info .quantity {
		display: flex !important;
		justify-content: center !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item {
		display: inline-flex !important;
	}

	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__media-wrapper .mod-video__media {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__media-wrapper .mod-video__media img {
		top: 0 !important;
	}
	/* Todo: End second last work */

	.custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(1), .custom-accessories .custom-accessories-inner .custom-accessories-columns:nth-of-type(3) {
		width: 100% !important;
	}
	.product-filter + * + .paging .pull-left .pagination {
		position: relative !important;
		z-index: 1 !important;
	}
	#product .product-prices ul li .list-prices .price-old {
		margin: 0 15px 0 0 !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child {
		padding: 0 !important;
	}
	.page-navigation__item {
		padding: 30px 0 !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content {
		padding: 0 !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content:before {
		display: none !important;
	}
	.tab-description .desc-pane [id] .mod-video.mod-video--full-width.container-fluid .mod-video__content-wrapper .mod-content.mod-video__content .mod-content__title {
		text-transform: none !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(2), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(4), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-right.container .row [class*="col-"]:nth-of-type(6), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(2), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"]:nth-of-type(5) {
		padding: 0 15px !important;
	}
	.mod-content__title, .mod-content__content, .mod-content__content p,
	.mod-text-photo__notes, .tab-description .desc-pane .com-stripe-title__descr.container {
		text-align: left !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .mod-content-item .mod-content__content {
		height: 115px !important;
		text-align: center !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .custom-accessories__items {
		margin: 0 !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:first-child .custom-accessories__items .images {
		border-left: none !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns:last-child .custom-accessories__items .images {
		border-right: none !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center {
		display: inline-flex !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories__center .custom-accessories__items {
		margin: 0 !important;
		padding: 0 15px !important;
	}
	.custom-accessories-columns.custom-accessories-center .custom-accessories__center .custom-accessories__items .images {
		width: 100% !important;
		position: initial !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products:before,
	.viewed-box-popular-wrapper .viewed-box-content:before {
		display: none !important;
	}
	.custom-accessories-columns.custom-accessories-center .btn-group .btn-book-recipes {
		max-width: 234px !important;
		width: 234px !important;
	}
	.custom-accessories .custom-accessories-inner .custom-accessories-columns .btn-group .btn-book-recipes span {
		font-size: 14px !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn {
		height: 48px !important;
		max-width: 179px !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn:before {
		height: 45px;
		border-bottom: 45px solid #c7163d;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn span {
		font-size: 16px !important;
		top: 5px !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .cart .btn span:before {
		left: -50px !important;
		top: -5px !important;
		width: 22px !important;
		height: 28px !important;
	}
	.viewed-box-popular-wrapper .viewed-box-content .viewed-box-product .viewed-box-item .product-meta .bottom .compare .btn {
		padding: 8px !important;
		height: 48px !important;
		width: 44px !important;
	}
	.breadcrumbs .breadcrumb {
		padding: 0 !important;
		display: inline-block !important;
	}
	.product-info .in-stock {
		position: absolute !important;
		left: initial !important;
		right: 0 !important;
		top: 0 !important;
		z-index: 2 !important;
	}
	.product-info .title-product {
		margin: 0 0 30px !important;
		padding: 0 !important;
	}
	.page-navigation__item li {
		margin: 0 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status {
		margin: 0 0 50px !important;
	}
	.custom-accessories .container {
		padding: 0 !important;
	}
	.custom-accessories .custom-accessories-inner {
		width: 100% !important;
	}
	.page-product.row-offcanvas:before {
		display: none !important;
	}
	.product-info .product-stars-and-review-inner {
		margin: 0 0 15px !important;
		flex-wrap: wrap;
	}
	.product-info .product-stars-and-review-inner:before {
		bottom: -8px !important;
		left: 0 !important;
		top: 40px;
	}
	.page-navigation__item li {
		margin: 0 0 15px !important;
	}
	.page-navigation__item li a {
		font-size: 14px !important;
		line-height: 14px !important;
	}
	.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title {
		position: inherit !important;
		text-transform: none !important;
		font-weight: 700 !important;
		font-size: 18px !important;
		text-align: center !important;
	}
	.tab-description .desc-pane [id] .mod-heading .mod-content.mod-heading__content .mod-content__title:after {
		display: none !important;
	}
	.mod-content__title {
		text-align: center !important;
	}
	.tab-characteristics > div .desc-pane .table {
		min-width: auto !important;
	}
	.product-info .title-product {
		font-size: 16px !important;
		line-height: 24px !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs {
		margin-bottom: 10px !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev,
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 50% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-50%) scale(-1, 1) !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item {
		margin: 0 15px !important;
		width: 36% !important;
	}
	.breadcrumbs {
		margin: 0 !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td .custom-review-title {
		font-size: 14px !important;
	}
	.tab-content .tab-description .desc-pane + div .custom-review-status .reviews_list-wrapper #review .table tbody tr td [itemprop=author] {
		font-size: 18px !important;
	}
	#product .custom-product-meta-item:last-child .quantity .quantity-item {
		top: -110px !important;
	}
	/* Todo: Btn slider */
	.custom-featured .products-block .product-block .product-meta .bottom .cart .custom-left-btn {
		max-width: 178px !important;
		height: 46px !important;
	}
	.custom-featured .products-block .product-block .product-meta .bottom .cart .custom-left-btn span:before {
		left: 15px !important;
		top: -5px !important;
	}
	.custom-featured .products-block .product-block .product-meta .bottom .cart .custom-left-btn:before,
	.bottom .cart .btn:before {
		height: 44px !important;
		border-bottom: 44px solid #c7163d !important;
	}
	.custom-featured .products-block .product-block .product-meta .bottom .cart .custom-left-btn:hover:before,
	.bottom .cart .btn:hover:before {
		border-bottom: 44px solid #c7163d !important;
	}
	.custom-featured .products-block .product-block .product-meta .bottom .compare .btn,
	.custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .bottom .compare .btn {
		height: 46px !important;
		width: 46px !important;
	}
	.custom-featured .products-block .product-block .product-meta .bottom .compare {
		margin: -10px 0 0 10px !important;
		height: 46px !important;
	}
	.bottom .cart .btn {
		height: 46px !important;
		max-width: 178px !important;
	}
	.product-grid .product-block .cart, .products-owl-carousel .product-block .cart {
		min-width: 178px !important;
	}
	.custom-related-products .desc-pane .products-block .owl-stage-outer .owl-item .product-block .bottom .compare .btn {
		height: 46px !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container .mod-contents__column:nth-of-type(1) .mod-content-item.row:after, .tab-description .desc-pane [id] .mod-contents.mod-contents--double .mod-contents__container.container-fluid .mod-contents__column:nth-of-type(2) .mod-content-item.row:before {
		height: auto !important;
	}

	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-prev {
		left: -10px !important;
	}
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-nav .owl-next {
		right: -10px !important;
	}
	.product-info .product-info-bg .image-container .thumbnail {
		height: auto;
	}

	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-prev,
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		top: calc(50% - 33px) !important;
	}
	.main-columns.custom-bestsellers-popular .panel.panel-v1.featured.custom-featured .custom-bestsellers-popular-slider .owl-carousel .owl-nav .owl-next {
		right: -8px !important;
	}

	.custom-subcat .list-inline.subcat .subcat-item a {
		height: 175px !important;
		position: relative !important;
		overflow: hidden !important;
	}
	.custom-subcat .list-inline.subcat .subcat-item img {
		height: 100px !important;
		max-width: 100% !important;
		width: 100% !important;
		display: block !important;
		object-fit: contain !important;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.custom-bottom .custom-producttabs .panel-body .box-products .owl-stage-outer .owl-stage .owl-item .product-block .bottom .cart .btn {
		max-width: 178px !important;
	}
	.custom-bottom .custom-producttabs .panel-body .box-products .owl-stage-outer .owl-stage .owl-item .product-block .bottom .cart .btn:hover:before,
	.custom-catalog .product-block .cart .btn:hover:before {
		border-bottom: 44px solid #ab0f32 !important;
	}

	.custom-related-products .desc-pane .products-block .owl-nav .owl-prev, .custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		top: 50% !important;
		transform: translateY(-25%) !important;
	}
	.custom-related-products .desc-pane .products-block .owl-nav .owl-next {
		transform: translateY(-50%) scale(-1, 1) !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid {
		position: relative !important;
		left: 0 !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .owl-stage {
		left: -15px !important;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item {
		width: 100% !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 60px;
		width: 100%;
	}
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item img {
		height: auto !important;
		width: 100% !important;
		display: block !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		margin: 8px !important;
	}
	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content) .mod-text-photo__media, .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content) .mod-text-photo__media .mod-text-photo__image-wrapper {
		height: 250px !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}

	.product-info .product-info-bg .image-container .image-additional .imgthumbs {
		padding: 0 15px !important;
	}

	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 66px;
		width: 66px !important;
	}
	.mod-contents__container.ka-theme.ka-theme--red-highlight.slick-initialized.slick-slider {
		height: auto !important;
	}
	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		height: 100%;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}
	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}

	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .product-col-wrap {
		width: 94% !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .owl-stage-outer {
		padding: 15px;
	}

	.product-info .cart-btn .cart .btn-cart span {
		font-size: 21px;
	}
}

@media screen and (max-width: 360px) {
	.header-v1 {
		background-position: center 139px;
		background-size: 100% calc(100% - 130px);
		.container-header.container-header_mobile {
			.top-box {
				flex-wrap: wrap;
				justify-content: space-around;
				.left-box {
					width: 100%;
					padding-top: 15px;
				}
				.right-box {
					.telephone-box {
						padding-left: 30px;
					}
				}
			}
		}
	}

	.search_block form.search-form_rc.is-active input {
		right: -250px;
		width: 250px;
		top: 0px;
		z-index: 1;
	}

	.custom-bottom .custom-producttabs .panel-body .box-products .owl-stage-outer .owl-stage .owl-item .product-block .bottom .compare .btn {
		padding: 10px;
		height: 45px;
		width: 45px;
	}
	.custom-instructions:after {
		display: none;
	}
	.custom-instructions .container .desc-pane ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.custom-bottom .custom-producttabs .panel-body .box-products .owl-stage-outer .owl-stage .owl-item .product-block .bottom .cart .btn:hover:before,
	.custom-catalog .product-block .cart .btn:hover:before {
		border-bottom: 44px solid #ab0f32 !important;
	}
	.tab-content .tab-description .related-products-wrapp .custom-related-products .products-block.row.products-row.product-grid .owl-stage {
		left: -15px !important;
	}
	.page-product.row-offcanvas:before {
		display: none;
	}
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item {
		width: 100% !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 60px;
		width: 100%;
	}
	.product-info .product-info-bg .image-container .custom-image-container .owl-carousel .owl-stage-outer .owl-item .item img {
		height: auto !important;
		width: 100% !important;
		display: block !important;
	}
	.custom-catalog .product-block .bottom .compare .btn {
		padding: 10px;
		height: 44px;
		width: 44px;
	}
	.product-info .product-info-bg .image-container .thumbnail a img {
		height: auto !important;
	}

	.tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content), .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content) .mod-text-photo__media, .tab-description .desc-pane [id] .mod-text-photo.mod-text-photo--text-boxed-on-left.container .row [class*="col-"] .mod-text-photo__column:not(.mod-text-photo__column--content) .mod-text-photo__media .mod-text-photo__image-wrapper {
		height: 250px !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs {
		padding: 0 15px !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 66px;
		width: 66px !important;
	}

	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		height: 100%;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}

	.product-info .cart-btn .cart .btn-cart span {
		font-size: 21px;
	}

	/* Todo: faq fancybox owlcarousel */
	.custom-faq .mod-accessories__row.mod-accessories__row--header {
		justify-content: center;
		flex-direction: column;
		width: 100%;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root {
		height: auto;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:first-child,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:last-child {
		padding: 0;
		width: 100%;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item {
		margin: 0 0 15px;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item .mod-accessories__placeholder {
		width: 100%;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		max-width: 100% !important;
	}

	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-close {
		top: 0;
		right: 0;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		left: 0 !important;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin {
		padding: 0 !important;
	}
}

@media screen and (min-width: 320px) and (max-width: 360px) {
	.header-v1.inner-header {
		min-height: 195px !important
	}

	.tab-description .desc-pane [id] .mod-contents.mod-contents--top-spacing.mod-contents--3-images.container .mod-contents__container.slick-slider .row [aria-describedby*="slick-"] .mod-content-item,
	.tab-description .desc-pane [id] .mod-contents .slick-track .owl-item .mod-content-item {
		margin: 8px !important;
	}
	.product-info .product-info-bg [class*="col-"]:last-child .product-colors {
		padding: 15px 0;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs {
		padding: 0 15px !important;
	}
	.product-info .product-info-bg .image-container .image-additional .imgthumbs a {
		height: 66px;
		width: 66px !important;
	}
	.main-columns.custom-bestsellers-popular .page-navigation {
		margin-bottom: 30px;
	}
	.custom-instructions .container .desc-pane ul li {
		margin: 0 0 30px;
	}
	.custom-instructions .container .desc-pane ul li a .title {
		font-size: 14px;
	}

	.product-info .product-info-bg .image-container .product-label.new {
		padding: 0 15px 2px 20px;
		top: 0;
		right: 104px;
	}
	.product-info .product-info-bg .image-container .product-label.new span {
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
	}
	.product-info .product-info-bg .image-container .product-label.new:before {
		height: 19px;
		border-bottom: 19px solid #c11b40;
	}

	.product-info .cart-btn .cart .btn-cart span {
		font-size: 21px;
	}

	/* Todo: faq fancybox owlcarousel */
	.custom-faq .mod-accessories__row.mod-accessories__row--header {
		justify-content: center;
		flex-direction: column;
		width: 100%;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root {
		height: auto;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:first-child,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item:last-child {
		padding: 0;
		width: 100%;
	}
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item {
		margin: 0 0 15px;
	}
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--title,
	.custom-faq .mod-accessories__row.mod-accessories__row--header .mod-accessories__column.mod-accessories__column--image,
	.custom-faq .mod-accessories__row--carousel .mod-accessories__root .mod-accessories__item .mod-accessories__placeholder {
		width: 100%;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		max-width: 100% !important;
	}

	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin .fancybox-close {
		top: 0;
		right: 0;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
		left: 0 !important;
	}
	.fancybox-overlay.fancybox-overlay-fixed .fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened .fancybox-skin {
		padding: 0 !important;
	}
}

/*----------------------------------      header      ----------------------------------------*/
@media (max-width: 767px) {
	.header-v1 {
		.container-header.container-header_mobile {
			padding: 22px 20px 17px;
			.top-box {
				.right-box {
					.telephone-box {
						display: flex;
						flex-wrap: wrap;
					}
					.telephone-box a:first-child {
						letter-spacing: 0.5px;
					}
					.svg-icon {
						flex-wrap: wrap;
						align-items: center;
						justify-content: center;
						svg {
							width: 30px;
							height: 30px;
						}
						.phone-icon {
							fill: #fff;
							fill-rule: evenodd;
						}
					}
				}
			}
			.bottom-box {
				.holiday_works {
					position: relative;
					margin: 0px !important;
					top: 3px;
					right: -15px;
				}
			}
		}
	}
}

@media (min-width: 992px) and (max-width: 1102px) {
	.header-v1 #pav-mainnav .navbar-ex1-collapse > ul > li > a {
		padding: 10px 5px;
	}
}

/*----------------------------------   product page   ----------------------------------------*/

@media (max-width: 767px) {
	#tab-description {
		section {
			.mod-text-photo--text-boxed-on-right {
				margin: unset;
			}
			.mod-text-photo__column--content {
				position: initial;
				width: unset;
				top: unset;
				bottom: unset;
			}
			.mod-text-photo__column--media {
				flex: unset;
			}
			.mod-text-photo--text-boxed-on-left {
				margin: unset;
				.mod-text-photo__notes {
					margin-right: unset;
					padding: 15px;
				}
			}
			.mod-text-photo {
				flex-wrap: wrap;
			}
			.mod-text-photo--text-boxed-on-right {
				.mod-text-photo__notes {
					padding: 15px;
					margin: 0;
				}
			}
			.mod-video__content-wrapper {
				margin: 0 20px;
			}
		}
		.faq {
			.mod-accessories__column--title {
				flex: unset;
				width: 100%;
			}
			.mod-accessories__row--header {
				flex-wrap: wrap;
				.mod-accessories__column--image {
					flex: 0 1 100%;
				}
			}
			.mod-accessories__column--title {
				margin: 0;
			}
		}
	}
	.product-info .product-info-bg [class*="col-"]:last-child, .product-info .product-info-bg [class*="col-"]:first-child {
		width: 100% !important;
	}
	.page-navigation__item {
		align-items: center;
		flex-direction: column;
		gap: 8px;
	}
}

@media (max-width: 560px) {
	.page-product {
		.gifts_block {
			.prod_gift {
				.prod_gift_link {
					.prod_gift_img {
						width: 100%;
						max-width: 300px;
					}
					.prod_gift_name {
						width: 100%;
						padding: 0 20px;
					}
				}
			}
		}
	}
}

/*--------------------    home page    --------------------*/
@media (min-width: 768px) and (max-width: 1199px) {
	.header-v1 {
		.container-banner {
			left: 0;
			.info-box .container-contact .top-box div {
				font-size: 24px;
			}
		}
	}
}

@media (max-width: 767px) {
	.widget_news .content-container .go-to-catalog {
		right: 0;
	}
	.choose-color .choose-color_box a {
		z-index: 10;
	}
	.page-home {
		.product-colors {
			padding: 7px 10px 0;
		}
		.kitchen-home {
			padding-top: 20px;
			background-position-y: 0;
		}
	}
	.common-home {
		.featured_categories {
			margin: 0;
			z-index: 25;
		}
	}
	.header-v1 {
		.container-banner, .container-shadow {
			display: none;
		}
	}
}

/*--------------------    category page    --------------------*/

@media (max-width: 767px) {
	.product-filter {
		padding: 20px;
		display: flex;
		flex-wrap: wrap;
		.product-filter__item {
			&:first-child {
				margin-bottom: 20px;
			}
			.sort [for='input-sort'] {
				margin-bottom: 5px;
			}
		}
	}
	#products {
		margin-bottom: 75px;
	}
}

/*--------------------    temp    --------------------*/

@media (min-width: 768px) {
	body:not(.page-home) {
		.header-v1 {
			background: url(/catalog/view/theme/kitchenrussia/img/banner-header.jpg) no-repeat left 70px;
			background-size: 1920px 760px;
			height: 190px;
			position: relative;
			border-bottom: 2px solid #c41239;
		}
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	body:not(.page-home) {
		.header-v1 {
			.container-banner {
				background-color: rgba(0, 0, 0, 0.25);
			}
		}
	}
}

@media (max-width: 768px) {
	.header-v1 {
		&.inner-header {
			min-height: auto;
		}
	}
}