.main-columns .container-inside .row .col-main .row .col-sm-12 #content .row .agreement-container div.last-box_text{
    max-width: 615px;
}
.total-item tbody{
    width: 100%;
}
.top-bg .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products .tab-content .box-products:before{
    content: '';
    position: absolute;
    right: -53px;
    background: url(/catalog/view/theme/kitchenrussia/img/4411.png)no-repeat;
    top: calc(100% - 70px);
    width: 137px;
    background-size: 100% 100%;
    height: 142px;
    transform: rotate(55deg);
}
.header-v1 .container-header .menu .menu-bottom .pav-mainnav .navbar .collapse .nav .dropdown:hover>ul{
    z-index: 500;
}
.custom-layout .custom-product-info .custom-related-products .desc-pane .products-block .product-label.new, .custom-layout .product-label.new, .product-label.new{
    left: 7px!important;
    right: auto;
}
.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .row .col-sm-6:before {
    position: absolute;
    bottom: -1px;
    background: url('/catalog/view/theme/kitchenrussia/img/591.png') no-repeat;
    background-size: contain;
    width: 126px;
    height: 98px;
    content:'';
    right: 4px;
    z-index: -1;
}
.container-inside .buttons.clearfix .pull-right{
    float: none!important;
    display: flex;
    justify-content: center;
    align-content: center;
}
.container-inside .buttons.clearfix .pull-right a{
    background-color: transparent;
    max-width: 145px;
    border: none;
    background: rgb(255, 51, 94);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;

}
.header-v1 .container-header.container-header_mobile .top-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-v1 .container-header.container-header_mobile .top-box .right-box{
    display: flex;
    align-items: center;
}
.header-v1 .container-header.container-header_mobile .top-box .right-box .telephone-box a{
    font-size: 17px;
    line-height: 1;
    color: white;
    display: flex;
    margin-left: 15px;
}
.header-v1 .container-header.container-header_mobile .top-box .left-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
}
.header-v1 .container-header.container-header_mobile .top-box .left-box button.btn.btn-search{
    border: none!important;
    background: none!important;
}
.header-v1 .container-header.container-header_mobile .top-box .left-box #searchtop .input-group-btn{
    border: none!important;
    background: none!important;
}
.header-v1 .container-header.container-header_mobile .top-box .left-box .btn i:before {
    content: '';
    background: url(/catalog/view/theme/kitchenrussia/img/2641.png)no-repeat;
    background-size: 100%;
    width: 25px;
    height: 26px;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 9px);
    filter: brightness(0) invert(1);
}
.header-v1 .container-header.container-header_mobile .top-box .left-box .search input{

}

.header-v1 .container-header.container-header_mobile .top-box .left-box .product-compare-header img{
width: 25px;
    height: 25px;
    margin-left: 5px;
}
.header-v1 .container-header.container-header_mobile .top-box .left-box .cart-box .cart-inner{

}
.header-v1 .container-header.container-header_mobile .top-box .left-box .cart-box .cart-inner .wrap-cart{
    position: relative;
}
.header-v1 .container-header.container-header_mobile .top-box .left-box .cart-box .cart-inner .wrap-cart span#cart-total{
    display: flex!important;
    position: absolute;
    top: -6px;
    right: -7px;
    background: #e6365d;
    color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    align-items: center;
    font-size: 11px;
    font-weight: 700;
    justify-content: center;
}

.header-v1 .container-header.container-header_mobile .top-box .left-box .cart-box .cart-inner img{
    width: 21px;
    height: 25px;
}

.header-v1 .container-header.container-header_mobile .bottom-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.header-v1 .container-header.container-header_mobile .bottom-box .burger-box .canvas-menu {
    height: 34px;
    display: flex;
    align-items: center;
}

.header-v1 .container-header.container-header_mobile .bottom-box .burger-box .canvas-menu .fa {
    font-size: 34px;
    color: white;
}

.header-v1 .container-header.container-header_mobile .bottom-box .logo-store {
    margin-left: calc(50% - 120px);
}
.header-v1 .container-header.container-header_mobile .bottom-box .logo-store a:hover{
    text-decoration: none;
}

.header-v1 .container-header.container-header_mobile .bottom-box .logo-store a span {
position: relative;
    text-transform: uppercase;
    color: white;
    bottom: 0;
    font-size: 11px;
    font-weight: 700;
}
.working-hours{
    display: none;
}
.widget_news .content-container .subscribe.form-inline button:hover{
    color: black;
}
.widget_news .content-container .subscribe.form-inline{
    padding-bottom: 100px;
}
.widget_news .content-container .subscribe.form-inline button{
    transition: 0s;
}
.special-offer.bottom-bg .homebuilder .pav-container .pav-inner .row .row-inner .col-lg-12 .col-inner .widget-products:before{
    content: '';
    position: absolute;
    left: -50px;
    background: url(/catalog/view/theme/kitchenrussia/img/4411.png)no-repeat;
    top: -60px;
    width: 175px;
    background-size: 100% 100%;
    height: 175px;
}

.box-close{
    display: none;
}

.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-quantity .input-group{
    max-width: 125px!important;
}
.main-columns .container-inside .row .col-main .row .col-sm-12 #content #buy_form_container .buy_products .shopping-cart-container .cart-item .product-quantity{
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 125px;
}

.row-offcanvas.active .ocwall{
    display: none!important;
}
#footer div div div>span{
    font-size: 13px;
}
input::-ms-clear {
    width : 0;
    height: 0;
}

#content h2 {
    font-size: 16px;
}
#content h3, #content h4{
    font-size: 14px;
}
#content h1 {
     font-size: 18px;
}
.page-information-news-item .container-inside h1{
     font-size: 18px;
     font-weight: 700;
     color: rgb(0, 0, 0);
     text-align: center;
     text-transform: uppercase;
     position: relative;
     padding-top: 28px;
     padding-bottom: 0;
     margin-bottom: 23px;
 }
.page-information-contact  .btn-primary{
    border: none;
    background: #e4345b;
    background-size: 100% 100%;
    height: 40px;
    position: relative;
    border-top: 2px solid #be1e43;
    transition: none;
    box-shadow: 0px 4px 19px rgba(194, 18, 57, 0.35);
}
.page-information-contact  .btn-primary:hover{
    background: #c72d4f;
}
.page-information-contact  .buttons .pull-right:hover:after{
    border-left: 10px solid #c72d4f;
    border-top: 6px solid #c72d4f;
}
.page-information-contact .buttons .pull-right{
    position: relative;
}
.page-information-contact  .buttons .pull-right:before{
    content: '';
    height: 38px;
    width: 83px;
    border-bottom: 38px solid #c7163d;
    border-left: 30px solid transparent;
    border-right: 0px solid transparent;
    position: absolute;
    right: 0;
    top: 0px;
}

.page-information-contact  .buttons .pull-right:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -9px;
    border: 8px solid transparent;
    border-left: 10px solid #e5355c;
    border-top: 6px solid #e5355c;
}

/*all ricep*/

#news_list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

#news_list .news_item {
    width: calc(100% / 4 - 15px);
    position: relative;
    margin-top: 50px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
    padding: 5px;
}
#news_list .news_list-content{
    border: 1px solid #ccc;
    padding-bottom: 20px;
    padding-top: 10px;
    border-top: none;
}


#news_list .news_item .news_img {
display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    }

#news_list .news_item .news_img a {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
}

#news_list .news_item .news_img a img {
    width: 100%;
    height: auto;
}

#news_list .news_item .news_date {
    position: absolute;
    background: rgb(255, 51, 94);
    width: 49px;
    height: 49px;
    top: -7px;
    right: -7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    line-height: 1;
}
#news_list .news_item .news_date span{
    font-size: 28px;
}
#news_list .news_item h3 {
    display: flex;
    height: 76px;
    padding: 0 15px;
    margin-top: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
}

#news_list .news_item h3 a {
    font-size: 13.333px;
    font-weight: 700;
    color: rgb(196, 18, 57);
}

#news_list .news_item .news_descr {
    padding: 0 15px;
}
#news_list .news_item .news_descr a{
    font-weight: 700;
    color: #333333;
    font-size: 13.333px;
    text-transform: uppercase;
    display: flex;
    margin-right: auto;
    padding-top: 9px;
    justify-content: center;
    width: 160px;
    text-align: center;
    margin-top: 20px;
    height: 50px;
    background: url(/catalog/view/theme/kitchenrussia/img/5511.png)no-repeat;
    background-size: 100% 100%;
}
#news_list .news_item .news_descr a:hover:after {
    border-left: 10px solid #c72d4f;
    border-top: 6px solid #c72d4f;
}
#news_list .news_item .news_descr p {
    font-size: 13.333px;
    color: #333333;
    line-height: 1.2;
}


.news_pages {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-items: center;
}

.news_pages .col-sm-6 {
text-align: center;
    margin-top: 30px;
}

.news_pages .col-sm-6 .pagination {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.news_pages .col-sm-6 .pagination .active {

}

.news_pages .col-sm-6 .pagination .active span {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    background-color: #931a3b;
    color: #fff;
    text-decoration: none;
    font-size: 13.33px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.news_pages .col-sm-6 .pagination li {
border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.news_pages .col-sm-6 .pagination li a {
    text-decoration: none;
    border: none;
    margin: 0;
    width: 28px;
}

.news_pages .col-sm-6 .pagination .pagination_arrows {

}

.news_pages .col-sm-6 .pagination .pagination_arrows a {

}

.news_pages .col-sm-6 .pagination .pagination_arrows a .fa {

}

.main-columns {

}

.page-information-news-item .main-columns .container-inside {
    margin-bottom: 40px;
}

.page-information-news-item .main-columns .container-inside .row {
    display: flex;
    justify-content: space-between;
}
@media  (max-width: 768px){
    .main-columns .container-inside .row {
        flex-direction: column;
    }
}

.main-columns .container-inside .row .news_column {
    display: flex;
    flex-direction: column;
    width: 25%;
    margin-right: 50px;
    min-width: 25%;
}
.main-columns .container-inside .row .news_column h2{
    font-size: 16px;
}
@media  (max-width: 768px){
    .main-columns .container-inside .row .news_column {
        width: 100%;
    }
}

.main-columns .container-inside .row .news_column .news_item h3 {
    display: flex;
    margin-top: 0;
    font-size: 13.333px;
    font-weight: 700;
    color: rgb(196, 18, 57);
    line-height: 1.2;
}

.main-columns .container-inside .row .news_column .news_item h3 a {
    font-size: 13.333px;
    font-weight: 700;
    color: rgb(196, 18, 57);
    line-height: 1.2;
}

.main-columns .container-inside .row .news_column div .allnews {
    max-width: 210px;
    border: none;
    background: rgb(255, 51, 94) no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    height: 40px;
    width: 100%;
    padding: 0 0 0 20px;
    position: relative;
    font-size: 13.332px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    text-decoration: none;
    z-index: 2;
    text-transform: uppercase;
}
.main-columns .container-inside .row .news_column div .allnews:before{
    content: '';
    height: 40px;
    width: 120px;
    border-bottom: 40px solid #c11b40;
    border-left: 30px solid transparent;
    border-right: 0px solid transparent;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    opacity: 0.6;
}
.main-columns .container-inside .row .news_column div .allnews:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -9px;
    border: 8px solid transparent;
    border-left: 10px solid rgb(255, 51, 94);
    border-top: 6px solid rgb(255, 51, 94);
}

/*search*/


.page-product-search #sidebar-main {
    width: 100%;
}

.page-product-search #sidebar-main #content {
    width: 100%;
}

.page-product-search #sidebar-main #content .row {
    justify-content: flex-start;
}
@media  (max-width: 768px) {
    .page-product-search #sidebar-main #content .row {
        flex-direction: column;
        align-items: flex-start;
    }
    .page-product-search #sidebar-main #content .row div {
        margin: 5px 0;
    }
    .page-product-search #sidebar-main #content .row .col-sm-3 {
        width: auto;
    }
}


.page-product-search #sidebar-main #content .row .col-sm-3 {
    width: 30%;
}

.page-product-search #sidebar-main #content .row .col-sm-3 .selectric-wrapper .selectric .button:after {

    border-bottom: 1px solid  #ff335e;
    border-right: 1px solid  #ff335e;
}

.page-product-search #sidebar-main #content .btn-primary {
    max-width: 150px;
    border: none;
    background: rgb(255, 51, 94) no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    height: 40px;
    width: 100%;
    position: relative;
    font-size: 13.332px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    text-decoration: none;
    z-index: 2;
    text-transform: uppercase;
}
.page-product-search #sidebar-main #content .btn-primary:before{
    content: '';
    height: 40px;
    width: 120px;
    border-bottom: 40px solid #c11b40;
    border-left: 30px solid transparent;
    border-right: 0px solid transparent;
    position: absolute;
    right: 0;
    top: -2px;
    z-index: -1;
    opacity: 0.6;
}
.page-product-search #sidebar-main #content .btn-primary:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -9px;
    border: 8px solid transparent;
    border-left: 10px solid rgb(228, 52, 91);
    border-top: 6px solid rgb(228, 52, 91);
}

.page-product-search #sidebar-main #content h2 {
    font-size: 16px;
    font-weight: 700;
    color: rgb(0, 0, 0);
    text-align: center;
    text-transform: uppercase;
    position: relative;
    padding-top: 28px;
    padding-bottom: 0;
    margin-bottom: 23px;
}
.page-product-search #sidebar-main #content h2:before{
    content: '';
    position: absolute;
    left: calc(50% - 15px);
    height: 3px;
    width: 30px;
    top: 0px;
    background-color: #e6365d;
}

.page-product-compare #content .comparetable .table-bordered td{
    padding: 5px;
    vertical-align: inherit;
}
.page-product-compare #content .comparetable .table-bordered td a:not(.btn){
    font-size: 13.333px;
    color: rgb(0, 0, 0);
    line-height: 1.2;
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
}

.page-product-compare #content .comparetable .table-bordered td a:hover{
    color: rgb(196, 18, 57);
    text-decoration: none;
}
.page-product-compare #content .comparetable .table-bordered td .price-old{
    font-size: 18.667px;
    font-weight: 700;
    color: #666666;
    line-height: 1.2;
    text-decoration: line-through;
    display: inline-block;
    width: 49%;
    text-align: center;
}
.page-product-compare #content .comparetable .table-bordered td .price-new{
    font-size: 18.667px;
    text-align: center;
    font-weight: 700;
    display: inline-block;
    width: 49%;
    color: rgb(196, 18, 57);
    line-height: 1.2;
}

.page-product-compare #content .comparetable .table-bordered td a.btn-default{
    max-width: 145px;
    border: none;
    background: #5c5c5c;
    background-size: 100% 100%;
    font-weight: 700;
    width: 100%;
    position: relative;
    border-top: 2px solid #be1e43;
    transition: none;
    box-shadow: 0px 4px 19px rgba(194, 18, 57, 0.35);
    z-index: 2;
    margin-bottom: 15px;
}

.page-product-compare #content .comparetable .table-bordered td .button-def-add{
    max-width: 145px;
    border: none;
    background: #e4345b;
    font-weight: 700;
    background-size: 100% 100%;
    width: 100%;
    position: relative;
    border-top: 2px solid #be1e43;
    transition: none;
    box-shadow: 0px 4px 19px rgba(194, 18, 57, 0.35);
    z-index: 2;
    height: 36px;
    margin-bottom: 10px;
    min-width: 145px;
}
.page-product-compare #content .comparetable .table-bordered td .button-def-add:before {
    content: '';
    height: 34px;
    width: 83px;
    border-bottom: 34px solid #c7163d;
    border-left: 30px solid transparent;
    border-right: 0px solid transparent;
    position: absolute;
    right: 0;
    top: 0px;
    z-index: -1;
}
.page-product-compare #content .comparetable .table-bordered td .button-def-add:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -9px;
    border: 8px solid transparent;
    border-left: 10px solid #e5355c;
    border-top: 6px solid #e5355c;
}
.page-product-compare #content .comparetable .table-bordered td .button-def-add:hover {
    background: #c72d4f;
}
.page-product-compare #content .comparetable .table-bordered td .button-def-add:hover:after {
    border-left: 10px solid #c72d4f;
    border-top: 6px solid #c72d4f;

}
.page-product-compare #content .comparetable .table-bordered td a.btn-default:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -9px;
    border: 8px solid transparent;
    border-left: 10px solid #5c5c5c;
    border-top: 6px solid #5c5c5c;
}
.page-product-compare #content .comparetable .table-bordered td a.btn-defaul:hover {
    background: #000;
}
.page-product-compare #content .comparetable .table-bordered td a.btn-defaul:hover:before {
    border-bottom: 38px solid #ab0f32;
}

.page-checkout-success .buttons.clearfix .btn-primary{
    max-width: 185px;
    width: 185px;
    display: inline-block;
    border: none;
    background: #e4345b !important;
    background-size: 100% 100%;
    height: 40px;
    position: relative;
    border-top: 2px solid #be1e43 !important;
    transition: none;
    box-shadow: 0 4px 19px rgba(194, 18, 57, 0.35);
    z-index: 2;
}
.page-checkout-success .buttons.clearfix .btn-primary:hover{
    background: #c72d4f !important;
}
.page-checkout-success .buttons.clearfix .btn-primary:before{
    content: '';
    height: 38px;
    width: 50%;
    border-bottom: 38px solid #c7163d;
    border-left: 30px solid transparent;
    border-right: 0 solid transparent;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}
.page-checkout-success .buttons.clearfix .btn-primary:hover:before {
     border-bottom: 38px solid #ab0f32;
 }
.page-checkout-success .buttons.clearfix .btn-primary:hover:after {
    border-left: 10px solid #c72d4f;
    border-top: 6px solid #c72d4f;
}
.page-checkout-success .buttons.clearfix .btn-primary:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -9px;
    border: 8px solid transparent;
    border-left: 10px solid #e5355c;
    border-top: 6px solid #e5355c;
}
.page-product-special .products-block.col-nopadding.custom-catalog{
    width: 100%;
    margin: 0!important;
}
.page-product-special .products-block.col-nopadding.custom-catalog .product-block.item-default.custom-item{
    width: calc(100% / 4 - 30px);
}

.back-call_rc {
    display: none;
    position: fixed;
    flex-direction: column;
    top: calc(50% - 100px);
    left: calc(50% - 200px);
    background: #fff;
    padding:35px  20px 20px;
     -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
    z-index: 9999;
    max-width: 400px;
    width: 100%;

    .recaptchaLegal {
        display: block;
        line-height: 16px;
    }
}
.back-call_rc span.close-rc{
    position: absolute;
    right: 12px;
    top: 12px;
    width: 15px;
    height: 15px;
    opacity: 1;
}
.back-call_rc span.close-rc:before, .back-call_rc span.close-rc:after {
    position: absolute;
    left: 6px;
    content: ' ';
    height: 15px;
    top: 0;
    width: 2px;
    background-color: #333;
}
.back-call_rc span.close-rc:before {
    transform: rotate(45deg);
}
.back-call_rc span.close-rc:after {
    transform: rotate(-45deg);
}

.back-call_rc.is-active{
    display: flex;
}
.back-call_rc h2 {
    margin: 0;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}
.back-call_rc h2:before{
    width: 30px;
    height: 4px;
    position: absolute;
    left: 0;
    top: -15px;
    content: '';
    background: #e6365d;
}
.back-call_rc form {
    margin-top: 30px;
}

.back-call_rc form div {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.back-call_rc form div label {
    font-weight: 700;
    color: #e4345b;
}

.back-call_rc form div input {
    border-radius: 3px;
    width: 200px;
    border: 1px solid #7d7d7d;
    padding: 0 10px;
    height: 30px;
    box-shadow: none;
}

.back-call_rc form button {
    margin-left:auto ;
    display: block;
    margin-top: 10px;
    max-width: 145px;
    border: none;
    background: #e4345b;
    font-weight: 700;
    background-size: 100% 100%;
    height: 40px;
    position: relative;
    border-top: 2px solid #be1e43;
    transition: none;
    color: #fff;
    box-shadow: 0px 4px 19px rgba(194, 18, 57, 0.35);
    z-index: 2;
}
.back-call_rc form button:before{
    content: '';
    height: 38px;
    width: 83px;
    border-bottom: 38px solid #c7163d;
    border-left: 30px solid transparent;
    border-right: 0px solid transparent;
    position: absolute;
    right: 0;
    z-index: -1;
    top: 0px;
}
.back-call_rc form button:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -9px;
    border: 8px solid transparent;
    border-left: 10px solid #e5355c;
    border-top: 6px solid #e5355c;
}
.bg-box.body-bg_rc{
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 9998;
}
body.is-active-bg{
    height: 100vh;
    overflow: hidden;
}

.owl-nav > div{
    filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));
}