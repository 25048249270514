button {
	&:active, &:focus {
		outline: none !important;
	}
}

#page {
	min-height: 100vh;
	& > .container {
		min-height: calc(100vh - 448px);
	}
}

.page-themecontrol-product {
	#page {
		min-height: 1px;
	}
}

.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}

#button-cart {
	width: 122px;
}

.product-info {
	margin-top: 24px;
}

#tab-description {
	img {
		height: auto;
	}
	.grid {
		.grid-sizer {
			width: calc(33% - 5px);
		}
		display: flex;
		flex-wrap: wrap;
		.grid-item {
			padding: 5px;
			background: #fff;
			width: 33%;
			& > img {
				width: 100%;
				height: auto;
				margin: 0;
				padding: 0;
				display: block;
			}
			&.full-width {
				width: 100%;
				h3 {
					margin-top: 0;
				}
				.full-row {
					display: flex;
					.copy.outer {
						width: calc(40% - 35px);
						padding: 0 0 0 20px;
					}
					img {
						max-width: 100%;
						height: 100%;
						width: 63%;
					}
				}
			}
		}
	}
}

#tab-downloads {
	ul li {
		list-style: none;
	}
}

#mainmenutop {
	.nav.navbar {
		height: 60px;
	}
	.panel-v4 .panel-body {
		border: none !important;
	}
}

.advantages img, .advantages .col-sm-9 {
	height: auto !important;
}

#is_ip .radio_custom .input-control {
	margin-right: 15px;
}

.modal-dialog {
	.reserve_button, .callback_button {
		@include rounded-corners(0);
		background: $tc;
		@include transition(0.2s all);
		padding: 10px 15px;
		text-transform: uppercase;
		font-family: $font-family-base;
		&:hover {
			background: $black;
		}
	}
}

.contact-icon {
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: $tc;
	top: 0;
	position: absolute;
	i.fa {
		color: $white;
		font-size: 30px;
		padding: 11px 10px 10px 10px;
		width: 50px;
		height: 50px;
		text-align: center;
		margin-top: -1px;
	}
}

.contact-text {
	display: inline-block;
	padding-left: 60px;
	min-height: 60px;
	margin-bottom: 10px;
	line-height: 24px;
	div.contact-text-b-days {
		div {
			display: flex;
			flex-wrap: wrap;
		}
	}
}

.search_block {
	overflow: visible;
	height: 100%;
	display: flex;
	align-items: center;
	form {
		margin: 0;
		position: relative;
		height: 100%;
		input {
			position: absolute;
			transform-origin: 0 0;
			right: 50px;
			transition: 0.5s;
			opacity: 0;
			width: 0;
			top: 0;
			height: 100%;
			padding: 0 5px;
		}
		&.is-active input {
			width: 300px;
			opacity: 1;
		}
		button {
			padding: 0 8px;
			height: 36px;
			width: 50px;
			background: none;
			&:focus, &:active {
				outline: none !important;
			}
			i {
				font-size: 23px;
				vertical-align: -2px;
				&:before {
					content: '';
					background: url(/catalog/view/theme/kitchenrussia/img/search.png) no-repeat;
					width: 18px;
					height: 18px;
					position: absolute;
					top: calc(50% - 9px);
					left: calc(50% - 9px);
				}
			}
		}
	}
	.dropdown-menu.autosearch {
		margin: 0;
		width: 350px;
		min-width: 0;
		padding: 0;
		right: 0;
		left: auto;
		display: none;
		border-radius: 0;
		top: 100%;
		li {
			@include transition(0.2s all);
			display: inline-block;
			width: 100%;
			margin-bottom: 5px;
			cursor: pointer;
			line-height: 1;
			position: relative;
			border-bottom: 1px #ebebeb solid;
			&:nth-last-child(-n+2) {
				border: none;
			}
			> div {
				line-height: 1.5;
				display: inline-block;
			}
			a {
				white-space: normal;
				padding: 3px 5px 20px 0;
			}
			.as-image {
				float: left;
				padding: 5px;
				width: 80px;
				margin-right: 10px;
				img {
					height: auto;
					width: 100%;
				}
			}
			.as-title {
				width: 100%;
				font-size: 16px;
				margin: 10px 0;
				a {
					color: $black;
				}
			}
			.as-price {
				width: calc(100% - 95px);
				font-size: 14px;
				font-weight: 600;
				position: absolute;
				bottom: 10px;
				left: 95px;
				.price-old {
					font-size: 14px;
					text-decoration: line-through;
					margin-right: 7px;
					color: #777;
				}
			}
			#showall {
				padding: 0 25px;
				display: inline-block;
			}
		}
		@media (max-width: 1199px) {
			width: 300px;
			li {
				.as-image {
					width: 80px;
				}
				.as-title {
					width: 100%;
					font-size: 14px;
					font-weight: 500;
					line-height: 16px;
				}
				.as-price {
					width: calc(100% - 120px);
					left: 95px;
				}
			}
		}
		@media (max-width: 767px) {
			width: 260px;
			right: -15px;
			left: auto;
		}
		@media (max-width: 421px) {
			top: calc(100% + 2px);
			width: calc(100vw - 30px);
			left: auto;
		}
	}
}

.producttabs {
	margin-top: 30px;
	margin-bottom: 0;
}

#checkout-form #shipping_methods .radio, #checkout-form #payment_methods .radio {
	margin-left: 0 !important;
}

.radio_custom {
	a {
		padding-left: 10px;
		font-size: 18px;
		color: $tc;
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 22px;
		position: relative;
		font-size: 14px;
		font-weight: 500;
		color: $bk;
		margin: 1px 0;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			border-radius: 50%;
			display: block;
			position: absolute;
			left: 0;
			user-select: none;
			@include size(15px, 15px);
			text-align: center;
			background: none;
			width: 14px;
			height: 14px;
			border: 1px solid #dfdfdf;
			top: calc(50% - 7px);
		}
		&.radio input ~ .input-control__indicator:after {
			border-radius: 50%;
			content: "";
			display: block;
			position: absolute;
			transform-origin: center center;
			background: $tc;
			@include opacity(0);
			@include transition(.1s opacity linear);
			width: 8px;
			height: 8px;
			background: #931a3b;
			transform: scale(1);
			border: none;
			top: 2px;
			left: 2px;
		}
		&.radio input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
	@media (max-width: 767px) {
		.input-control, .list_of_points {
			font-size: 14px;
		}
	}
}

#button-order {
	margin-top: 15px;
}

#totop {
	position: fixed;
	right: 10px;
	bottom: 100px;
	cursor: pointer;
	text-indent: -9999px;
	z-index: 999;
	background: url(/catalog/view/theme/kitchenrussia/img/851.png) no-repeat;
	border: none;
	padding: 0;
	background-size: cover;
	width: 50px;
	height: 50px;
	@include transition(200ms all ease-in-out);
	span {
		display: none;
	}
	&:hover {
		background-color: transparent;
	}
}

#coupon_form {
	h4 {
		font-size: 16px;
	}
	#button-coupon {
		padding: 0 16px;
	}
}

.maintenance {
	min-height: calc(100vh - 381px);
}

.widget-products {
	.product-grid .product-block {
		margin: 0;
		width: 100%;
	}
}

h1 {
	padding: 15px 0;
	text-transform: none;
}

.comparetable {
	padding: 15px;
	margin-bottom: 15px;
	overflow-x: auto;
	table {
		margin-bottom: 0;
	}
	.price-old {
		text-decoration: line-through;
		color: $mg;
	}
}

.owl-prev, .owl-next {
	position: absolute;
	top: -33px;
	width: 25px;
	height: 25px;
	color: #fff;
	@include transition(0.2s ease all);
	background: $tc center no-repeat;
	font-family: $fa;
	font-size: 22px;
	line-height: 1;
	padding: 1px;
	&:hover {
		background-color: $mg;
	}
}

#image-additional-carousel {
	padding: 0 30px;
}

#image-additional {
	.owl-prev, .owl-next {
		top: 13px;
	}
	.owl-next {
		right: 0;
	}
	.owl-prev {
		left: 0;
		right: auto;
	}
}

#image-zoomContainer {
	overflow: hidden;
}

.selectric-wrapper {
	position: relative;
	cursor: pointer;
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	border: 1px solid $lg;
	border-radius: 0;
	background: $white;
	position: relative;
	overflow: hidden;
	.label {
		display: block;
		white-space: nowrap;
		overflow: visible;
		width: calc(100% - 35px);
		text-overflow: ellipsis;
		margin: 0 25px 0 10px;
		padding: 8px 0;
		font-size: 14px;
		color: $black;
		height: 30px;
		font-weight: 400;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		line-height: 1;
	}
	.button {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 38px;
		line-height: 38px;
		background-color: $white;
		color: $black;
		text-align: center;
		&:after {
			content: '';
			position: absolute;
			@include size(6px, 6px);
			border-bottom: 1px solid $black;
			border-right: 1px solid $black;
			@include transform(rotate(45deg));
			right: 10px;
			top: 10px;
		}
	}
}

.selectric-focus .selectric {
	border-color: #aaaaaa;
}

.selectric-hover .selectric {
	.button {
		color: #a2a2a2;
		&:after {
			border-top-color: #a2a2a2;
		}
	}
}

.selectric-open {
	z-index: 9999;
	.selectric .button::after {
		@include transform(rotate(225deg));
		transform-origin: 4px 5px;
	}
	.selectric-items {
		display: block;
	}
}

.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
	}
	&.selectric-is-native {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 10;
		select {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border: none;
			z-index: 1;
			box-sizing: border-box;
			opacity: 0;
		}
	}
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	display: none;
	position: absolute;
	top: 31px;
	right: 0;
	background: $white;
	border: 1px solid $lg;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
	.selectric-scroll {
		height: 100%;
		overflow: auto;
	}
	ul, .selectric-items li {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 14px;
		line-height: 20px;
		min-height: 20px;
	}
	li {
		display: block;
		padding: 10px;
		color: $black;
		cursor: pointer;
		line-height: 16px;
		white-space: nowrap;
		&.selected, &.highlighted, &:hover {
			color: $wh;
			background-color: $tc;
		}
	}
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		color: #666 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.selectric-group {
		.selectric-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			color: #444;
		}
		&.disabled li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

.widget-products {
	.tab-header {
		padding: 5px 0;
		border-bottom: $bc 1px solid;
		span {
			font-family: $hf;
			padding: 0 5px;
			font-size: 18px;
			text-transform: uppercase;
		}
	}
	.owl-stage {
		padding: 15px 0;
	}
	.owl-item {
		&:hover {
			z-index: 400;
		}
	}
}

#request-on-order-form .btn-buy-product {
	background: $tc !important;
	padding: 0 37px !important;
	color: $white !important;
	@include transition(0.2s all);
	&:hover {
		background: $bk !important;
	}
}

.page-information, .checkout-buy {
	h1 {
		margin-top: 0;
	}
}

.holiday_works {
	text-align: center;
	border: none !important;
	margin-left: auto !important;
	display: block;
	color: #fff !important;
	margin-top: 20px;
	margin-bottom: 10px;
	position: absolute;
	bottom: 23px;
	background: #ac153a;
	padding: 5px 15px;
}

.quick-view {
	#content {
		padding: 0;
	}
}

#review {
	table {
		margin-bottom: 15px;
	}
	.rating {
		margin-left: 15px;
	}
}

#form-review-container {
	.recaptcha_container label {
		display: none;
	}
}

.prod_rating {
	.custom-color {
		cursor: pointer;
	}
}