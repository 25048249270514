.svg-cart {
	width: 23px;
	height: 23px;
}
.svg-cart-1 {
	fill-rule:evenodd;
}
.svg-cart-1, .svg-cart-2 {
	fill:none;
	stroke:#fff;
	stroke-miterlimit:10;
	stroke-width:0.75px;
}
.cart-btn {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	svg {
		margin: 0 10px 3px 0;
	}
}
.owl-carousel {
	display: block !important;
	background: transparent;
	@include box-shadow(none);
}

.refine-search {
	margin-top: 15px;
	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.sidebar {
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;
	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;
		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}
}

.product-info {
	.product-meta {
		.btn-sm, .btn-group-sm > .btn {
			padding: 0 15px;
		}
	}
}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

.product-filter {
	color: $product-filter-color;
	text-transform: uppercase;
	font-size: $product-filter-font-size;
	font-weight: 500;
	margin: -15px 0 10px;
	width: 100%;
	-webkit-align-items: center;
	align-items: center;
	-webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	-moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	span {
		margin-right: 5px;
	}
	.filter-right > div {
		padding-top: 3px;
		padding-left: 5px;
	}
	.display {
		float: left;
	}
	.form-control {
		display: inline-block;
		padding: 0 20px;
		margin: 0 5px;
		@include size(auto, 36px);
		text-transform: capitalize;
	}
	.btn-switch {
		display: inline-block;
		margin-right: 5px;
		color: #969696;
		background: transparent;
		padding: 0;
		line-height: 35px;
		font-weight: 500;
		font-size: 34px;
		@include transition (all 200ms ease 0s);
		i {
			padding: 0 5px;
		}
		&:hover, &.active {
			color: $theme-color;
			@include box-shadow (none);
		}
		&#grid-switch {
			font-size: 32px;
		}
	}
}

.product-list {
	.description {
		display: block;
	}
	.product-meta {
		padding-left: 0;
		width: 70%;
	}
	.product-block {
		display: flex;
		margin: 10px 0;
		.name {
			height: auto !important;
			a {
				span {
					display: inline-block;
				}
			}
		}
		.block-img {
			width: 30%;
		}
		.bottom {
			.cart {
				display: inline-block;
			}
		}
	}
}

.product-grid, .products-owl-carousel {
	&.product-related {
		.products-block {
			margin: 5px;
		}
		.product-block {
			width: calc(100% / 4 - 13px);
		}
	}
	.product-block {
		display: inline-block;
		margin: 15px 30px 15px 0;
		width: calc(100% / 3 - 33px);
		@include transition(border 0.2s ease-in-out);
		.cart {
			padding: 0;
			flex: 1 1 auto;
			.btn {
				width: 100%;
			}
		}
		.compare {
			flex: 0 0 36px;
			i {
				font-size: 22px;
				vertical-align: -2px;
			}
		}
		.quickview {
			flex: 0 0 36px;
			margin-left: -1px;
			i {
				font-size: 22px;
				vertical-align: -2px;
			}
		}
		.compare, .quickview {
			a, button {
				width: 100%;
			}
		}
		.bottom {
			@include transition (all 400ms ease 0s);
			bottom: 0;
			left: 0;
			z-index: 999;
			background-color: $white;
			text-align: center;
		}
		&:hover {
			border-color: $bc;
		}
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
	.product-block {
		width: auto;
	}
}

/* 
* PRODUCT INFORMATION PAGE
*/
.product-info {
	.title-product {
		font-weight: 600;
	}
	.image-additional {
		overflow: hidden;
		position: relative;
		.owl-carousel {
			margin-bottom: 0 !important;
		}
		.item {
			border: 1px solid $border-color;
			text-align: center;
			margin: 0 2px;
			background-color: $white;
			position: relative;
		}
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;

		.markdown-product {
			top: 10px;
			right: unset;
			left: 10px;
		}
	}
	.quantity {
		padding-bottom: 15px;
	}
	.quantity-adder {
		display: block;
		position: relative;
		background-color: $white;
		min-width: 125px;

		@include border-radius($border-color, 0);
		.add-action {
			@include size(40px, 35px);
			color: $black;
			display: block;
			position: relative;
			cursor: pointer;
			font-size: 8px;
			line-height: 39px;
			text-align: center;
			&:hover, &:focus {
				color: $theme-color;
			}
		}
		.quantity-number {
			input {
				width: 40px;
				text-align: center;
				@include rounded-corners(0);
				box-shadow: none;
				padding: 0;
				border: none;
			}
		}
	}
}

.product-markdown-view {
	#product {
		.custom-product-meta {
			margin: 0 !important;
			justify-content: flex-start;
			gap: 20px;
			flex-wrap: wrap;

			&-item {
				width: auto !important;
				max-width: 100% !important;
			}
		}
	}
	.price {
		display: flex;
		gap: 8px;

		.price-new, .price-old {
			font-size: 23px;
			line-height: 1;
			white-space: nowrap;
		}
	}
	.cheaper-item, .cheaper-products, .cheaper-items, .cheaper-item-info, .custom-product-meta-item {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.cheaper-item {
		padding: 20px;
		border: 1px solid $dg;

		&:not(.general) {
			border: 1px solid $mg;

			&:hover {
				text-decoration: none;
			}
			.price-new {
				font-size: 16px;
			}
			.price-old {
				color: $bk;
				font-size: 16px;
			}
		}
	}
	.cheaper-products {
		margin: 20px 0;
	}
	.cheaper-item, .cheaper-products {
		&__title {
			font-size: 16px;
			font-weight: 600;
		}
		&__status {
			color: $dg;
			font-weight: 600;
		}
		&__reason {
			color: $dg;
		}
	}
}

.home-about_us {
	padding: 73px 0 37px 0;
	background-size: 1920px;
	background-position: bottom left;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		left: 80px;
		background: url(/catalog/view/theme/kitchenrussia/img/4411.png) no-repeat;
		bottom: 168px;
		width: 122px;
		background-size: 100% 100%;
		height: 142px;
		transform: rotate(55deg);
	}
	&_container h2 {
		margin-top: 0;
	}
	.about_us-content {
		display: flex;
		justify-content: space-between;
		.content .labels {
			margin-top: 21px;
		}
	}
}

.form-box-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
	padding: 45px 26px 30px;
	background-color: white;
	border-right: 4px solid $tc;
	max-width: 264px;
	position: relative;
	margin-top: 12px;
	height: 100%;
	&:after {
		content: '';
		position: absolute;
		left: -47px;
		background: url(/catalog/view/theme/kitchenrussia/img/pepper.svg) no-repeat;
		top: -50px;
		width: 110px;
		height: 110px;
		z-index: 1;
		transform: scale(-1, 1);
	}
	&:before {
		position: absolute;
		bottom: -2px;
		background: url(/catalog/view/theme/kitchenrussia/img/591.png) no-repeat;
		background-size: contain;
		width: 126px;
		height: 100px;
		content: '';
		left: -1px;
		z-index: 1;
		filter: brightness(30%);
	}
	h2 {
		margin-top: 0;
		margin-bottom: 0;
		line-height: 1.4;
	}
	p {
		font-size: 12px;
		font-family: $f;
		color: #000;
		margin-top: 8px;
	}
	form {
		display: flex;
		flex-direction: column;
		width: 100%;
		input, textarea {
			resize: none;
			width: 100%;
			min-height: 40px;
			margin-bottom: 10px;
			padding: 10px 10px 10px 14px;
			font-size: 12px;
			font-family: $f;
			color: rgb(76, 76, 76);
			line-height: 1.2;
			-webkit-box-shadow: inset 10px 10px 11px -10px rgba(233, 233, 233, 0.68);
			-moz-box-shadow: inset 10px 10px 11px -10px rgba(233, 233, 233, 0.68);
			box-shadow: inset 10px 10px 11px -10px rgba(233, 233, 233, 0.68);
			border: 1px solid #cbcbcb;
		}
		span {
			font-size: 10px;
			font-family: $f;
			color: #000000;
			line-height: 1.7;
			a {
				color: #c41239;
				text-decoration: underline;
				&:hover {
					color: black;
				}
			}
		}
		button {
			margin: 20px auto 0;
			max-width: 145px;
			border: none;
			text-transform: uppercase;
			background: #e3335a;
			background-size: 100% 100%;
			height: 40px;
			position: relative;
			border-top: 2px solid #be1e43;
			transition: none;
			box-shadow: 0 4px 19px rgba(194, 18, 57, 0.35);
			z-index: 2;
			color: white;
			width: 100%;
			font-weight: 700;
			&:hover {
				color: #000;
				&:after {
					color: #000;
				}
			}
			&:before, &:after {
				content: '';
				position: absolute;
			}
			&:before {
				height: 38px;
				width: 83px;
				border-bottom: 38px solid #c11b40;
				border-left: 30px solid transparent;
				border-right: 0 solid transparent;
				right: 0;
				top: 0;
				z-index: -1;
				opacity: 0.6;
			}
			&:after {
				left: 0;
				bottom: -9px;
				border: 8px solid transparent;
				border-left: 10px solid #e3335a;
				border-top: 6px solid #e3335a;
			}
		}
	}
}

.widget_news {
	&.box-news > .container {
		margin: 0 auto;
		padding: 0 15px;
	}
	.content-container .go-to-catalog {
		text-transform: uppercase;
	}
}

.special-offer {
	.box-products {
		position: relative;
	}
	.product-block {
		.product-meta {
			.product-prices {
				height: 22px;
			}
		}
	}
	.bottom-bg {
		.widget-products:after {
			height: 12px;
			content: '';
			position: absolute;
			bottom: -12px;
			background: #000000;
			opacity: 0.2;
			width: 100%;
			left: 0;
			z-index: 2;
			display: block;
		}
	}
}

#form-review-container {
	.set-rating {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		margin-top: 6px;
		margin-bottom: 2px;
		height: 16px;
		width: max-content;
		svg {
			cursor: pointer;
			width: 22px;
			height: 22px;
			&.selected {
				fill: $tc;
			}
		}
		&:hover svg {
			fill: #ccc;
		}
		& > svg:hover, & > svg:hover ~ svg {
			fill: $tc;
		}
	}
	.recaptcha_container {
		.control-label {
			display: none;
		}
	}
}

/*------------------- category page + allproduct page --------------------*/
.page-category, .page-product-allproduct {
	#sidebar-left {
		.owl-prev, .owl-next {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: center;
			top: calc(50% - 20px);
			background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) no-repeat;
			background-size: cover;
			width: 42px;
			height: 40px;
		}
		.owl-prev {
			left: -7px;
		}
		.owl-next {
			right: -7px;
			transform: scale(-1, 1);
		}
	}
}
/*------------------- home page --------------------*/
.page-home .bannercontainer.banner-fullscreen .tp-leftarrow {
	position: absolute;
	left: 20px;
	top: 296px;
	background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) 0% 0% / cover no-repeat;
	width: 42px;
	color: transparent;
	height: 40px;
	font-size: 0px;
	z-index: 100;
}
.page-home .bannercontainer.banner-fullscreen .tp-rightarrow {
	position: absolute;
	right: 20px;
	top: 296px;
	background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) 0% 0% / cover no-repeat;
	transform: scale(-1, 1);
	width: 42px;
	color: transparent;
	height: 40px;
	font-size: 0px;
	z-index: 100;
}
.page-home .bannercontainer.banner-fullscreen .tp-leftarrow:hover,
.page-home .bannercontainer.banner-fullscreen .tp-rightarrow:hover {
	cursor: pointer;
}
.home-about_us {
	.about_us-content {
		.content {
			.text-box {
				font-size: 10pt;
				color: black;
				line-height: 1.8;
				list-style-type: none;
				svg {
					fill: $tc;
				}
				ul {
					list-style-type: none;
					li > span {
						margin-left: 5px;
					}
				}
			}
		}
	}
}
.page-home {
	.defhome {
		border-bottom: 2px solid #c41239;
		.row-inner {
			width: 100%;
			padding: 0;
			& > div {
				padding: 0;
			}
		}
	}
	.kitchen-home {
		background-size: 1920px 100%;
		background: url(/catalog/view/theme/kitchenrussia/img/block-2.png) no-repeat center;
		padding-top: 70px;
		background-position-y: 50px;
	}
	.simplebullets .counter {
		display: none;
	}
}
.header-v1 {
	background: url("https://cdn.kitchenrussia.ru/image/catalog/banner/Kitchen_banner-3.jpg") no-repeat;
	background-position: center 74px;
}
/*------------------- information pages --------------------*/

.confidence {
	font-family: "Montserrat", sans-serif;
	font-size: 10pt;
	color: black;
	line-height: 1.8;
	a {
		color: $tc;
		text-decoration: none;
		cursor: pointer;
		&:hover {
			color: $tc;
			text-decoration: underline;
		}
	}
	h2 {
		font-size: 16px;
		font-weight: 700;
	}
}

/*------------------- information page Color Kyoto, Honey -----------*/
.information-information-23, .information-information-25 {
	h1 {
		display: none;
	}
	.main-columns.container {
		width: 1600px;
	}
	#modular-landing {
		img {
			max-width: 100%;
			height: auto;
			-ms-interpolation-mode: bicubic;
			display: inline-block;
			vertical-align: middle;
		}
		.owl-nav .owl-prev, .owl-nav .owl-next {
			text-indent: -9999px;
			position: absolute;
			top: calc(50% - 40px);
			background-size: cover;
			width: 42px;
			height: 40px;
		}
		.owl-nav .owl-prev {
			left: -7px;
			background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) no-repeat;
		}
		.owl-nav .owl-next {
			right: -7px;
			background: url(/catalog/view/theme/kitchenrussia/img/arrows.svg) no-repeat;
			transform: scale(-1, 1);
		}
		.mod-contents,
		.mod-heading,
		.mod-contents.mod-contents--3-images .mod-contents__container,
		.mod-contents.mod-contents--double .mod-contents__container {
			& :after {
				content: ' ';
				display: table;
				clear: both;
			}
			& :before {
				content: ' ';
				display: table;
			}
		}

		article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
			display: block;
		}
		.mod-heading__content {
			text-align: center;
		}
		#module1 {
			.mod-heading {
				padding-top: 0;
			}
			.mod-content__title {
				display: block;
			}
		}
		.mod-home-banner__box {
			-webkit-box-flex: 0;
			-ms-flex: 0;
			flex: 0;
			flex-grow: 0;
			height: 21.875rem;
		}
		.mod-home-banner--full-width {
			padding-left: 0;
			padding-right: 0;
			max-width: 100%;
			.mod-home-banner__content {
				background-color: #ededed;
				padding: 2.1875rem;
				text-align: left;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-direction: column;
				flex-direction: column;
				-webkit-box-align: start;
				-ms-flex-align: start;
				align-items: flex-start;
			}
			.mod-home-banner__box-wrp {
				display: flex;
				flex-wrap: wrap;
			}
			.mod-home-banner__box--content {
				-ms-flex-preferred-size: 22.5rem;
				flex-basis: 22.5rem;
				-webkit-box-ordinal-group: 1;
				-ms-flex-order: 0;
				order: 0;
			}
			.mod-home-banner {
				margin-left: auto;
				margin-right: auto;
			}
			.mod-home-banner__box {
				height: 46.375rem;
			}
			.mod-home-banner__box--content {
				-ms-flex-preferred-size: 38%;
				flex-basis: 38%;
			}
			.mod-home-banner__content {
				padding: 0 5rem;
				height: 100%;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
			}
			.mod-home-banner__image-cnt {
				height: 100%;
			}
			.mod-home-banner__box--image {
				-webkit-box-flex: 1;
				-ms-flex-positive: 1;
				flex-grow: 1;
				-webkit-box-ordinal-group: 2;
				-ms-flex-order: 1;
				order: 1;
			}
			.mod-home-banner__text {
				width: 100%;
				font-weight: 300;
				color: #000;
			}
			.mod-home-banner__image {
				width: 100%;
				height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				font-family: "object-fit: cover";
			}
			.mod-home-banner__image-positioner {
				position: static;
				display: block;
				width: 100%;
				height: 100%;
			}
			.mod-home-banner__title {
				width: 100%;
				font-size: 2.875rem;
				font-weight: 700;
				line-height: 3.125rem;
				color: #000;
			}
		}
		.mod-content__title {
			font-size: 2.8rem;
			max-width: 100%;
			font-weight: 600;
			line-height: 2.5rem;
		}
		.mod-heading {
			padding-top: 5rem;
			padding-bottom: 5rem;
			margin-left: auto;
			margin-right: auto;
		}
		.mod-heading--light {
			.mod-content.mod-heading__content {
				max-width: 500px;
				width: 54.1666666667%;
				padding-left: 1.25rem;
				padding-right: 1.25rem;
				margin-left: auto;
				margin-right: auto;
			}
			.mod-content__title {
				font-size: 3.125rem;
				line-height: normal;
				letter-spacing: normal;
				margin-left: auto;
				margin-right: auto;
				max-width: 100%;
				line-height: 1.15;
			}
			.mod-content__content {
				margin-top: 2.5rem;
				font-size: 1.875rem;
				letter-spacing: normal;
			}
		}
		.mod-content__content p,
		.mod-home-banner__text,
		.mod-content-item__container .mod-content__content {
			font-size: 1.675rem;
			line-height: 2.225rem;
		}
		.mod-content-item__media {
			position: relative;
			max-width: 28.125rem;
			margin: 0 auto;
		}
		.mod-contents {
			.mod-content-item__media {
				max-width: none;
				img {
					position: absolute;
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					-o-object-fit: cover;
					object-fit: cover;
					font-family: "object-fit: cover";
				}
			}
		}
		.mod-content-item {
			text-align: center;
			padding-bottom: 6.25rem;
		}
		.mod-content-item__image-wrapper {
			display: block;
			padding-top: 80%;
		}
		.mod-content-item__container {
			max-width: 37rem;
			margin: 0 auto;
			.mod-content__content {
				margin-top: .625rem;
				min-height: 120px;
			}
		}
		.mod-content-item__content {
			padding: 0 1.25rem;
		}
		.mod-contents--double {
			.mod-contents__container {
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
			}
			.mod-contents__column {
				width: 50%;
				float: left;
				padding-left: 0;
				padding-right: 0;
			}
		}
		#module6 {
			.mod-heading {
				text-align: center;
				border-bottom: 0;
				background-color: #EFF0B0;
				& > :after {
					content: '';
					display: inline-block;
					width: 130px;
					height: 3px;
					background-color: #3E3936;
				}
			}
		}
		.mod-heading.mod-heading--misty {
			.mod-content__content {
				margin-left: auto;
				margin-right: auto;
				width: 60%;
				margin-bottom: 4rem;
			}
			.mod-content__title,
			.mod-content__image-wrapper {
				margin-left: auto;
				margin-right: auto;
				max-width: 83.5rem;
				font-size: 4rem;
				padding: 0 2rem;
			}
		}
		#module7, #module12, #module14 {
			.mod-heading__content, .mod-content__title {
				max-width: 100%;
			}
		}
		#module7, #module12 {
			.mod-heading {
				border-bottom: 0;
				padding-bottom: 1rem;
			}
		}
		#module13 {
			.mod-content-item__image-wrapper {
				padding-top: 48%;
			}
		}
		.mod-contents.mod-contents--3-images {
			.mod-contents__heading, .mod-contents__container {
				margin-left: auto;
				margin-right: auto;
				max-width: 94rem;
				padding-top: 2rem;
				padding-bottom: 2rem;
			}
			.mod-content-item {
				text-align: left;
				padding-bottom: 1rem;
			}
			.mod-content-item__container {
				max-width: 100%;
			}
			.mod-content-item__content {
				padding: 0;
			}
			.mod-content__title {
				font-size: 2.125rem;
				line-height: 2.125rem;
			}
			.mod-content__title + * {
				margin-bottom:20px;
				min-height: 90px
			}
			.mod-content__content {
				font-weight: 400;
			}
		}
		#module13 {
			.mod-content__title {
				min-height:60px;
				margin-top: 10px;
			}
		}
		.mod-video--half-width---text-on-left,
		.mod-video--half-width---text-on-right {
			.mod-video__content-wrapper {
				-webkit-box-ordinal-group: 2;
				-ms-flex-order: 1;
				order: 1;
			}
		}
		.mod-video--half-width {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;

			max-width: 111rem;
			margin-left: auto;
			margin-right: auto;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
			margin-top: 4rem;
			margin-bottom: 6rem;

			.mod-video__media-wrapper {
				position: relative;
				overflow: hidden;
				width: 60%;
				padding-top: 37.8%;

				.mod-video__media, iframe {
					position: absolute;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					-o-object-fit: cover;
					object-fit: cover;
					font-family: "object-fit: cover";
				}
			}
			.mod-video__content-wrapper {
				width: 40%;
				padding: 4.125rem 3.5rem;
				.mod-video__content {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					-ms-flex-direction: column;
					flex-direction: column;
					height: 100%;
					.mod-content__title {
						font-size: 3.4375rem;
						line-height: 3.6875rem;
						font-weight: 700;
					}
				}
			}
		}
		.mod-text-photo {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;

			margin-bottom: 14.3125rem;
		}
		.mod-text-photo--text-boxed-on-left,
		.mod-text-photo--text-boxed-on-right {
			margin-top: 2.5rem;
			margin-bottom: 2.5rem;

			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-box-align: stretch;
			-ms-flex-align: stretch;
			align-items: stretch;
			position: relative;
			max-width: 90rem;
			margin-left: auto;
			margin-right: auto;

			.mod-text-photo__column--media {
				-webkit-box-flex: 0;
				-ms-flex: 0 0 63%;
				flex: 0 0 63%;
				max-height: 42rem;
				padding: 0;

				position: relative;

				.mod-text-photo__media {
					width: 100%;
					background: #eee;
					min-height: 100%;
					position: relative;
					padding-bottom: 74%;
					img {
						width: 100%;
						height: 100%;
						position: absolute;
						-o-object-fit: cover;
						object-fit: cover;
						font-family: "object-fit: cover";
					}
				}
			}
			.mod-text-photo__column--content {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				.mod-text-photo__content {
					position: absolute;
					top: 3rem;
					width: 42%;
					padding: 2.5rem 3.5rem 3.125rem 3.5rem;
					background-color: #FFF;
					box-shadow: 0 3px 50px rgba(0, 0, 0, 0.2);
					z-index: 1;
					.mod-content__title {
						font-size: 2.875rem;
						line-height: 3rem;
						font-weight: 700;
					}
				}
			}
		}
		.mod-text-photo--text-boxed-on-right {
			margin-bottom: 15.5rem;
			.mod-text-photo__column--content {
				-webkit-box-ordinal-group: 3;
				-ms-flex-order: 2;
				order: 2;
				.mod-text-photo__content {
					right: 1.25rem;
				}
			}
		}
		.mod-text-photo--text-boxed-on-left {
			.mod-text-photo__column {
				.mod-text-photo__content {
					left: 1.25rem;
				}
			}
			.mod-text-photo__column--media {
				-webkit-box-ordinal-group: 4;
				-ms-flex-order: 3;
				order: 3;
				margin-left: auto;
			}
		}
		.mod-content-item {
			.mod-btn-action, .mod-btn {
				width: 100%;
				max-width: 25rem;
				min-width: 0;
				margin: auto;
				display: block;
			}
		}
		.mod-content__action {
			margin-top: 0;
		}
		.mod-btn {
			box-sizing: border-box;
			text-decoration: none;
			display: inline-block;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 1.21rem;
			padding: .4125rem;
			line-height: 2rem;
			min-width: 15rem;
			text-align: center;
			color: #3e3936;
			border: 1px solid #cfcecd;
			background-color: rgba(255, 255, 255, 0.6);
		}
		#module14 {
			.mod-heading {
				border-bottom: 0;
				padding-bottom: 1rem;
			}
		}
		.mod-hero-column-banner-wrapper {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
			max-width: 105.5rem;
			margin: 2.5rem auto;
			padding: 0 2.25rem;
			& > :not(:last-child) {
				margin-right: 2.5rem;
			}
			& > * {
				-webkit-box-flex: 0;
				-ms-flex: 0 0 calc(50% - 1.75rem);
				flex: 0 0 calc(50% - 1.75rem);
			}
			.mod-hero-column-banner {
				max-width: 68rem;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				.mod-hero-column-banner__figure {
					padding-bottom: 66.7%;
					position: relative;
					margin: 0;

					height: 22.80rem;
					padding-bottom: 0;

					.mod-hero-column-banner__picture, .mod-hero-column-banner__image {
						position: absolute;
						width: 100%;
						height: 100%;
						-o-object-fit: cover;
						object-fit: cover;
						overflow: hidden;
					}
				}
				.mod-hero-column-banner__actions {
					background-color: #c20038;
					color: #FFF;
					padding: 3rem;

					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					height: 10.250rem;

					.mod-hero-column-banner__heading {
						position: relative;

						-webkit-box-flex: 1;
						-ms-flex: 1 1 auto;
						flex: 1 1 auto;
						margin: 0;
					}
					.mod-hero-column-banner__cta-wrapper {
						-webkit-box-flex: 0;
						-ms-flex: 0 0 auto;
						flex: 0 0 auto;
						.mod-hero-column-banner__cta {
							font-size: 1.375rem;
							font-weight: 600;
							line-height: 2.25rem;
							color: #FFF;
							border: .125rem solid #FFF;
							padding: 1.225rem 2rem;
							text-align: center;
							box-sizing: border-box;
							display: block;
							width: 100%;
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
}
.information-information-25 {
	#modular-landing {
		#module3, #module6, #module7, #module12 {
			.mod-heading {
				background-color: #e9872d;
				.mod-content__title,
				.mod-content__content {
					color: #fff;
				}
			}
		}
		#module6 {
			.mod-heading {
				& > :after {
					content: '';
					display: none;
				}
			}
		}
		#module7, #module12 {
			.mod-heading {
				padding-bottom: 5rem;
			}
		}
	}
}
/*------------------- information page Color Kyoto, Honey END--------*/